<template>
    <div class="card card-top-line setup-profile">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Supporting Documents</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body profile-collapse-list no-shadow active" v-if="!documents.readonlyMode">
            <div class="documents-main pt-0" :class="{'disabled':documents.readonlyMode}">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="upload-left">
                            <div class="document-type-dropdown">
                                <div class="form-group form-select single-multiselect">
                                    <span class="float-label" :class="{'multiselect--disabled':documents.readonlyMode}">
                                    <label for="c">Select Document Type</label>
                                        <multiselect
                                            :disabled="documents.readonlyMode"
                                            placeholder=""
                                            v-model="documents.documentType" 
                                            :show-labels="false" 
                                            :options="documentOptions" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name"
                                            @input="onChangeCheckType">
                                            <template slot="option" slot-scope="props">
                                                <div>
                                                    <span class="option__title">{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                    </span>
                                </div>
                            </div>
                            <div class="document-type-dropdown" v-if="selectedItemArray.wealthNames">
                                <div class="form-group form-select single-multiselect">
                                    <span class="float-label" :class="{'multiselect--disabled':documents.readonlyMode}">
                                    <label for="c">Select Wealth Document</label>
                                        <multiselect
                                            :disabled="documents.readonlyMode"
                                            placeholder=""
                                            v-model="documents.wealthType" 
                                            :show-labels="false" 
                                            :options="selectedItemArray.wealthNames" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template slot="option" slot-scope="props">
                                                <div>
                                                    <span class="option__title">{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                    </span>
                                </div>
                            </div>
                            <div class="upload-box" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                                class="opacity-0 overflow-hidden absolute" @change="onChangeFileUpload(documents.wealthType)" ref="file" accept=".pdf,.doc" :disabled="documents.readonlyMode"/>
                                <label for="assetsFieldHandle" class="block cursor-pointer">
                                    <i class="fa fa-upload fa-2x icon-primary"></i>
                                    <p class="mt-3">Click to upload pages of the document <br/><small>File Formate: JPG, PNG or PDF</small><br/><small>File Size: 10MB</small></p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="upload-right">
                            <form id="document_form">
                                <ul class="upload-list-group list-unstyled">
                                    <template v-for="(document,index) in documentTypeList">
                                        <li class="d-flex justify-content-between flex-column"  :key="index" v-if="businessType === 'Business' || (businessType === 'Organisation' && !document.isBusiness)">
                                            <h6 class="mb-0 d-flex justify-content-between align-items-center">{{ document.name }} 
                                                <img src="@/assets/complete.svg" v-if="document.documents_count > 0" width="20"/>
                                                <img src="@/assets/rejected.svg" v-if="documentError && document.documents_count == 0" width="20"/>
                                            </h6>
                                            <ul class="inner-list list-unstyled">
                                                <li v-for="(uploaddocument,fieIndex) in document.documents" :key="fieIndex">
                                                    <p class="pl-4 ml-3 mb-0 d-flex justify-content-between align-items-center "><span> - {{ uploaddocument.name }} </span> <button :id="'file_remove_'+index+'_'+fieIndex" class="badge badge-danger mx-0 cursor-pointer"  @click="removeUploadFiles($event, document.type, fieIndex)" :disabled="documents.readonlyMode">Delete</button></p>
                                                </li>
                                            </ul>
                                            <div class="second-inner">
                                                <ul class="inner-list list-unstyled" v-if="document.wealthNames">
                                                    <li v-for="(wealthsName,welthFileIndex) in document.wealthNames" :key="welthFileIndex">
                                                        <h6 class="mb-0 d-flex justify-content-between align-items-center"><small>{{ wealthsName.name }}</small></h6>
                                                        <span v-if="wealthsName.wealthDocuments">
                                                            <p class="pl-5 mb-0 d-flex justify-content-between align-items-center" v-for="(wealthDoc,welthFileDocIndex) in wealthsName.wealthDocuments" :key="welthFileDocIndex">
                                                                <span class="ml-3"> - {{ wealthDoc.name }} </span> <small class="badge badge-danger mx-0 cursor-pointer" @click="removeUploadFiles($event, document.type, welthFileDocIndex, wealthsName.wealthType)">Delete</small>
                                                            </p>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </form>
                        </div>
                    </div>
                    <div v-if="!documents.readonlyMode" class="col-12 justify-content-center d-flex">
                        <b-button size="lg" @click="documentsForm('cancel')" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg" @click="documentsForm('update')">Update</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Multiselect = require('vue-multiselect').default
export default {
    name: 'SupportingDocument-Profile.vue',
    components: {
        Multiselect,
    },
    props: {
        businessType: {
            type: String,
            default: function () {
                return 'Business'
            }
        },
    },
    watch: { 
        businessType: function() { // watcher for the value change of props
            this.updateDocumentOptions();
        }
    },
    data(){
        return{
            selectedItemArray: [],
            documentError:false,
            documents:{
                readonlyMode:true,
                documentType:[],
                wealthType:''
            },

            documentTypeList:[
                {
                    type:'article_of_association',
                    name:'Article of Association',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                {
                    type:'memorandum_of_association',
                    name:'Memorandum of Association',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                //director 1
                {
                    type:'director_id_1',
                    name:'Director - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'director_address_1',
                    name:'Director - John -  Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                //director 2
                {
                    type:'director_id_2',
                    name:'Director - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'director_address_2',
                    name:'Director - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                //shareholders 1
                {
                    type:'shareholders_id_1',
                    name:'Shareholder - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_address_1',
                    name:'Shareholder - John - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_wealth_1',
                    name:'Shareholder - John - Source of Wealth',
                    documents:[],
                    documents_count: 0, 
                    wealthNames:[
                        {
                            wealthType:'swealth1_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth1_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth1_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //shareholders 2
                {
                    type:'shareholders_id_2',
                    name:'Shareholder - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_address_2',
                    name:'Shareholder - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_wealth_2',
                    name:'Shareholder - David - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'swealth2_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth2_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth2_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //Beneficial Owners 1
                {
                    type:'owners_id_1',
                    name:'UBO - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_address_1',
                    name:'UBO - John - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_wealth_1',
                    name:'UBO - John - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'owealth1_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth1_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth1_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //Beneficial Owners 2
                {
                    type:'owners_id_2',
                    name:'UBO - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_address_2',
                    name:'UBO - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthDocuments:[],
                    isBusiness: true
                },
                 {
                    type:'owners_wealth_2',
                    name:'UBO - David - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'owealth2_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth2_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth2_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //licences
                {
                    type:'licences',
                    name:'Proof of Licences held, if any',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                //financial aaccounts
                {
                    type:'financial_accounts',
                    name:'Financial Accounts',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                }
            ],

            documentOptions:[
                {
                    type:'article_of_association',
                    name:'Article of Association',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                {
                    type:'memorandum_of_association',
                    name:'Memorandum of Association',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                //director 1
                {
                    type:'director_id_1',
                    name:'Director - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'director_address_1',
                    name:'Director - John -  Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                //director 2
                {
                    type:'director_id_2',
                    name:'Director - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'director_address_2',
                    name:'Director - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                //shareholders 1
                {
                    type:'shareholders_id_1',
                    name:'Shareholder - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_address_1',
                    name:'Shareholder - John - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_wealth_1',
                    name:'Shareholder - John - Source of Wealth',
                    documents:[],
                    documents_count: 0, 
                    wealthNames:[
                        {
                            wealthType:'swealth1_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth1_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth1_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //shareholders 2
                {
                    type:'shareholders_id_2',
                    name:'Shareholder - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_address_2',
                    name:'Shareholder - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'shareholders_wealth_2',
                    name:'Shareholder - David - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'swealth2_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth2_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'swealth2_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //Beneficial Owners 1
                {
                    type:'owners_id_1',
                    name:'UBO - John - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_address_1',
                    name:'UBO - John - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_wealth_1',
                    name:'UBO - John - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'owealth1_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth1_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth1_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //Beneficial Owners 2
                {
                    type:'owners_id_2',
                    name:'UBO - David - ID',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: true
                },
                {
                    type:'owners_address_2',
                    name:'UBO - David - Proof of Address',
                    documents:[],
                    documents_count: 0,
                    wealthDocuments:[],
                    isBusiness: true
                },
                 {
                    type:'owners_wealth_2',
                    name:'UBO - David - Source of Wealth',
                    documents:[],
                    documents_count: 0,
                    wealthNames:[
                        {
                            wealthType:'owealth2_document1',
                            name:'Document 1',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth2_document2',
                            name:'Document 2',
                            wealthDocuments:[],
                        },
                        {
                            wealthType:'owealth2_document3',
                            name:'Document 3',
                            wealthDocuments:[],
                        }
                    ],
                    isBusiness: true
                },
                //licences
                {
                    type:'licences',
                    name:'Proof of Licences held, if any',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                },
                //financial aaccounts
                {
                    type:'financial_accounts',
                    name:'Financial Accounts',
                    documents:[],
                    documents_count: 0,
                    wealthNames: null,
                    isBusiness: false
                }
            ],

            sourceWealth_options:[
                {
                    id: 1,
                    document:'Document-1',
                },
                {
                    id: 2,
                    document:'Document-2',
                },
                {
                    id: 3,
                    document:'Document-3',
                },
                {
                    id: 4,
                    document:'Document-4',
                },
                {
                    id: 5,
                    document:'Document-5',
                },
            ],

            UBOSourceWealth_options:[
                {
                    id: 1,
                    document:'Document-1',
                },
                {
                    id: 2,
                    document:'Document-2',
                },
                {
                    id: 3,
                    document:'Document-3',
                },
                {
                    id: 4,
                    document:'Document-4',
                },
                {
                    id: 5,
                    document:'Document-5',
                },
            ],
        }
    },methods:{
        editDetails(){
            this.documents.readonlyMode = false
        },

        onChangeFileUpload(type) {
            if(type){
                let index = this.selectedItemArray.wealthNames.findIndex(i => i.wealthType == type.wealthType)
                this.selectedItemArray.wealthNames[index].wealthDocuments.push(...this.$refs.file.files);
                this.documents.wealthType = ''
                this.selectedItemArray.documents_count += this.$refs.file.files.length;
            }else{
                this.selectedItemArray.documents.push(...this.$refs.file.files);
                this.selectedItemArray.documents_count += this.$refs.file.files.length;
            }
            this.updateDocumentOptions();
        },

        removeUploadFiles(e, typeArray, fieIndex, wealthDocType) {
            this.documentTypeList.map((item) => {
                if(item.type == typeArray && wealthDocType) {
                    item.wealthNames.map((wealthItem) => {
                        if(wealthItem.wealthType == wealthDocType) {
                            wealthItem.wealthDocuments.splice(fieIndex,1);
                            item.documents_count--
                        }
                    });
                } else if(item.type == typeArray){
                    item.documents.splice(fieIndex, 1);
                    item.documents_count--
                }
            });
            this.updateDocumentOptions();
        },

        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },

        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },

        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChangeFileUpload(); // Trigger the onChange event manually
            // Clean up 
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },

        onChangeCheckType(selectedItems){
            this.$refs.file.value = null;
            this.selectedItemArray = selectedItems
        },

        documentsForm(buttonType){
            if(buttonType == 'cancel'){
                this.documents.readonlyMode = true
                this.documentError = false
            }else{
                this.documentError = true
                let arr = [];
                this.documentOptions.map((iteam)=>{
                    arr.push(iteam.documents_count)
                });
                if(!arr.includes(0)){
                    this.documents.readonlyMode = false
                }
            }  
        },

        //Update document upload options
        updateDocumentOptions() {
            let that = this;
            this.documentOptions = [];
            this.documentTypeList.map((item) => {
                if(item.documents_count == 0 && (that.businessType === 'Business' || (that.businessType === 'Organisation' && !item.isBusiness))) {
                    that.documentOptions.push(item);
                }
            });
        }
    },mounted() {
        this.updateDocumentOptions();
    }
}
</script>
