<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>User Management</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(createUser)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.first_name || errorArr[0] && errorArr[0].fieldName!='','success':user.first_name && errorArr[0] && errorArr[0].fieldName=='','mb-0':apiErrorCode == 'V0096-009'}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*" @input="checkNameFormat($event, 'first_name', 'First Name', 0)">
                                                    <label for="first_name" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.first_name || errorArr[0] && errorArr[0].fieldName!=''"></i>
                                                    <i class="fa fa-check" v-if="user.first_name && errorArr[0] && errorArr[0].fieldName==''"></i>
                                                </span>
                                                <span class="text-danger error-msg" v-if="errorArr[0] && errorArr[0].fieldName!=''">{{errorArr[0].errorMessage}}</span>
                                            </div>
                                        </ValidationProvider>
                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0067-009'">{{apiErrorMsg}}</span> 

                                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.last_name || errorArr[1] && errorArr[1].fieldName!='','success':user.last_name && errorArr[1] && errorArr[1].fieldName=='','mb-0':apiErrorCode == 'V0096-010'}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*" @input="checkNameFormat($event, 'last_name', 'Last Name', 1)">
                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.last_name || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                                    <i class="fa fa-check" v-if="user.last_name && errorArr[1] && errorArr[1].fieldName==''"></i>
                                                </span>
                                                <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span>
                                            </div>
                                        </ValidationProvider>
                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0067-010'">{{apiErrorMsg}}</span>

                                        <ValidationProvider name="business_name" rules="required" v-slot="{ errors }">
                                             <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.businessname && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Business Name*</label>
                                                    <multiselect 
                                                        placeholder=""
                                                        v-model="user.businessname" 
                                                        :options="businessname_options" 
                                                        :show-labels="false"
                                                        label="category" 
                                                        track-by="category">
                                                        <template slot="option" slot-scope="props">
                                                            <div>
                                                                <span :class="props.option.isType"> 
                                                                    <span v-if="props.option.isType == 'child'">-</span>
                                                                    {{props.option.category}} 
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.businessname && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="branches"  v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.branch_selected && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Branch</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.branch_selected"
                                                        :show-labels="false" 
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name" 
                                                        :options="branch_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.branch_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>



                                        <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.contact_email || errors[0],'success':user.contact_email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="user.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*">
                                                    <label for="contact_email" class="control-label">Contact Email Address*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.contact_email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="user.contact_email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>

                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0067-007'">{{apiErrorMsg}}</span>


                                        <ValidationProvider name="mobile_number" rules="required"  v-if="!user.readonlyMode">
                                            <div class="form-group" :class="{'errors': mobileInputError == false,'success':user.mobile_number && mobileInputError}">
                                                <span class="float-label">
                                                    <label for="mobile_number" class="control-label">Contact Mobile Number*</label>
                                                    <vue-tel-input
                                                            v-model="user.mobile_number"
                                                            v-bind="primaryMobileNumber"
                                                            @validate ="mobileInputValidate"
                                                            ref="telInput"
                                                            @keypress.native="onlyNumber($event)">
                                                            <template v-slot:arrow-icon>
                                                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                            </template>
                                                        </vue-tel-input>

                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                    <i class="fa fa-check" v-if="mobileInputError && user.mobile_number"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country && user.country.length != 0  && !errors[0]}">
                                                    <span class="float-label">
                                                    <label for="Country">Select Country*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="user.country"   
                                                            :show-labels="false" 
                                                            :options="countrys_options"
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name"
                                                            @select="onSelect($event)">
                                                            <template slot="option" slot-scope="props">
                                                                <div class="option-title">
                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                    <span>{{ props.option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect>

                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="user.country && user.country.length != 0 && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="functions" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.function_selected && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Function*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.function_selected"
                                                        :show-labels="false" 
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name" 
                                                        :options="function_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.function_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="roles" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.roles_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="roles">Select Roles*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.roles_selected"
                                                        :show-labels="false" 
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name" 
                                                        :options="roles_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.roles_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="services" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.services_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Services">Select Services*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.services_selected"
                                                        :show-labels="false" 
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name" 
                                                        :options="services_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.services_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="access" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.access_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Access">Select Access Type*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.access_selected"
                                                       :show-labels="false" 
                                                        :searchable="false"
                                                        :options="access_type_options"
                                                        label="name" 
                                                        track-by="name">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.access_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg" @click="redirectToPersonnelList()">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>

    </div>
</template>

<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BreadCrumb = require('@/components/BreadCrumb.vue').default
import shared from "@/shared.js";
const { VueTelInput } = require('vue-tel-input')
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default

import parsePhoneNumber from 'libphonenumber-js'




export default {
    name: "AddUserComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        BreadCrumb,
        VueTelInput,
        'main-loader':LoaderComponent,
    },
    data: () => ({
        isAllChecked:false,
        access_type_options:[],
        services_options: [],
        roles_options:[],
        function_options: [],
        branch_options: [],
        businessname_options: [],
        mobileInputError:'',
        primaryMobileNumber:{
            // mode: "auto",
            // defaultCountry:"GB",
            // autoFormat:false,
            // dropdownOptions:{
            //     showFlags: true,
            //     disabled:true,
            //     showDialCodeInSelection:true
            // }, 
        },
        // primaryMobileNumber:{},
        countrys_options: [],
        errorArr : [],
        user: {
            first_name:'',
            last_name:'',
            country: null,
            contact_email:'',
            mobile_number:'',
            businessname: null,
            function_selected: null,
            roles_selected: null,
            services_selected: null,
            access_selected: null,
            branch_selected:null,
            readonlyMode : true,
        },
        fieldName : '',
        fieldErrorMsg : '',
        apiErrorMsg : '',
        elementName : '',
        apiErrorCode: '',

    }),
    methods: {

        async getCountryDataByCountryId() {
            try {
                // this.primaryMobileNumber = {};  
                const response = await this.$http.get(this.$baseurl+"countries/"+sessionStorage.getItem('countryId'));
                // console.log(response);

                let countryData = {
                            id:         response.data.id, 
                            isocode:    response.data.isoCode, 
                            code:       response.data.dialCode, 
                            name:       response.data.name, 
                            flagClass:  "flag-icon flag-icon-"+response.data.isocode.toLowerCase()+" fi-xl fi-round",
                            isChecked:true
                        }                
                this.user.country = countryData;
                this.primaryMobileNumber = {
                    mode: "auto",
                    defaultCountry:response.data.isoCode,
                    autoFormat:false,
                    dropdownOptions:{
                        showFlags: true,
                        disabled:true,
                        showDialCodeInSelection:true
                    }, 
                };
                this.user.readonlyMode = false
                
            } catch (error) {
                console.log(error);
            }
        },
        
        redirectToPersonnelList() {
            sessionStorage.setItem('personnelList', '1');
            this.$router.push({path:'/business/details'});
        },
        onSelect(value) {
            this.$refs.telInput.choose(value.isocode);
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        onlyNumber (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },
        checkNameFormat(e, fieldName, elementName,  elementIndex) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                var splitStr = e.target.value.split(' ');
                for (var i = 0; i < splitStr.length; i++) {
                    if(splitStr[0]!='')
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
            this.user[fieldName] = splitStr.join(' ');
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = '';
                        this.errorArr[elementIndex].errorMessage = '';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                    return true; // Match with regex 

                } else {
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = fieldName;
                        this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                }
            } else {
                this.user[fieldName] = char;
                this.fieldErrorMsg = 'Please provide valid value for '+elementName;
                if(!this.errorArr.some(obj => obj.index == elementIndex)) {
                    for(let i=0; i<elementIndex; i++) {
                        if(this.errorArr[i] === undefined){
                            this.errorArr.push({'fieldName': '', errorMessage:'', 'index': i})                        
                        }
                        //if(this.errorArr[elementIndex].fieldName == '')
                    }
                    this.errorArr.push({'fieldName': fieldName, errorMessage:this.fieldErrorMsg, 'index': elementIndex})
                } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr[elementIndex].fieldName =  fieldName;
                    this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
                    this.errorArr[elementIndex].index = elementIndex;
                } else {
                    // do nothing 
                }
               
                e.preventDefault(); // If not match, don't add to input text
            }
        },
        async getCountryData() {
                try {
                    this.$refs.loader.show();
                    const response = await this.$http.get(this.$baseurl+"countries/available");
                    // JSON responses are automatically parsed.
                    if(response.data.code === '0003-000'){
                        response.data.details.map((item) => {
                                let countryData = {
                                    id:item.countryid, 
                                    isocode: item.isocode, 
                                    code: item.mobilecountrycode, 
                                    name: item.countryname, 
                                    flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                    isChecked:false
                                }
                            this.countrys_options.push(countryData);
                        })
                        this.$refs.loader.hide();
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);
                    }
                } catch (error) {
                    this.$refs.loader.hide();
                    shared.toastrError(error);
                }
        },
        async getAccessTypeData() {
            try {
                this.$refs.loader.show();
                //const response = await this.$http.get(this.$baseurl+"business/accesstypes");
                const response = await this.$http.get(this.$baseurl+'business/accesstypes',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                if(response.data.code === '0097-000'){                   
                    // JSON responses are automatically parsed.
                    response.data.details.map((item) => {
                            let accessTypeData = {
                                id:item.accessroleid, 
                                name: item.name, 
                            }
                        this.access_type_options.push(accessTypeData);
                    })
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                //console.log(error);
                this.$refs.loader.hide();
                shared.toastrError(error);

            }
        }, 
        async getServiceOptionsData() {
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(this.$baseurl+"business/services",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                if(response.data.code === '0097-000'){    
                    // JSON responses are automatically parsed.
                    response.data.details.map((item) => {
                            let serviceOptionData = {
                                id:item.accessserviceid, 
                                name: item.name, 
                            }
                        this.services_options.push(serviceOptionData);
                    })
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                //console.log(error);
                this.$refs.loader.hide();
                shared.toastrError(error);
            }
        },
        async getRolesData() {
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(this.$baseurl+'business/roles',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });                
                // JSON responses are automatically parsed.
                if(response.data.code === '0097-000'){    
                    response.data.details.map((item) => {
                            let roleOptionData = {
                                id:item.roleid, 
                                name: item.name, 
                            }
                        this.roles_options.push(roleOptionData);
                    })
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                this.$refs.loader.show();
                shared.toastrError(error);
            }
        },  
        async getFunctionssData() {
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(this.$baseurl+"business/accessfunctions",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                // JSON responses are automatically parsed.
                if(response.data.code === '0097-000'){
                    response.data.details.map((item) => {
                            let functionOptionData = {
                                id:item.accessfunctionid, 
                                name: item.name, 
                            }
                        this.function_options.push(functionOptionData);
                    })
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                this.$refs.loader.hide();
                shared.toastrError(error);
            }
        },
        async getBranchData() {
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(this.$baseurl+"business/branches?businessid="+sessionStorage.getItem('businessIdFromBusinessList'),{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                // JSON responses are automatically parsed.
                if(response.data.code === '0067-000'){
                    response.data.details.map((item) => {
                            let branchOptionData = {
                                id:item.branchid, 
                                name: item.name, 
                            }
                        this.branch_options.push(branchOptionData);
                    })
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                this.$refs.loader.hide();
                shared.toastrError(error);
            }
        },
       async getBusinessWithSubsidiaryData() {
           try {
                const response = await this.$http.get(this.$baseurl+'business/list?sorting=DESC&sortby=createdat&pagesize=200&pagenumber=1&subformat=true',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                    },
                });
                if(response.data.code == '0075-000'){
                    response.data.details.map((item) => {
                        if(item.isParent==true) {
                            let countryData = {
                                id:item.businessid,
                                parentId: "",
                                isType: "parent",
                                category: item.name
                            }
                            this.businessname_options.push(countryData);
                            if(item.subbusinesses.length > 0) {
                                item.subbusinesses.map((subItem) => {
                                    let subItemData = {
                                        id:subItem.businessid,
                                        parentId: subItem.parentbusinessid,
                                        isType: "child",
                                        category: subItem.name
                                    }
                                    this.businessname_options.push(subItemData);
                                })
                            }
                        }
                    })
                }
           } catch(error) {
               return [];
           }
        },   

        createUser() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                //console.log(this.user.branch_selected);
                const phoneNumber = parsePhoneNumber(this.user.mobile_number, this.user.country.isocode);
                let userData = {
                        country: this.user.country.isocode,
                        email: this.user.contact_email,
                        phone: phoneNumber.number,
                       // businessid: sessionStorage.getItem('businessIdFromBusinessList'),
                        businessid: this.user.businessname.id,
                        branchid:this.user.branch_selected!=null?this.user.branch_selected.id:'',
                        accessfunction:  this.user.function_selected.id,
                        role: this.user.roles_selected.id,
                        accesstype: this.user.access_selected.id,
                        service: this.user.services_selected.id,
                        firstname: this.user.first_name,
                        lastname:this.user.last_name
                    };
                console.log('Form Data',userData);
                this.$http.post(this.$baseurl+"business/personnel", userData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                    },
                }).then((response) => {
                    if(response.data.code == '0067-000') {
                        this.$refs.loader.hide();
                        shared.toastrSuccess(response.data.message);
                        this.redirectToPersonnelList(); //set Session Storage and redirect user to Personnels
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);                            
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    if(error.response.data.detail == undefined) {
                        this.apiErrorMsg = error.response.data.message;
                    } else {
                        this.apiErrorMsg = error.response.data.detail;
                    }
                    this.$refs.loader.hide();
                    // shared.toastrError(error.response.data.message);                            
                    

                });            
            }
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.user.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.user.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    },
    watch: {
        mobileNumber(val) {
            this.user.mobile_number = val.replace(/\W/g, "");
        },
    },
    mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.getCountryData();
            this.getAccessTypeData();
            this.getServiceOptionsData();
            this.getRolesData();
            this.getFunctionssData();
            this.getBranchData();
            this.getBusinessWithSubsidiaryData();
            this.getCountryDataByCountryId();
          //  this.getCountryDataByCountryId(sessionStorage.getItem('countryId'));
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.field {
    margin-bottom: 10px;
}

.form {
    margin-top: 20px;
}

span {
    display: block;
    margin-top: 3px;
   
}
</style>
