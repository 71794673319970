<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>

        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Invoice List -->
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header">
                        <h2>Invoice List</h2>
                    </div>
                    <div class="body pt-0">
                        <!-- Body Card top -->
                        <div class="chart-top-action d-lg-flex justify-content-between py-3">
                            <div class="d-md-flex justify-content-between">
                                <div class="mr-0 mr-md-3 action-top-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search Invoices" aria-label="Search Invoices" aria-describedby="search-invoices">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="search-invoices"><i class="icon-magnifier"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="fliter-main mt-3 mt-md-0">
                                    <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                    <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                        <div class="d-flex justify-content-between p-3">
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Input One"/>
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Input Two"/>
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Input Three"/>
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                <!-- Uncomment to add date range picker filter on table -->
                                <!-- <div class="date-range-main">
                                    <template>
                                        <v-md-date-range-picker
                                            :opens="opens"
                                            :presets="presets"
                                            :autoApply="autoApplyDateRange"
                                        ></v-md-date-range-picker>
                                    </template>
                                </div> -->
                                <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                    <b-button variant="primary btn-mw" size="lg"  v-b-modal.new_invoice>Create Invoice <i class="fa fa-plus-square"></i></b-button>
                                    <!-- Create New Invoice Modal -->
                                    <b-modal id="new_invoice" title="Create Invoice" no-stacking hide-footer>
                                        <div class="modal-area">
                                            <ul class="list-unstyled payment-list">
                                                <li class="border rounded p-3" v-on:click="addNewInvoice('onetime')">
                                                    <div class="btn btn-primary btn-lg mb-2 font-0">
                                                        <svg class="payment svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                                            <g>
                                                                <g>
                                                                    <path d="M51.4,96.4c-2.7,0-11.6-3.2-37.5-32c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-3.7-5.5-0.3-9.6c1.6-1.8,5.1-4.4,8.7-2.6
                                                                        c0.1,0.1,0.2,0.1,0.4,0.2l13.7,11.6V19.5c0-0.3,0-3.4,2.3-5.6c1.4-1.4,3.3-2.1,5.6-2.1c0,0,0,0,0,0c5.9,0,7.6,4.1,7.9,7.6
                                                                        c0,0.1,0,0.1,0,0.2v19.4c1.1-0.8,2.5-1.2,4.1-1.1c0.6,0,2.4,0,4.1,1.5c0.8,0.7,1.5,1.6,2,2.7c0.1-0.2,0.3-0.3,0.4-0.5
                                                                        c1-1.1,2.8-2.3,5.9-2c0.6,0,2.5,0.2,4.3,2c1,1,1.8,2.4,2.4,4.1c1-1,2.9-2,5.8-1.7c2.4,0.1,6.9,1.5,6.9,6v29.8
                                                                        c0,0.2-0.9,14.7-15.5,16.9c-0.1,0-0.2,0-0.3,0H51.7C51.6,96.4,51.5,96.4,51.4,96.4z M16.6,62.2c26.7,29.5,34.2,30.7,34.8,30.7
                                                                        c0.1,0,0.1,0,0.2,0h20.5c11.6-1.8,12.4-13,12.4-13.5l0-29.7c0-2.3-3.4-2.5-3.6-2.5c0,0-0.1,0-0.1,0c-1.4-0.2-2.4,0.1-3.1,0.8
                                                                        c-0.9,0.9-1.4,2.4-1.5,3.8c0,0.8,0,1.6-0.1,2.5C76,55.3,75.3,56,74.4,56c0,0,0,0,0,0c-0.9,0-1.7-0.7-1.7-1.6
                                                                        c0-0.3-0.1-1.3,0.1-2.7c-0.1-3.7-0.8-6.4-2.2-7.8c-0.9-0.9-1.8-0.9-1.8-0.9l0,0c-0.1,0-0.2,0-0.2,0c-1.4-0.1-2.4,0.2-3.1,0.9
                                                                        c-1,1.1-1.4,3-1.4,4.5c0.1,0.7,0.1,1.5,0.1,2.2c0,0.9-0.7,1.7-1.6,1.8c-0.9,0.1-1.7-0.6-1.9-1.5c0-0.2-0.2-1.2-0.1-2.5
                                                                        c-0.4-4.2-1.6-5.8-2.3-6.5c-0.8-0.7-1.6-0.7-1.6-0.7c-0.1,0-0.2,0-0.4,0c-1-0.1-1.8,0.2-2.4,0.8c-1,1.1-1.5,2.9-1.6,4.7v3.6
                                                                        c0,0.9-0.7,1.7-1.7,1.7c-0.9,0.1-1.7-0.6-1.8-1.6c0-0.4-0.2-2,0-4V19.6c-0.4-4-2.5-4.3-4.4-4.3h0c-1.4,0-2.4,0.3-3.1,1.1
                                                                        c-1.2,1.2-1.2,3.1-1.2,3.2v48.1c0,0.7-0.4,1.3-1,1.6c-0.6,0.3-1.3,0.2-1.9-0.2L20.5,55.1c-2.1-0.8-4.3,1.8-4.4,1.8
                                                                        C14.6,58.7,16.3,61.6,16.6,62.2z"/>
                                                                </g>
                                                                <path d="M43.8,3.6c-8.7,0-15.8,7.1-15.8,15.8c0,7.3,5,13.4,11.8,15.2v-3.6c-4.8-1.7-8.3-6.2-8.3-11.6c0-6.8,5.5-12.3,12.3-12.3
                                                                    s12.3,5.5,12.3,12.3c0,5.1-3.1,9.4-7.5,11.3v3.7c6.4-2,11-8,11-15C59.6,10.7,52.5,3.6,43.8,3.6z"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <a href="#"><h6 class="mb-0 text-dark">One Off</h6></a>
                                                </li>
                                                <li class="border rounded p-3" v-on:click="addNewInvoice('recurring')">
                                                    <div class="btn btn-primary btn-lg mb-2 font-0">
                                                        <svg class="payment svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                                            <g>
                                                                <path d="M20.9,20.1h19.5V14h-7.8c0,0,27.5-14.1,49.6,16l5.6-3.5c0,0-21-35.3-60.2-17.7V1h-6.8V20.1z"/>
                                                                <path d="M34.5,89.3l-9.9-16.8l-5.2,3.1l4,6.7c0,0-26.1-16.5-11.4-50.8L6,28.4c0,0-19.7,36,15.4,60.8l-6.8,4l3.4,5.8L34.5,89.3z"/>
                                                                <path d="M82.9,42.5l-9.6,17l5.3,3l3.8-6.8c0,0-1.3,30.9-38.4,35.2l0.2,6.6c0,0,41.1-0.8,45.1-43.6l6.8,3.9l3.3-5.9L82.9,42.5z"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <a href="#"><h6 class="mb-0 text-dark">Recurring</h6></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-modal>
                                </div>
                            </div>
                        </div>

                        <!-- Body Table -->
                        <b-table 
                            responsive
                            table-class="mb-0 table-custom border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(id)="data">
                                <router-link to="/invoice/detail">{{ data.value }}</router-link>
                            </template>
                            <template #cell(amountDetails)="data">
                                <span>{{ data.value.currencyprefix}}{{ data.value.amount }}</span>
                            </template>
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="onEdit">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>

                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const moment = require('moment')
const TransactionsTopRow = require('@/components/core/TransactionsTopRow.vue').default
import '@/plugins/sweetalert2'
//const { VMdDateRangePicker } = require("v-md-date-range-picker")

export default {
    name:'InvoiceListComponent',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow,
        //'v-md-date-range-picker':VMdDateRangePicker,
    },
    methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
        
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },
        
        addNewInvoice: function (invoiceType) {
            this.$router.push({path:'/invoice/customer-list', query:{ type: invoiceType }});
        },

        onEdit: function () {
            this.$router.push({path:'/invoice/add-invoice-details'})
        }
    },data(){
        return{
            isVisibleFilter: false,
            //date range presets
            opens: 'right',
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
            autoApplyDateRange: false,
            //Table data
            items:[
                {id: 'A0089',name:'ThemeMakker Inc.',date:'15-Aug-2021',dueDate:'Aug 15, 2021',amountDetails:{currencyprefix:'$',amount:'550'},status:{name:'Pending',class:'badge-warning'},action:''},
                {id: 'A0080',name:'East Repair Inc.',date:'17-Aug-2021',dueDate:'Aug 20, 2021',amountDetails:{currencyprefix:'$',amount:'1,905'},status:{name:'Paid',class:'badge-success'},action:''},
                {id: 'A0081',name:'Saffron Design Inc.',date:'19-Aug-2021',dueDate:'Aug 15, 2021',amountDetails:{currencyprefix:'$',amount:'5,390'},status:{name:'Unpaid',class:'badge-danger'},action:''},
                {id: 'A0088',name:'Factura Inc.',date:'20-Aug-2021',dueDate:'Aug 25, 2021',amountDetails:{currencyprefix:'$',amount:'205'},status:{name:'Pending',class:'badge-warning'},action:''},
                {id: 'A0086',name:'Herman Ltd Inc.',date:'25-Aug-2021',dueDate:'Sep 2, 2021',amountDetails:{currencyprefix:'$',amount:'501'},status:{name:'Paid',class:'badge-success'},action:''},
                {id: 'A0019',name:'Barrows and Sons Inc.',date:'22-Aug-2021',dueDate:'Aug 23, 2021',amountDetails:{currencyprefix:'$',amount:'16,660'},status:{name:'Cancelled',class:'badge-danger'},action:''},
            ],
           
           //Table header
            fields: [
                { key: 'date', label: 'Date',sortable: true,thClass:'th_sortfix'},
                { key: 'id', label: 'Invoice ID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'dueDate', label: 'Due Date',sortable: true,thClass:'th_sortfix'},
                { key: 'amountDetails', label: 'Amount',sortable: true,thClass:'th_sortfix text-right',tdClass:'text-right'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'text-right th_sortfix',tdClass:'text-right'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Table Pagination
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //Summary data
            transactions_toprows:[
                {
                    icon:'fa fa-user-plus',
                    text:'New Members(today)',
                    price:'220',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-calendar',
                    text:'New This Month',
                    price:'100',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last month'
                },
                {
                    icon:'fa-users',
                    text:'Total Member',
                    price:'3200',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Member Location',
                    price:'3000',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last year'
                }
            ],
        }
    },mounted() {
        this.totalRows = this.items.length
    }

}
</script>