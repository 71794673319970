<template>
   <div class="right-header">
        <b-navbar toggleable="lg" class="bg-light p-0 bg-transparent justify-content-between">
            <b-navbar-brand class="header-name" to="/standapp-home"><img :src="require(`@/assets/standapp/standapp_main.svg`)" width="150" alt="Payangel Business"></b-navbar-brand>
            <b-navbar-toggle target="navbarColor01"></b-navbar-toggle>
            <b-collapse id="navbarColor01" is-nav class="flex-grow-0">
                <b-nav class="navbar-nav ml-auto">
                    <b-nav-item>Contact Us</b-nav-item>
                    <b-nav-item-dropdown variant="link" class="country-dropdown">
                        <template #button-content>
                            <span :class="navbar_details.country_dropdawn.imageClass"></span>{{ navbar_details.country_dropdawn.text_title }}
                        </template>
                        <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.country_dropdawn.citys" :key="index" @click="activeCountry(index)" :active="index == navbar_details.country_dropdawn.active_index">
                            <span :class="city.imageClass" alt=""></span>{{ city.name }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown variant="link" class="lang-dropdown">
                        <template #button-content>
                        {{ navbar_details.language_dropdawn.text_title }}
                        </template>
                        <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.language_dropdawn.citys" :key="index" @click="activeLang(index)" :active="index == navbar_details.language_dropdawn.active_index">
                            {{ city.name }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-form v-if="displayLoginButton">
                        <a href="#" target="_self" class="nav-link btn btn-outline" type="submit">Log in to Personal</a>
                        <router-link to="/standapp-login" class="nav-link btn btn-login">Log in</router-link>
                    </b-nav-form>
                </b-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    name:'SRightHeaderComponent',
    data(){
        return{
            navbar_details: {
                country_dropdawn:{
                    text_title: 'Ghana',
                    imageClass: "flag-icon flag-icon-gh fs-20 mr-2 fi-round fi-md",
                    active_index: 1,
                    citys:[
                        // {
                        //     imageClass: "flag-icon flag-icon-ca fs-20 mr-2 fi-round fi-md",
                        //     name:'Canada'
                        // },
                        // {
                        //     imageClass: "flag-icon flag-icon-gb fs-20 mr-2 fi-round fi-md",
                        //     name:'United Kingdom'
                        // }
                    ]
                },
                language_dropdawn:{
                    text_title: 'EN',
                    active_index: 0,
                    citys:[
                        {
                            name:'EN'
                        },
                        {
                            name:'FR'
                        },
                        {
                            name:'ES'
                        }
                    ]
                },
            }
        }
    },
    methods: {
        activeCountry(index){
            this.navbar_details.country_dropdawn.active_index = index;
            this.navbar_details.country_dropdawn.text_title = this.navbar_details.country_dropdawn.citys[index].name;
            this.navbar_details.country_dropdawn.imageClass = this.navbar_details.country_dropdawn.citys[index].imageClass;

            this.$root.$refs.standappRegister.setCountryDropdown(this.navbar_details.country_dropdawn.citys[index].primaryNumberCode);
            sessionStorage.setItem('countryCode',this.navbar_details.country_dropdawn.citys[index].primaryNumberCode);
        },

        activeLang(index){
            this.navbar_details.language_dropdawn.active_index = index;
            this.navbar_details.language_dropdawn.text_title = this.navbar_details.language_dropdawn.citys[index].name;
        },

        async getCountryData() {
            try {
                const response = await this.$http.get(this.$baseurl+"countries/available");
                // JSON responses are automatically parsed.
                response.data.details.map((item) => {
                    let countryData = {
                        imageClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fs-20 mr-2 fi-round fi-md",
                        name:item.countryname,
                        primaryNumberCode:item.isocode
                    }
                    this.navbar_details.country_dropdawn.citys.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        },  

        setCountry(isocode){
            this.navbar_details.country_dropdawn.citys.forEach((element, index) => {
                if(element.primaryNumberCode == isocode) {
                    this.activeCountry(index);
                }
            })
        }
        
    },computed:{
        displayLoginButton(){
            let authStandappClass = ['shome'].includes(this.$route.name)
            return authStandappClass
        }
    },mounted() {
        this.getCountryData();
        // if(sessionStorage.getItem('countryCode')){
        //     setTimeout(() => {this.setCountry(sessionStorage.getItem('countryCode'));},2000)
        //     console.log('country',sessionStorage.getItem('countryCode'));
        //     // this.setCountry(sessionStorage.getItem('countryCode'));
        // }
        this.$root.$refs.sRightHeader = this;
    }
}
</script>