<template>
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card card-top-line">
                        <div class="header">
                            <h2>Single Invoice</h2>
                            <ul class="header-dropdown">
                                <li class="dropdown"  v-bind:class="{ 'show': cardToggle}"  @click="cardToggle = !cardToggle" v-click-outside="cardToggleOutside">
                                    <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"></a>
                                    <ul class="dropdown-menu dropdown-menu-right" v-bind:class="{ 'show': cardToggle }">
                                        <li><a href="javascript:void(0);">Print Invoices</a></li>
                                        <li role="presentation" class="divider"></li>
                                        <li><a href="javascript:void(0);">Export to XLS</a></li>
                                        <li><a href="javascript:void(0);">Export to CSV</a></li>
                                        <li><a href="javascript:void(0);">Export to XML</a></li>
                                    </ul>
                                </li>
                            </ul>                            
                        </div>
                        <div class="body">
                            <h3>Invoice Details : <strong class="text-primary">#A0089</strong></h3>
                            <ul class="nav nav-tabs-new2">
                                <li class="nav-item inlineblock"><a class="nav-link" v-on:click="setActive('Details')" :class="{ active:isActive('Details') }">Details</a></li>                                
                                <li class="nav-item inlineblock"><a class="nav-link" v-on:click="setActive('History')" :class="{ active:isActive('History') }">History</a></li>
                            </ul>
                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane in" id="details" aria-expanded="true" :class="{ active:isActive('Details') }">
                                    <div class="row clearfix">
                                        <div class="col-md-6 col-sm-12">
                                            <address>
                                                <strong>ThemeMakker Inc.</strong> <span v-if="$route.query.recipient == 'groups'" v-b-modal.members class="badge badge-filled badge-primary cursor-pointer"><strong title="Email">+6</strong> Others</span><br>
                                                795 Folsom Ave, Suite 546<br>
                                                San Francisco, CA 54656<br>
                                                United States<br>
                                                <strong title="Phone mt-1">Phone:</strong> (123) 456-34636<br>
                                                <strong title="Email">Email:</strong> jones@payangel.com
                                            </address>
                                        </div>
                                        <div class="col-md-5 col-sm-12">
                                            <div class="d-flex justify-content-md-end">
                                                <div class="invoice-detail-right">
                                                    <p class="m-b-0"><strong>Invoice Date: </strong> Jun 15, 2018</p>
                                                    <p class="m-b-0"><strong>Due Date: </strong> Jun 15, 2018</p>
                                                    <p class="m-b-0"><strong>Invoice Status: </strong> <span class="badge badge-warning m-b-0">Pending</span></p>
                                                    <p class="m-b-0" v-if="$route.query.recipient == 'groups'"><strong>Invoice Number: </strong> <span v-b-modal.members class="text-primary cursor-pointer font-weight-bold">#123456</span></p>
                                                    <p class="m-b-0" v-if="$route.query.recipient != 'groups'"><strong>Invoice Number: </strong> #123456</p>
                                                    <p><strong>Invoice Currency: </strong> USD</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 col-sm-3 text-md-right mb-3">
                                            <qrcode-vue :value="value" :size="size" level="H" />
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-12">
                                            <b-table 
                                                responsive 
                                                hover  
                                                outlined 
                                                table-class="js-basic-example table-custom mb-0"
                                                head-variant="light"
                                                :items="detailsItems" 
                                                :fields="detailsFields"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection">
                                                <template #head()="{label,  field: { key }}">
                                                    {{ label }}
                                                    <!-- Custom icons -->
                                                    <template>
                                                        <i v-if="sortBy !== key" class=""></i>
                                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                    </template>  
                                                </template>
                                                <template #cell(#)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row clearfix">
                                        <div class="col-md-6">
                                            <h5>Note</h5>
                                            <p>Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.</p>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <p class="m-b-0"><b>Sub-total:</b> $2,930.00</p>
                                            <p class="m-b-0">Discout: $12.9%</p>
                                            <p class="m-b-0">VAT: $12.9%</p>                                        
                                            <h3 class="m-b-0 m-t-10">USD 2,930.00</h3>
                                        </div>                                    
                                        <div class="hidden-print col-md-12 text-right" v-if="!isApproveInvoice">
                                            <hr>
                                            <router-link to="/invoice/add-invoice-item" class="btn btn-secondary btn-lg btn-mw mr-md-1 my-2 my-md-0">Edit</router-link>
                                            <button class="btn btn-primary btn-mw btn-lg" @click="approvedInvoice()">Generate Invoice</button>
                                        </div>
                                        <div class="hidden-print col-md-12 text-right" v-if="isApproveInvoice">
                                            <hr>
                                            <router-link to="/invoice/list" class="btn btn-secondary btn-lg btn-mw mr-md-1 my-2 my-md-0">Back to Invoices</router-link>
                                            <button class="btn btn-primary btn-lg btn-mw mr-md-1 my-2 my-md-0" @click="copyLink()">Copy Link</button>
                                            <button class="btn btn-info btn-lg btn-mw mr-md-1 my-2 my-md-0">Download</button>
                                            <button class="btn btn-warning btn-mw btn-lg btn-lg btn-mw mr-md-1 my-2 my-md-0" v-b-modal.send_email>Send Email</button>
                                        </div>
                                        <!-- send emails modal-->
                                        <b-modal id="send_email" title="Send Emails" hide-footer>
                                            <div class="modal-area">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="send_emails" @submit.prevent="passes(sendEmailFormSubmit)">
                                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                                             <li>
                                                                <div class="form-group form-select single-multiselect">
                                                                    <span class="float-label">
                                                                        <label for="functions">Send Email to</label>
                                                                        <multiselect
                                                                            :disabled="true"
                                                                            placeholder=""
                                                                            v-model="sendEmails.sendInvoiceRecipientTo"  
                                                                            :show-labels="false" 
                                                                            :options="sendInvoiceRecipientToOptions" 
                                                                            :searchable="false">
                                                                        </multiselect> 
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                                    <p class="mb-0 mr-3 custom-switch-text">Send to Other Recipients?</p>
                                                                    <div class="custom-switch success-handle">
                                                                        <input type="checkbox" id="recipients" class="custom-switch-input" v-model="sendEmails.otherRecipient">
                                                                        <label for="recipients" class="custom-switch-label">
                                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li v-if="sendEmails.otherRecipient">   
                                                                <ValidationProvider name="emails" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors.length && !sendEmails.emails,'success':sendEmails.emails}">
                                                                        <span class="float-label">
                                                                            <textarea type="text" v-model="sendEmails.emails" class="form-control" id="emails" placeholder=""/>
                                                                            <label for="emails" class="control-label">Send Email to*</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && !sendEmails.emails"></i>
                                                                            <i class="fa fa-check" v-if="sendEmails.emails"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                                    <p class="mb-0 mr-3 custom-switch-text">Remind 3 days before Due date</p>
                                                                    <div class="custom-switch success-handle">
                                                                        <input type="checkbox" id="threeDaysBeforeDueDate" class="custom-switch-input" v-model="sendEmails.threeDaysBeforeDueDate">
                                                                        <label for="threeDaysBeforeDueDate" class="custom-switch-label">
                                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                                    <p class="mb-0 mr-3 custom-switch-text">Remind 1 day before Due date</p>
                                                                    <div class="custom-switch success-handle">
                                                                        <input type="checkbox" id="oneDaysBeforeDueDate" class="custom-switch-input" v-model="sendEmails.oneDaysBeforeDueDate">
                                                                        <label for="oneDaysBeforeDueDate" class="custom-switch-label">
                                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                                    <p class="mb-0 mr-3 custom-switch-text">Remind on Due date</p>
                                                                    <div class="custom-switch success-handle">
                                                                        <input type="checkbox" id="remindOnDueDate" class="custom-switch-input" v-model="sendEmails.remindOnDueDate">
                                                                        <label for="remindOnDueDate" class="custom-switch-label">
                                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                                    <p class="mb-0 mr-3 custom-switch-text">Remind 3 days after the Due date</p>
                                                                    <div class="custom-switch success-handle">
                                                                        <input type="checkbox" id="threeDaysAfterDueDate" class="custom-switch-input" v-model="sendEmails.threeDaysAfterDueDate">
                                                                        <label for="threeDaysAfterDueDate" class="custom-switch-label">
                                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Send Email</b-button>
                                                                    <b-button @click="$bvModal.hide('send_email')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                </ValidationObserver>
                                            </div>
                                        </b-modal>
                                    </div>                                    
                                </div>                        
                                <div role="tabpanel" class="tab-pane" id="history" aria-expanded="false" :class="{ active:isActive('History') }">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <address>
                                                <strong>ThemeMakker Inc.</strong> <span v-if="$route.query.recipient == 'groups'" v-b-modal.members class="badge badge-filled badge-primary cursor-pointer"><strong title="Email">+6</strong> Others</span><br>
                                                795 Folsom Ave, Suite 546<br>
                                                San Francisco, CA 54656<br>
                                                United States<br>
                                                <strong title="Phone mt-1">Phone:</strong> (123) 456-34636<br>
                                                <strong title="Email">Email:</strong> jones@payangel.com
                                            </address>
                                        </div>
                                        <div class="col-md-5 col-sm-12">
                                            <div class="d-flex justify-content-md-end">
                                                <div class="invoice-detail-right">
                                                    <p class="m-b-0"><strong>Invoice Date: </strong> Jun 15, 2018</p>
                                                    <p class="m-b-0"><strong>Due Date: </strong> Jun 15, 2018</p>
                                                    <p class="m-b-0"><strong>Invoice Status: </strong> <span class="badge badge-warning m-b-0">Pending</span></p>
                                                    <p class="m-b-0" v-if="$route.query.recipient == 'groups'"><strong>Invoice Number: </strong> <span v-b-modal.members class="text-primary cursor-pointer font-weight-bold">#123456</span></p>
                                                    <p class="m-b-0" v-if="$route.query.recipient != 'groups'"><strong>Invoice Number: </strong> #123456</p>
                                                    <p><strong>Invoice Currency: </strong> USD</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 col-sm-3 text-right">
                                            <qrcode-vue :value="value" :size="size" level="H" />
                                        </div>
                                    </div>
                                    <div class="mt-40"></div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-table 
                                                responsive
                                                table-class="mb-0 table-custom border"
                                                head-variant="light"
                                                :items="resultQuery" 
                                                :fields="fields"
                                                :current-page="currentPage"
                                                :per-page="perPage"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection">
                                                <template #head()="{label,  field: { key }}">
                                                    {{ label }}
                                                    <!-- Custom icons -->
                                                    <template>
                                                        <i v-if="sortBy !== key" class=""></i>
                                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                    </template>  
                                                </template>
                                                <template #cell(id)="data">
                                                    <router-link to="/invoice/detail">{{ data.value }}</router-link>
                                                </template>
                                                <template #cell(amountDetails)="data">
                                                    <span>{{ data.value.currencyprefix}}{{ data.value.amount }}</span>
                                                </template>
                                                <template #cell(status)="data">
                                                    <p class="function-badge badge-group-table mb-0">
                                                        <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                                    </p>
                                                </template>
                                                <template #cell(action)>
                                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                        <b-dropdown-item @click="onEdit">Edit</b-dropdown-item>
                                                        <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                            </b-table>
                                            <!-- Table Pagination -->
                                            <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                                                <div class="per-page">
                                                    <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="number-page">
                                                    <b-pagination 
                                                        v-model="currentPage" 
                                                        :total-rows="totalRows" 
                                                        :per-page="perPage"
                                                        hide-goto-end-buttons 
                                                        hide-ellipsis 
                                                        prev-text="Previous page" 
                                                        next-text="Next page" 
                                                        align="right">
                                                    </b-pagination>
                                                </div>
                                                <div class="go-page">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Go To Page</span>
                                                        </div>
                                                        <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="members" title="Invoice Recipients" hide-footer>
                <div class="modal-area">
                    <ul class="list-unstyled mb-0 common-form small-form">
                        <li>
                            <ul class="list-unstyled invoice-list mt-2">
                                <li class="row" v-for="(member,i) in membersList" :key="i">
                                    <label class="col-lg-4 col-sm-6 font-weight-bold">{{member.name}}:</label>
                                    <p class="col-lg-8">{{member.email}}</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </b-modal>
        </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import QrcodeVue from 'qrcode.vue'
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default

export default {
    name:'InvoicePreviewComponent',
    components: {
        BreadCrumb,
        QrcodeVue,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data() {
        return {
            searchQuery: null,
            isApproveInvoice:false,
            activeTab: 'Details',
            cardToggle:false,
            value: 'https://example.com',
            size: 60,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            //Invoice list table json
            detailsItems:[
                {name:'Simple Black Clock',description:'Lorem ipsum dolor sit amet.',quantity:'1',unitCost:'$1,500',total:'$1,500'},
                {name:'Brone Candle',description:'There are many variations of passages of Lorem Ipsum',quantity:'5',unitCost:'$50',total:'$250'},
                {name:'Wood Simple Clock',description:'Lorem ipsum dolor sit amet.',quantity:'2',unitCost:'$500',total:'$1,000'},
                {name:'Unero Small Bag',description:'Contrary to popular belief, not simply random text',quantity:'3',unitCost:'$300',total:'$900'},
            ],
           
            //Invoice list table header fields
            detailsFields: [
                '#',
                { key: 'name', label: 'Item',sortable: true,thClass:'th_sortfix'},
                { key: 'description', label: 'Description',sortable: true,thClass:'th_sortfix'},
                { key: 'quantity', label: 'Quantity',sortable: true,thClass:'th_sortfix'},
                { key: 'unitCost', label: 'Unit Cost',sortable: true,thClass:'th_sortfix'},
                { key: 'total', label: 'Total'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Table data
            items:[
                {id: 'A0089',name:'ThemeMakker Inc.',date:'15-Aug-2021',dueDate:'Aug 15, 2021',amountDetails:{currencyprefix:'$',amount:'550'},status:{name:'Pending',class:'badge-warning'},action:''},
                {id: 'A0080',name:'East Repair Inc.',date:'17-Aug-2021',dueDate:'Aug 20, 2021',amountDetails:{currencyprefix:'$',amount:'1,905'},status:{name:'Paid',class:'badge-success'},action:''},
                {id: 'A0081',name:'Saffron Design Inc.',date:'19-Aug-2021',dueDate:'Aug 15, 2021',amountDetails:{currencyprefix:'$',amount:'5,390'},status:{name:'Unpaid',class:'badge-danger'},action:''}
            ],
           
           //Table header
            fields: [
                { key: 'date', label: 'Date',sortable: true,thClass:'th_sortfix'},
                { key: 'id', label: 'Invoice ID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'dueDate', label: 'Due Date',sortable: true,thClass:'th_sortfix'},
                { key: 'amountDetails', label: 'Amount',sortable: true,thClass:'th_sortfix text-right',tdClass:'text-right'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'text-right th_sortfix',tdClass:'text-right'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],
            //Table Pagination
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],

            sendEmails:{
                sendInvoiceRecipientTo:['Groups 1'],
                otherRecipient: false,
                emails:'',
                threeDaysBeforeDueDate:false,
                oneDaysBeforeDueDate:false,
                remindOnDueDate:true,
                threeDaysAfterDueDate:false,
            },

            sendInvoiceRecipientToOptions:[
                'Groups 1'
            ],

            membersList:[
                {name:"Michael Truong", email:"michael.truong@gmail.com"},
                {name:"John Smith", email:"john.smith@gmail.com"},
                {name:"Charlotte Jake", email:"charlotte.jake@gmail.com"},
                {name:"Grayson Shams", email:"grayson.shams@gmail.com"},
                {name:"Jacob Camly", email:"jacob.camly@gmail.com"},
                {name:"Amelia Hank", email:"amelia.hank@gmail.com"},
                {name:"Michael Larry", email:"michael.larry@gmail.com"}
            ]           
        }
    },methods:{
        isActive: function (activeTab) {
            return this.activeTab === activeTab
        },

        setActive: function (activeTab) {
            this.activeTab = activeTab 
        },

        cardToggleOutside: function() {
            this.cardToggle = false
        },

        approvedInvoice(){
            this.$swal.fire({
                title: 'Invoice Generated Successfully',
                icon: 'success',
                showCancelButton: false, 
                showConfirmButton: false,              
            })

            setTimeout(() => {
                this.$swal.close()
                this.isApproveInvoice = true
            },2000);
        },

        copyLink(){
            var inputofKey = document.createElement("input");
            inputofKey.value = 'https://192.168.225.29:8080/invoice/add-invoice-item';
            document.body.appendChild(inputofKey);
            inputofKey.select();
            document.execCommand("Copy");
            inputofKey.remove();
        },

        sendEmailFormSubmit(){
            this.$bvModal.hide('send_email')
            this.$swal.fire({
                title: 'Email is successfully sent',
                icon: 'success',
                showCancelButton: false, 
                showConfirmButton: false,              
            })
        },

        onEdit: function () {
            this.$router.push({path:'/invoice/add-invoice-details'})
        },

        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    },computed: {
        resultQuery(){
            if(this.searchQuery){
                return this.items.filter((searchItem)=>{
                    return this.searchQuery.toLowerCase().split(' ').every(v => searchItem.name.toLowerCase().includes(v))
                })
            }else{
                return this.items;
            }
        }
    }
}
</script>