<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card card-top-line">
                    <div class="body">
                        <form id="invoice_type_form" @submit.prevent="invoiceSubmit">
                            <div class="row clearfix">
                                <div class="col-md-6 col-sm-12">
                                    <address>
                                        <strong>ThemeMakker Inc.</strong> <span v-if="this.$route.query.recipient == 'groups'" v-b-modal.members class="badge badge-filled badge-primary cursor-pointer"><strong title="Email">+6</strong> Others</span><br>
                                        795 Folsom Ave, Suite 546<br>
                                        San Francisco, CA 54656<br>
                                        United States<br>
                                        <strong title="Phone mt-2">Phone:</strong> (123) 456-34636<br>
                                        <strong title="Email">Email:</strong> jones@payangel.com
                                    </address>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="d-flex justify-content-md-end">
                                        <div class="invoice-detail-right">
                                            <p class="m-b-0"><strong>Invoice Date: </strong> Jun 15, 2018</p>
                                            <p class="m-b-0"><strong>Due Date: </strong> Jun 15, 2018</p>
                                            <p class="m-b-0"><strong>Invoice Status: </strong> <span class="badge badge-warning m-b-0">Pending</span></p>
                                            <p class="m-b-0" v-if="$route.query.recipient == 'groups'"><strong>Invoice Number: </strong> <span v-b-modal.members class="text-primary cursor-pointer font-weight-bold">#123456</span></p>
                                            <p class="m-b-0" v-if="$route.query.recipient != 'groups'"><strong>Invoice Number: </strong> #123456</p>
                                            <p><strong>Invoice Currency: </strong> USD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <b-table-simple responsive class="mb-0 mt-4 table-invoice-item" table-class="border">
                                <b-thead class="thead-light">
                                    <b-tr>
                                        <b-th class="text-center">#</b-th>
                                        <b-th>Name</b-th>
                                        <b-th>Description</b-th>
                                        <b-th class="text-center">Quantity</b-th>
                                        <b-th class="text-center invoice-currency-header">Currency</b-th>
                                        <b-th class="text-center">Price</b-th>
                                        <b-th class="text-center">Total</b-th>
                                        <b-th class="text-center">&nbsp;</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(invoice,index) in invoicesRows" :key="index">
                                        <b-td class="text-center">{{ index+1 }}</b-td>
                                        <b-td><input type="text" class="form-control name" v-model="invoice.name"></b-td>
                                        <b-td><input type="text" class="form-control details" v-model="invoice.description"></b-td>
                                        <b-td align="right"><input type="text" class="form-control qty" v-model="invoice.qty" @keypress="onlyNumber($event)"></b-td>
                                        <b-td class="invoice-currency-drop">
                                            <div class="form-group form-select multiselect-grp mb-0">
                                                <span class="float-label">
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="invoice.currency" 
                                                        :show-labels="false" 
                                                        :options="currencyOptions" 
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name">
                                                        <template slot="option" slot-scope="props">
                                                            <div class="option-title">
                                                                <span :class="props.option.flagClass" class="mr-2"></span>
                                                                <span>{{ props.option.name }}</span>
                                                            </div>
                                                        </template>
                                                    </multiselect> 
                                                </span>
                                            </div>
                                        </b-td>
                                        <b-td align="right"><input type="text" class="form-control rates" v-model="invoice.price" @keypress="onlyNumber($event)"></b-td>
                                        <b-td align="right"><input type="text" class="form-control total" v-model.number="subtotalRow[index]" readonly></b-td>
                                        <b-td><i class="fa fa-times text-danger cursor-pointer" @click="removeRow(index)"></i></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- invoice-calc -->
                            <div class="invoice-calc">
                                <div class="d-flex justify-content-between flex-column flex-md-row-reverse">
                                    <div class="add-row-btn">
                                        <b-button variant="primary btn-mw" size="lg" @click="addNewRow">Add New Row</b-button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row clearfix">
                                <div class="col-md-6">
                                    <h5>Note</h5>
                                    <p>Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.</p>

                                    <h5 v-if="invoiceType == 'recurring'">Invoice Repeating Details</h5>
                                    <p v-if="invoiceType == 'recurring'" class="m-b-0"><strong>Start Date: </strong> Jun 15, 2022</p>
                                    <p v-if="invoiceType == 'recurring'" class="m-b-0"><strong>End Date: </strong> Jun 15, 2025</p>
                                    <p v-if="invoiceType == 'recurring'"><strong>Frequency: </strong> Monthly</p>
                                </div>
                                <div class="offset-md-3 col-md-3">
                                    <ul class="list-unstyled mb-0">
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Sub-total:</label>
                                                <p class="mb-0">${{ formateNumbers(parseFloat(total).toFixed(2)) }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 font-weight-bold mb-0">Tax:</label>
                                                    <span class="font-weight-bold">10%</span>
                                                </div>
                                                <div>
                                                    <p class="mb-0">
                                                        ${{  formateNumbers(parseFloat(grandTotal.taxAmount).toFixed(2)) }}
                                                    </p>
                                                </div>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">VAT:</label>
                                                <p class="mb-0">${{ formateNumbers(parseFloat(invoice.vat).toFixed(2)) }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Discount:</label>
                                                <p class="mb-0">${{ formateNumbers(parseFloat(invoice.discount).toFixed(2)) }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Grand Total:</label>
                                                <p class="mb-0">${{ formateNumbers(parseFloat(grandTotal.grandtotal).toFixed(2)) }}</p>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            <div class="hidden-print d-flex flex-column flex-md-row justify-content-end mt-3">
                                <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw mr-md-1 my-2 my-md-0">Cancel</router-link>                                    
                                <b-button type="submit" variant="primary btn-mw" size="lg">Preview</b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="members" title="Invoice Recipients" hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled mb-0 common-form small-form">
                    <li>
                        <ul class="list-unstyled invoice-list mt-2">
                            <li class="row" v-for="(member,i) in membersList" :key="i">
                                <label class="col-lg-4 col-sm-6 font-weight-bold">{{member.name}}:</label>
                                <p class="col-lg-8">{{member.email}}</p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Multiselect = require('vue-multiselect').default

export default {
    name:'AddInvoiceItemComponent',
    components: {
        BreadCrumb,
        Multiselect
    },data() {
        return {
            invoicesRows: [
                {name:"", description: "",qty: null ,currency:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},price: null,total: null},
            ],

            invoice:{
               taxSelected: 10,      
               vat: 0,
               discount:0                                           
            },
            invoiceType: this.$route.query.type,
            invoiceInformations:[
                {key:'Business Name',value:'ThemeMakker Inc.'},
                {key:'Email',value:'thememakker@gmail.com'},
                {key:'Phone',value:'289-335-6503'},
                {key:'country',value:'United Kingdom'},
                {key:'First Name',value:'Michael'},
                {key:'Last Name',value:'Truong'},
                {key:'Street',value:'High Street'},
                {key:'Town/City',value:'Bristol'},
                {key:'Note',value:'An invoice is a time-stamped commercial document that itemizes and records a transaction between a buyer and a seller.'},
            ],

            value: 'https://example.com',
            size: 60,
            currencyOptions:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],

            membersList:[
                {name:"Michael Truong", email:"michael.truong@gmail.com"},
                {name:"John Smith", email:"john.smith@gmail.com"},
                {name:"Charlotte Jake", email:"charlotte.jake@gmail.com"},
                {name:"Grayson Shams", email:"grayson.shams@gmail.com"},
                {name:"Jacob Camly", email:"jacob.camly@gmail.com"},
                {name:"Amelia Hank", email:"amelia.hank@gmail.com"},
                {name:"Michael Larry", email:"michael.larry@gmail.com"}
            ]
        }
    },methods:{
        addNewRow: function(){
            this.invoicesRows.push({name:"", description:"",qty:"",currency:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},price:"",total:""});
        },

        removeRow: function(index){
            this.invoicesRows.splice(index, 1);
        },

        invoiceSubmit(){
            this.$router.push({path:'/invoice/detail', query:{ type: this.$route.query.type, recipient: this.$route.query.recipient }});
        },

        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },

        formateNumbers(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },computed: {
        subtotalRow() {
          return this.invoicesRows.map((item) => {
            return Number(item.qty * item.price)
          });
        },

        total() {
            return this.invoicesRows.reduce((total, item) => {
                return total + item.qty * item.price;
            }, 0);
        },

        grandTotal() {
            var total = this.total;
            var selectedTax = this.invoice.taxSelected;
            var taxAmount = total/100 * selectedTax;
            var grandtotal = taxAmount + this.total;
            return{
                taxAmount,
                grandtotal
            }
        }
    }
}
</script>