<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead">Update Email Address</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(updateEmailSubmit)" >
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !updateEmaildata.email || errors[0],'success':updateEmaildata.email && !errors[0] ,'mb-0':apiErrorCode == 'E0002-002'}">
                            <span class="float-label">
                                <input type="email" v-model="updateEmaildata.email" class="form-control" name="email" id="forgot-email">
                                <label for="forgot-email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !updateEmaildata.email || errors[0]"></i>
                                <i class="fa fa-check" v-if="updateEmaildata.email && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please provide valid email</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0002-002'">{{apiErrorMsg}}</span> 
                    <b-button type="submit" block variant="primary" size="lg">Update Email</b-button>
                    <div class="bottom">
                        <span class="helper-text">Know your password? <router-link to="/standapp-login">Login</router-link></span>
                    </div>
                </form>
            </div>
        </ValidationObserver>
    </div>  
</template>

<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
import { mapState } from 'vuex';
import shared from "@/shared.js";

export default {
    name: 'StandappChangeEmailComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        updateEmailSubmit() {
            let updateEmailData = {
                newemail: this.updateEmaildata.email,
                email: sessionStorage.getItem('businessUserEmail'),
                businessid: sessionStorage.getItem('registeredBusinessId'),
            }
            //console.log(updateEmailData);

            this.$http.put(this.$baseurl+'business/register/changeemail', updateEmailData)
            .then((res) => {
                //Perform Success Action
                if(res.data.code == '0002-009') {
                    sessionStorage.setItem('businessUserEmail', this.updateEmaildata.email);
                    let resMessage = res.data.message; //'Please provide reset passcode which has receive by mail'
                    //this.$swal('Success!', resMessage, 'success', 'OK');
                    shared.toastrSuccess(resMessage);

                    this.$router.push({ path: '/standapp-otp' })
                } else {
                   // this.$swal('Error!', res.data.message, 'error', 'Close');
                    shared.toastrError(res.data.message);
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                //console.log('forgotPassword',error.response.data.code);
                //console.log("Response", error.response.data.code);
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
                shared.toastrError(error.response.data.message);
            });
        }
    },
    data(){
        return{
            updateEmaildata: {
                email: "",
            },
            apiErrorCode : '',
            apiErrorMsg : ''

        }

    }, computed: mapState({
        businessRegistrationDetail: state => state.registration.businessDetail,
        personalDetail: state => state.personalInfo  
    })
}
</script>
<style scoped>

</style>