// initial state
const state = () => ({
    businessDetail: {
      country: '',
      businessName: '',
      categoryid: '',
      email: '',
      mobile: '',
      countryName: '',
      categoryName: ''
    }
})

// getters
const getters = {}

// actions
const actions = {
    addBusiness ({ commit }, businessDetails) {
        commit('setBusiness', businessDetails)
    },
    clearBusiness({ commit }) {
      commit('clearBusinessData')
    }
}

// mutations
const mutations = {
  setBusiness (state, businessDetails) {
    state.businessDetail.country = businessDetails.country,
    state.businessDetail.businessName = businessDetails.name,
    state.businessDetail.categoryid = businessDetails.categoryid,
    state.businessDetail.email = businessDetails.email,
    state.businessDetail.mobile = businessDetails.mobile,
    state.businessDetail.countryName = businessDetails.countryName,
    state.businessDetail.categoryName = businessDetails.categoryName
  },
  clearBusinessData(state) {
    state.businessDetail.country = '',
    state.businessDetail.businessName = '',
    state.businessDetail.categoryid = '',
    state.businessDetail.email = '',
    state.businessDetail.mobile = '',
    state.businessDetail.countryName = '',
    state.businessDetail.categoryName = ''

  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}