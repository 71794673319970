<template>
    <div class="add-business">
        <!-- Add Fund -->
        <b-modal id="add_fund_option" title="Add Payout Option" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(addFundOptionForm)" id="single-form">
                        <li>
                            <ValidationProvider name="name" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !addFundDatas.name || errorArr[1] && errorArr[1].fieldName!='','success':addFundDatas.name && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="addFundDatas.name" class="form-control" id="name" placeholder="Add Payout Option Name*">
                                        <!--  @input="checkNameFormat($event, 'name', 'Owner First Name', 'single', 1)" -->
                                        <label for="owner" class="control-label">Add Payout Option Name</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !addFundDatas.name || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.name && errorArr[1] && errorArr[1].fieldName==''"></i>
                                    </span>
                                    <!-- <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span> -->
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addFundDatas.accountName && addFundDatas.accountName.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Account to be Debited*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addFundDatas.accountName"  
                                            :show-labels="false" 
                                            :options="accountOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.accountName && addFundDatas.accountName.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Payout Option</b-button>
                            <b-button @click="$bvModal.hide('add_fund_option')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'

export default {
    name: 'AddPayoutOptionModal',
    props: {
        buttonClass: String
    },components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },data(){
        return{
            addFundDatas:{
                name:'',
                accountName:''
            },
            selectedBusinessType: '',
            mobileInputError:'',

            accountOptions:[
                {'id':'1', 'name':'HSBC Holdings'},
                {'id':'2', 'name':'JPMorgan Chase & Co'},
                {'id':'3', 'name':'Airtel Ghana Limited'},
            ],
            
            errorArr : [],

            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            elementName : '',
            apiErrorCode: '',
            formType: ''
        }
    },methods:{
        resetForm(){
            // clear single business form fields
            Object.keys(this.addFundDatas).forEach(v => this.addFundDatas[v] = '')
        },
        // checkNameFormat(e, fieldName, elementName, form, elementIndex) {
        //     if(form==='single') {
        //         this.formType = this.singleBusinessDatas;
        //     }
        //     let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
        //     let charLength = e.target.value.length;
        //     if(/^[A-Za-z'-\s]+$/.test(char)) {
        //         var splitStr = e.target.value.split(' ');
        //         for (var i = 0; i < splitStr.length; i++) {
        //             if(splitStr[0]!='')
        //             splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        //         }
        //     this.formType[fieldName] = splitStr.join(' ');
        //         if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
        //             this.fieldErrorMsg = '';
        //             this.fieldName = '';
        //             if(this.errorArr.some(obj => obj.index == elementIndex)) {
        //                 this.errorArr[elementIndex].fieldName = '';
        //                 this.errorArr[elementIndex].errorMessage = '';
        //                 this.errorArr[elementIndex].index = elementIndex;
        //                 //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
        //             }                   
        //             return true; // Match with regex 

        //         } else {
        //             if(this.errorArr.some(obj => obj.index == elementIndex)) {
        //                 this.errorArr[elementIndex].fieldName = fieldName;
        //                 this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
        //                 this.errorArr[elementIndex].index = elementIndex;
        //                 //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
        //             }                   



        //         }
        //     } else {
        //         this.formType[fieldName] = char;
        //         this.fieldErrorMsg = 'Please provide valid value for '+elementName;
        //         if(!this.errorArr.some(obj => obj.index == elementIndex)) {
        //             this.errorArr.push({'fieldName': fieldName, errorMessage:this.fieldErrorMsg, 'index': elementIndex})
        //         } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
        //             this.errorArr[elementIndex].fieldName =  fieldName;
        //             this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
        //             this.errorArr[elementIndex].index = elementIndex;
        //         } else {
        //             // do nothing 
        //         }
               
        //         e.preventDefault(); // If not match, don't add to input text
        //     }
        //     console.log(this.errorArr);
        // },
        //add fund submit form data
        addFundOptionForm(){
            console.log('add fund')
        }

    }
}
</script>
