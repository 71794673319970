<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Manage Funds</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-lg-flex justify-content-between py-3">
                            <div class="d-md-flex justify-content-between">
                                <div class="mr-0 mr-md-3 action-top-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search fund" aria-label="Search fund" aria-describedby="search-fund" v-model="searchText" v-on:keyup.enter="refreshFundTable">
                                        <div class="input-group-append" @click="refreshFundTable()">
                                            <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="fliter-main mt-3 mt-md-0">
                                    <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                    <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                        <div class="d-flex justify-content-between p-3">
                                            <div class="form-group form-select single-multiselect">
                                                <span class="float-label">
                                                    <multiselect
                                                        placeholder="Select Filter Type"
                                                        v-model="filterBy"  
                                                        :show-labels="false" 
                                                        :options="filterOption" 
                                                        label="name"
                                                        :searchable="true">
                                                    </multiselect> 
                                                </span>
                                                <!-- <input class="form-control" type="text" placeholder="Input One"/> -->
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group form-select single-multiselect">
                                                <span class="float-label">
                                                    <multiselect
                                                        placeholder="Select Filter Type"
                                                        v-model="condition"  
                                                        :show-labels="false" 
                                                        :options="conditionList" 
                                                        label="name"
                                                        :searchable="true">
                                                    </multiselect> 
                                                </span>
                                                <!-- <input class="form-control" type="text" placeholder="Input Two"/> -->
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshFundTable()">Apply</button>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                    <b-button size="lg" variant="primary btn-mw" @click="createFund()">Add Funds <i class="fa fa-plus-square"></i></b-button>
                                    <!-- <b-button class="mr-0 mr-md-3" variant="outline-primary btn-mw" size="lg"  @click="withdrawFund()">Withdraw Funds <i class="fa fa-external-link"></i></b-button> -->
                                </div>
                            </div>
                        </div>

                        <b-table 
                            ref="fundTable"
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="getFundData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(dropdawn)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'" >
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-withdraw-modal></add-withdraw-modal>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const AddWithdrawFundModal = require('@/components/admin/fund/AddWithdrawFundModal.vue').default
// const { toastFunction } =  require("@/plugins/toast")
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
// const { sweetAlertFunction } =  require("@/plugins/sweetalert2")
import Multiselect from 'vue-multiselect'
import shared from "@/shared.js";

export default {
    name:'FundComponent',
    components: {
        BreadCrumb,
        'add-withdraw-modal': AddWithdrawFundModal,
        'main-loader':LoaderComponent,
        Multiselect
    },data(){
        return{
            isVisibleFilter: false,

            options: [
                { value: 'pending', text:'Pending' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' },
            ],

            items:[],
           
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'paymentOption', label: 'Payment Option',sortable: true,thClass:'th_sortfix'},
                { key: 'reference', label: 'Payment Reference'},
                { key: 'currency', label:'Currency' },
                { key: 'amount', label:'Amount' },
                { key: 'dropdawn', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            filterOption: [
                { value: 'walletname', name:'Wallet Name' },
                { value: 'paymentoptionname', name:'Payment Option Name' },
                { value: 'paymentreference', name:'Payment Reference' },
                { value: 'amount', name:'Amount' },
                { value: 'status', name:'Status' },
                { value: 'createdat', name:'Created At'},
                { value: 'createdby', name:'Created By' },
            ],

            conditionList: [
                { value: '==', name:'equals to' },
                { value: '>=', name:'greater than or equals to' },
                { value: '>', name:'greater than' },
                { value: '<', name:'less than' },
                { value: '<=', name:'less than or equals to' },
                { value: 'like', name:'like' }
            ],

            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: ''
        }
    },methods: {

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        async getFundData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'desc'
                } else {
                    sort = 'asc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText,
                    filterby : this.filterBy ? this.filterBy.value : '',
                    filtervalue : this.filterValue,
                    condition : this.condition ? this.condition.value : '=='
                }
                // console.log(paramData);
                const response = await this.$http.get(this.$baseurl+"transactions/funds/"+sessionStorage.getItem("businessId"), { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.transactions.map((item) => {
                    let dropdownMenu = '';
                    if(item.status == 'pending'){
                        dropdownMenu = {selected:'pending',class:'btn-outline-warning',id:item.walletid,name:'Pending'}
                    } else if(item.status == 'approved') {
                        dropdownMenu = {selected:'approved',class:'btn-outline-success',id:item.walletid,name:'Approved'}
                    } else {
                        dropdownMenu = {selected:'rejected',class:'btn-outline-danger',id:item.walletid,name:'Rejected'}
                    }
                    let fundLists = {
                        walletid:item.walletid,
                        name:item.walletname,
                        paymentOption:item.paymentoptionname,
                        reference:item.paymentreference,
                        currency:item.currencycode,
                        amount:parseFloat(item.amount).toFixed(2),
                        dropdawn:dropdownMenu
                    };
                    this.items.push(fundLists);
                }); 
                this.totalRows = response.data.query.total;
                return this.items;               
            } catch (error) {
                console.log(error);
            }
        },

        refreshFundTable() {
            this.$refs.fundTable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshFundTable();
        },

        createFund(){
            this.$bvModal.show('add_fund');
        },
        withdrawFund(){
            this.$bvModal.show('withdraw_fund');
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.totalRows = this.items.length;
            this.$root.$refs.FundComponent = this;
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>
