<template>
    <fragment>
        <div v-for="(categoryitem,index) in categoryItems" :key="'category_'+index">
            <!--All Main Category Iteams table -->
            <b-table 
                responsive
                hover 
                outlined
                table-class="js-basic-example table-custom mb-0"
                head-variant="light"
                :items="categoryitem.items" 
                :fields="fields"
                thead-class="d-none">
                <template #cell(action)="row">
                    <a class="btn" :class="[row.detailsShowing  ? 'btn-primary text-white' : 'btn-outline-primary']" href="javascript:void(0);" @click="row.toggleDetails">
                        <i class="fa fa-angle-down" v-if="!row.detailsShowing"></i>
                        <i class="fa fa-angle-up" v-if="row.detailsShowing"></i>
                    </a>
                </template>
                <template #cell(name)="row">
                    <span class="font-weight-bold">{{ row.value }}</span>
                </template>
                <!--toggle sub-details onclick main category -->
                <template #row-details="row">
                    
                    <b-card >
                        <!-- category reqirment Modal -->
                        <div class="d-md-flex justify-content-end py-3">
                            <b-button variant="primary btn-mw" 
                            size="lg" v-b-modal="'categoryreq_' + row.index" @click="getCurrentModalId(row.index, row.item.id)">Add Field  <i class="fa fa-plus-square ml-1"></i></b-button>
                            <b-modal :id="'categoryreq_' + row.index" title="Add Field" hide-footer no-stacking no-close-on-backdrop @hide="resetForm">
                                <div class="modal-area">
                                    <ValidationObserver v-slot="{ passes }">
                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                            <form id="category_requirement" @submit.prevent="passes(addComplianceField)">
                                                <li> 
                                                    <ValidationProvider name="field_label" rules="required" v-slot="{ errors}">  
                                                        <div class="form-group" :class="{'errors': errors.length && !businessCategory.field_label || errors[0],'success':businessCategory.field_label && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="businessCategory.field_label" class="form-control" name="field_label" id="field_label" placeholder="Field Label*" @input="checkNameFormat($event, 'field_label')">
                                                                <label for="field_label" class="control-label">Field Label*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.field_label || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="businessCategory.field_label && !errors[0]"></i>
                                                            </span>
                                                            <span class="text-danger error-msg" v-if="fieldName == 'field_label'">{{fieldErrorMsg}}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <!-- <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0066-003'">{{apiErrorMsg}}</span>  -->
                                                </li>
                                                <li> 
                                                    <ValidationProvider name="field_name" rules="required" v-slot="{ errors}">  
                                                        <div class="form-group" :class="{'errors': errors.length && !businessCategory.field_name || errors[0],'success':businessCategory.field_name && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="businessCategory.field_name" class="form-control" name="field_name" id="field_name" placeholder="Field Name*" @input="checkNameFormatForFieldName($event, 'field_name')">
                                                                <label for="field_name " class="control-label">Field Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.field_name || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="businessCategory.field_name && !errors[0]"></i>
                                                            </span>
                                                            <span class="text-danger error-msg" v-if="fieldName == 'field_name'">{{fieldErrorMsg}}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0066-003'">{{apiErrorMsg}}</span> 
                                                </li>
                                                <li>
                                                    <ValidationProvider name="form_control" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !businessCategory.type,'success':businessCategory.type}">
                                                            <span class="float-label">
                                                                <label for="functions">Field Type*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="businessCategory.formControlOptions"  
                                                                    :show-labels="false"
                                                                    label="name"
                                                                    :options="formControlOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.type"></i>
                                                                <i class="fa fa-check" v-if="businessCategory.type"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="business_type" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !businessCategory.type,'success':businessCategory.type}">
                                                            <span class="float-label">
                                                                <label for="functions">Value Type*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="businessCategory.type"  
                                                                    :show-labels="false"
                                                                    label="name"
                                                                    :options="typeOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.type"></i>
                                                                <i class="fa fa-check" v-if="businessCategory.type"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <div class="d-flex align-items-center m-b-20">
                                                        <p class="mb-0 mr-3">Type 1</p>
                                                        <div class="custom-switch success-error-bg">
                                                            <input type="checkbox" class="custom-switch-input" id="type_one" v-model="businessCategory.typeOne">
                                                            <label class="custom-switch-label" for="type_one">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex align-items-center m-b-20">
                                                        <p class="mb-0 mr-3">Type 2</p>
                                                        <div class="custom-switch success-error-bg">
                                                            <input type="checkbox" class="custom-switch-input" id="type_two" v-model="businessCategory.typeTwo">
                                                            <label class="custom-switch-label" for="type_two">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex align-items-center m-b-20">
                                                        <p class="mb-0 mr-3">Type 3</p>
                                                        <div class="custom-switch success-error-bg">
                                                            <input type="checkbox" class="custom-switch-input" id="type_three" v-model="businessCategory.typeThree">
                                                            <label class="custom-switch-label" for="type_three">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex align-items-center m-b-20">
                                                        <p class="mb-0 mr-3">Type 4</p>
                                                        <div class="custom-switch success-error-bg">
                                                            <input type="checkbox" class="custom-switch-input" id="type_four" v-model="businessCategory.typeFour">
                                                            <label class="custom-switch-label" for="type_four">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
<li>
                                                    <div class="d-flex align-items-center m-b-20">
                                                        <p class="mb-0 mr-3">Is Required?</p>
                                                        <div class="custom-switch success-error-bg">
                                                            <input type="checkbox" class="custom-switch-input" id="isRequired" v-model="businessCategory.isRequired">
                                                            <label class="custom-switch-label" for="isRequired">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>                                                
                                                <li>
                                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Field</b-button>
                                                        <b-button @click="$bvModal.hide('categoryreq_'+ row.index)" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                    </div>
                                                </li>
                                            </form>
                                        </ul>
                                    </ValidationObserver>
                                </div>
                            </b-modal>
                        </div>
                        <!-- All Sub Category Table -->
                        <div v-if="!categoryitem.subItems[row.index].isSubList && !categoryitem.subItems[row.index].isSubTab">
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.index].subIteamDetais" 
                                :fields="subFields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection">
                                <template #head()="{label,  field: { key }}">
                                    {{ label }} 
                                    <!-- Custom icons -->
                                    <template>
                                        <i v-if="sortBy !== key" class=""></i>
                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </template>  
                                </template>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeOne_'+row.index+data.index"  v-model="data.value.ison" @click="updateStatus(data, 'type1', !data.value.ison)">
                                            <label class="custom-switch-label" :for="'typeOne_'+row.index+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeTwo_'+row.index+data.index"  v-model="data.value.ison" @click="updateStatus(data, 'type2', !data.value.ison)">
                                            <label class="custom-switch-label" :for="'typeTwo_'+row.index+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeThree_'+row.index+data.index"  v-model="data.value.ison" @click="updateStatus(data, 'type3', !data.value.ison)">
                                            <label class="custom-switch-label" :for="'typeThree_'+row.index+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeFour_'+row.index+data.index"  v-model="data.value.ison" @click="updateStatus(data, 'type4', !data.value.ison)">
                                            <label class="custom-switch-label" :for="'typeFour_'+row.index+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="row">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item @click="deleteField(row.item.fid)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </div>
                        
                    </b-card>
                </template>
            </b-table>
        </div>
        <div>
            <main-loader ref="loader"></main-loader>
        </div>
    </fragment>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default

import { Fragment } from 'vue-fragment'
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default

import shared from "@/shared.js";


export default {
    name:'ComRequirementsComponent',
    components: {
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        Fragment,
        'main-loader':LoaderComponent
    },data(){
        return{
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            direSortBy:'',
            direSortDesc:false,
            sharIndividualSortBy:'',
            sharIndividualSortDesc:false,
            sharCorporateSortBy:'',
            sharCorporateSortDesc:false,
            uboSortBy:'',
            uboSortDesc:false,
            accountSortBy:'',
            accountSortDesc:false,
            financeSortBy:'',
            financeSortDesc:false,
            customerSortBy:'',
            customerSortDesc:false,
            technicalSortBy:'',
            technicalSortDesc:false,
            currentModalId:0,
            selectedComplianceId: '',
            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            apiErrorCode: '',
            breadCrumbIteams:[
                {
                    title:'Category List',
                    links:'/setting/compliance-list'
                },
                {
                    title:'Registered Sole Proprietorship',
                    links:''
                }
            ],
           //categoryItems: [],
            categoryItems:[
                {
                    items:[],
                    subItems:[],
                }
            ],


            items: [], // Array of all compliance items
            subItems:[],
            subIteamDetais: [],
            ownerType: '',

            //All Category list table header fields
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: '',thClass:'text-center', tdClass:'text-center'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All sub Category list table header fields
            subFields: [
                { key: 'fid', label: 'Field Id',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'type1', label: 'Type 1',thClass:'w-130-px'},
                { key: 'type2', label: 'Type 2',thClass:'w-130-px'},
                { key: 'type3', label: 'Type 3',thClass:'w-130-px'},
                { key: 'type4', label: 'Type 4',thClass:'w-130-px'},
                { key: 'action', label: 'Action',thClass:'w-130-px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            businessCategory:{
                field_label:'',
                field_name:'',
                typeOne:true,
                typeTwo:true,
                typeThree:true,
                typeFour:true,
                isRequired:true
            },
            formControlOptions:[
                    { id: '1', name: 'Textbox'},
                    { id: '2', name: 'Radio'},
                    { id: '3', name: 'Checkbox'},
                    { id: '3', name: 'Number'},
                    { id: '4', name: 'Email'},
                    { id: '5', name: 'password'}
                ],
            typeOptions: [
                    { id: '1', name: 'TextOnly'},
                    { id: '2', name: 'Alphanumeric'},
            ]
        }
    },methods:{
        resetForm(){
                this.businessCategory.field_name = '';
                this.businessCategory.field_label = '';
                this.businessCategory.type = '';
                this.businessCategory.formControlOptions = '';
                this.fieldErrorMsg = '';
                this.apiErrorMsg = '';
                this.apiErrorCode = '';
                this.selectedComplianceId = '';
        },
        checkNameFormat(e, fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            var splitStr = e.target.value.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                if(splitStr[0]!='')
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            if(splitStr[0]=='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
            }

            this.businessCategory[fieldName] = splitStr.join(' ');
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldName;
                    this.fieldErrorMsg = 'Please provide minimum two characters without spaces at start.';
                }
            } else {
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide valid value for field label';
                e.preventDefault(); // If not match, don't add to input text
            }

        },
        checkNameFormatForFieldName(e, fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            var splitStr = e.target.value.split(' ');
            // for (var i = 0; i < splitStr.length; i++) {
            //     if(splitStr[0]!='')
            //     splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            // }
            if(splitStr[0]=='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
            }

            this.businessCategory[fieldName] = splitStr.join(' ');
            if(/^(?!.*(?:__))[a-z_]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldName;
                    this.fieldErrorMsg = 'Please provide minimum two characters without spaces at start.';
                }
            } else {
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide valid value for field name';
                e.preventDefault(); // If not match, don't add to input text
            }

        },
        getCurrentModalId(activeModalId, complianceId){
            this.currentModalId = activeModalId
            this.selectedComplianceId = complianceId;
        },
        updateStatus(row, key, dataObj) {
            this.$refs.loader.show();
            let dataJson = {
                'type1' : key==='type1'? dataObj: row.item.type1.ison,
                'type2' : key==='type2'? dataObj: row.item.type2.ison,
                'type3' : key==='type3'? dataObj: row.item.type3.ison,
                'type4' : key==='type4'? dataObj: row.item.type4.ison
            }
             this.$http.put(this.$baseurl+"business/compliances/"+row.item.fid, dataJson,{
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                if(res.data.code == '0066-000') {
                    //this.$swal('Success!', res.data.message, 'success', 'Close');
                    shared.toastrSuccess(res.data.message);
                    this.$refs.loader.hide();
                    this.getRequirementData();
                    
                }
            }).catch((error) => {
                //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });

        },
        addComplianceField(){
            let categoryData = {
                    type1: this.businessCategory.typeOne,
                    type2: this.businessCategory.typeTwo,
                    type3: this.businessCategory.typeThree,
                    type4: this.businessCategory.typeFour,
                    complianceid: this.selectedComplianceId,
                    fieldname: this.businessCategory.field_name,
                    fieldlabel: this.businessCategory.field_label,
                    fieldtype: this.businessCategory.formControlOptions.name,
                    valuetype: this.businessCategory.type.name,
                    isrequired: this.businessCategory.isRequired,
                    ownertype: null
                };
            this.$http.post(this.$baseurl+"business/compliances/field", categoryData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                    },
                }).then((res) => {
                    if(res.data.code == '0066-000') {
                        shared.toastrSuccess(res.data.message);
                        this.getRequirementData();
                        this.$bvModal.hide('categoryreq_'+this.currentModalId);
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                });            
                //this.$bvModal.hide('categoryreq_'+this.currentModalId);
        },
        deleteField(fieldId) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    this.$http.delete(this.$baseurl+"business/compliances/"+fieldId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        if(res.data.code == '0066-000') {
                            shared.toastrSuccess(res.data.message);
                            this.getRequirementData();
                            this.$refs.loader.hide();
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        if(this.apiErrorCode == "E0066-002") {
                            this.$refs.loader.hide();
                            shared.toastrError(error.response.data.message);
                            
                        }/* else if(this.apiErrorCode == "E0004-003") {
                            this.$store.dispatch('contactDetail/addContact', loginData);
                            this.$router.push({ path: '/standapp-otp' })
                        }*/ else {
                            this.apiErrorMessage = error.response.data.message;
                        }
                    });



                }
            })
        },
        async getRequirementData() {
            this.items = [];
          //  this.$refs.loader.show();
            const response = await this.$http.get(this.$baseurl+"business/compliances/all",{
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                },
            });
            response.data.details.forEach(element => {
                let complianceItem = {
                        id:element.complianceid, 
                        name: element.compliancename,
                        action: ''
                    }
                this.items.push(complianceItem);
            });
            this.categoryItems[0].items  = this.items;
            this.generateSubList(this.categoryItems[0].items);
        },
        async generateSubList(itemsArr) {
            this.categoryItems[0].subItems = [];
            this.subItems = [];
            for (let i = 0; i < itemsArr.length; i++) {
                const subItemData = await this.$http.get(this.$baseurl+"business/compliances/"+itemsArr[i].id, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                    },
                });

                    this.subIteamDetais = [];
                    subItemData.data.details.forEach(subItemElement => {
                             let subItemsData = {
                                fid:    subItemElement.fieldid,
                                name:   subItemElement.fieldname,
                                type1:  subItemElement.type1==false?{ison:false}:{ison:true},
                                type2:  subItemElement.type2==false?{ison:false}:{ison:true},
                                type3:  subItemElement.type3==false?{ison:false}:{ison:true},
                                type4:  subItemElement.type4==false?{ison:false}:{ison:true},
                                action: ''
                            };
                            this.subIteamDetais.push(subItemsData);
                    });
                    const subItemsJson = {
                        isSubList: false,
                        isSubTab: false,
                        subIteamDetais: this.subIteamDetais
                    };
                    this.subItems.push(subItemsJson); 
                    this.categoryItems[0].subItems = this.subItems;
            }
        }
    },
    mounted () {
        if(sessionStorage.getItem("jwtToken")){
            this.getRequirementData();
        } else {
           // this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>
