<template>
    <div class="right-content mx-auto my-auto">
        <div class="header pb-3 text-center">
            <p class="lead px-3">Link your account for multiple business</p>
        </div>
        <div class="body">
            <ValidationObserver v-slot="{ passes }">
                <form class="form-auth-small" @submit.prevent="passes(linkedBusiness)" id="login">
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !linkbusinessformdata.email || errors[0],'success':linkbusinessformdata.email && !errors[0], 'mb-0':apiErrorCode == 'E0004-001'}">
                            <span class="float-label">
                                <input type="email" v-model="linkbusinessformdata.email" class="form-control" name="email" id="signin-email" placeholder="Email*" :disabled="linkbusinessformdata.readonlyMode">
                                <label for="signin-email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !linkbusinessformdata.email || errors[0] "></i>
                                <i class="fa fa-check" v-if="linkbusinessformdata.email && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please enter a valid Email Address</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0004-001'">{{apiErrorMessage}}</span>
                    <ValidationProvider name="name" rules="required" v-slot="{ errors}">
                        <div class="form-group set-password" :class="{'errors': errors.length && !linkbusinessformdata.passwords,'success':linkbusinessformdata.passwords, 'mb-0':apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'}">
                            <span class="float-label">
                                <input :type="linkbusinessformdata.password_type" class="form-control" id="signin-password" v-model="linkbusinessformdata.passwords" placeholder="Password*">
                                <!-- <input type="password" v-model="linkbusinessformdata.passwords" class="form-control" id="signin-password" placeholder="Password*"> -->
                                <label for="signin-password" class="control-label">Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !linkbusinessformdata.passwords"></i>
                                <i class="fa fa-check" v-if="linkbusinessformdata.passwords"></i>
                                <i class="fa fa-eye" :class="linkbusinessformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'">{{apiErrorMessage}}</span>
                    <div class="col-12">
                        <div class="form-group clearfix">
                            <label class="fancy-checkbox new-fancy">
                                <input type="checkbox" @click="checkIsChecked($event)">
                                <span>Check here to indicate that you have read and agree to the <strong>
                                    <!-- Terms and Conditions -->
                                    <router-link to="/terms-and-conditions" target="_blank">Terms and Conditions</router-link>
                                    </strong>
                                </span>
                            </label>
                            <span class="text-danger">{{  linkbusinessformdata.errorMsg }}</span>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-lg btn-block">Link Business</button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
import shared from "@/shared.js";

import { mapState } from 'vuex'
export default {
    name:'StandappLinkBusinessComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        linkedBusiness: function() {
            // if(this.linkbusinessformdata.IsChecked){
            //     this.$router.push({ path: '/standapp-login' })
            // }else{
            //     return this.linkbusinessformdata.errorMsg = 'Please select checkbox to continue.'
            // }
            if(!this.linkbusinessformdata.IsChecked){
                return this.linkbusinessformdata.errorMsg = 'Please select checkbox to continue.'
            }else{
                let loginData = {
                    email: this.linkbusinessformdata.email,
                    password: this.linkbusinessformdata.passwords
                }
                this.$http.post(this.$baseurl+'business/auth/login', loginData)
                .then((res) => {
                    //Perform Success Action
                    // console.log(res);
                    if(res.data.code == '0004-000') {
                        sessionStorage.setItem("jwtToken", res.data.token);
                        const linkBusinessData = {
                            business: this.businessRegistrationDetail.businessName,
                            country:  this.businessRegistrationDetail.country,
                            categoryid: this.businessRegistrationDetail.categoryid
                        }
                        this.$http.post(this.$baseurl+'business/register/linkbusiness', linkBusinessData, {
                            headers: {
                                'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                            },
                        }).then((res) => {
                            if(res.data.code === '0002-000') {
                                shared.toastrSuccess(res.data.message);
                                
                                this.$store.dispatch('registration/clearBusiness');
                                
                                this.$router.push({path:'/account'});
                            }
                        }).catch((error) => {
                            this.apiErrorCode = error.response.data.code;
                            if(this.apiErrorCode == "E0002-010") {
                                shared.toastrError(error.response.data.message);
                                this.$router.push({path:'/standapp-register'});
                            }
                        })

                        // this.$swal('Success!', res.data.message, 'success', 'OK');
                      //  this.$router.push({ path: '/account' })
                    } else {
                        //this.$swal('Error!', res.data.message, 'error', 'Close');
                        shared.toastrError(res.data.message);
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    // console.log('login',error.response.data.code);
                    this.apiErrorCode = error.response.data.code;
                    // if(this.apiErrorCode == "V0004-001") {
                    //     this.apiErrorMessage = error.response.data.detail;
                    // } else if(this.apiErrorCode == "E0004-003") {
                    //     // let activationMessage = error.response.data.message;
                    //     // this.$swal('Error!', activationMessage, 'error', 'Close');
                    //     this.$store.dispatch('contactDetail/addContact', loginData);
                    //     this.$router.push({ path: '/standapp-otp' })
                    // } else {
                        this.apiErrorMessage = error.response.data.message;
                    //}
                });
              
            }
        },

        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },

        showPassword() {
            this.linkbusinessformdata.password_type = this.linkbusinessformdata.password_type === 'password' ? 'text' : 'password';
        },
        //check Arrow Certification checkbox cheked
        checkIsChecked(event){
            this.linkbusinessformdata.IsChecked = event.target.checked
            if(this.linkbusinessformdata.IsChecked){
                return this.linkbusinessformdata.errorMsg = ''
            }
        }
    },
    data(){
        return{
            banner_img: require(`@/assets/home_image/tick_done.svg`),
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'The one Account you need for',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            linkbusinessformdata: {
                readonlyMode : true,
                email: '',//'user@gmail.com',
                passwords:"",
                password_type: 'password',
                IsChecked: false,
                errorMsg:''
            },
            apiErrorCode : '',
            apiErrorMessage : ''
        }
    },
    mounted () {
        this.onLoad();
    }, computed: mapState({
        businessRegistrationDetail: state => state.registration.businessDetail,
        // personalDetail: state => state.personalInfo  
    }),created() {
        this.linkbusinessformdata.email = this.businessRegistrationDetail.email;
        // console.log(this.businessRegistrationDetail.email);
    }
}
</script>
<style scoped>

</style>