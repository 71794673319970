<template>
    <div class="add-business">

        <!-- Add Fund -->
        <b-modal id="add_fund" title="Add Fund" hide-footer no-stacking no-close-on-backdrop @shown="modalShow()">
            <!--  @shown="resetForm" -->
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(addFundForm)" id="single-form">
                        <li class="mb-3">
                            <strong> Business Name : <span>{{selectedBusinessName}}</span></strong>
                        </li>
                        <li>
                            <ValidationProvider name="associatedWallet" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addFundDatas.associatedWallet && addFundDatas.associatedWallet.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Associated wallet*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addFundDatas.associatedWallet"  
                                            :show-labels="false" 
                                            :options="walletOptions" 
                                            label="name"
                                            :searchable="true"
                                            @select="setCurrency">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.associatedWallet && addFundDatas.associatedWallet.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li class="mb-3">
                            <strong> Selected Currency : <span v-if="addFundDatas.associatedWallet">{{selectedCurrency}}</span></strong>
                        </li>
                        <li>
                            <ValidationProvider name="paymentOption" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addFundDatas.paymentOption && addFundDatas.paymentOption.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Payment Option*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addFundDatas.paymentOption"  
                                            :show-labels="false" 
                                            :options="paymentOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.paymentOption && addFundDatas.paymentOption.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="paymentReference" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !addFundDatas.paymentReference || errorArr[1] && errorArr[1].fieldName!='','success':addFundDatas.paymentReference && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="addFundDatas.paymentReference" class="form-control" id="paymentReference" placeholder="Payment Reference*">
                                        <label for="paymentReference" class="control-label">Payment Reference*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !addFundDatas.paymentReference || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.paymentReference && errorArr[1] && errorArr[1].fieldName==''"></i>
                                    </span>
                                    <!-- <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span> -->
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="addAmount" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !addFundDatas.addAmount || errorArr[1] && errorArr[1].fieldName!='','success':addFundDatas.addAmount && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="addFundDatas.addAmount" class="form-control" id="addAmount" placeholder="Add Fund Amount*">
                                        <label for="addAmount" class="control-label">Add Fund Amount*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !addFundDatas.addAmountx"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.addAmount"></i>
                                    </span>
                                    <span class="text-danger error-msg" v-if="apiErrorCode=='V0100-034'">{{apiErrorMsg}}</span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <div class="form-group">
                                <ValidationProvider name="fund_proof_file" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !addFundDatas.fundProofFile,'success':addFundDatas.fundProofFile}">
                                        <div class="custom-file">
                                            <b-form-file
                                                v-model="addFundDatas.fundProofFile"
                                                placeholder="Select Pdf for upload Proof of funds"
                                                drop-placeholder="Drop file here..."
                                                accept=".pdf"
                                                ref="file"
                                                @change="uploadFile"
                                            ></b-form-file>
                                            <!-- @change="uploadDocument" -->
                                            <i class="fa fa-times" v-if="errors.length && !addFundDatas.fundProofFile"></i>
                                            <i class="fa fa-check" v-if="addFundDatas.fundProofFile"></i>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Fund</b-button>
                            <b-button @click="$bvModal.hide('add_fund')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>

        <!-- Withdraw Fund -->
        <b-modal id="withdraw_fund" title="Withdraw Fund" hide-footer no-stacking no-close-on-backdrop>
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(singleBusinessForm)">
                        <!-- <li>
                            <ValidationProvider name="businessName" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addFundDatas.businessName && withdrawalFundDatas.businessName.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Business*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="withdrawalFundDatas.businessName"  
                                            :show-labels="false" 
                                            :options="businessNameOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="withdrawalFundDatas.businessName && withdrawalFundDatas.businessName.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li> -->
                        <li class="mb-3">
                            <strong> Business Name : <span>Businessitem</span></strong>
                        </li>
                        <li>
                            <ValidationProvider name="associatedWallet" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':withdrawalFundDatas.associatedWallet && withdrawalFundDatas.associatedWallet.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Associated wallet*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="withdrawalFundDatas.associatedWallet"  
                                            :show-labels="false" 
                                            :options="walletOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="withdrawalFundDatas.associatedWallet && withdrawalFundDatas.associatedWallet.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li class="mb-3">
                            <strong> Selected Currency : GB</strong>
                        </li>
                        <li>
                            <div class="form-group">
                                <span class="float-label">
                                    <input type="text" v-model="withdrawalFundDatas.availableBalance" class="form-control" id="availableBalance" placeholder="Available Balance*" disabled>
                                    <label for="owner" class="control-label">Available balance of wallet</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <ValidationProvider name="withdrawlAmount" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !withdrawalFundDatas.withdrawlAmount || errorArr[1] && errorArr[1].fieldName!='','success':withdrawalFundDatas.withdrawlAmount && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="withdrawalFundDatas.withdrawlAmount" class="form-control" id="withdrawlAmount" placeholder="Withdrawal Amount*">
                                        <!--  @input="checkNameFormat($event, 'withdrawlAmount', 'Owner First Name', 'single', 1)" -->
                                        <label for="owner" class="control-label">Withdraw Amount</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !withdrawalFundDatas.withdrawlAmount || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                        <i class="fa fa-check" v-if="withdrawalFundDatas.withdrawlAmount && errorArr[1] && errorArr[1].fieldName==''"></i>
                                    </span>
                                    <!-- <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span> -->
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="associatedWallet" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':withdrawalFundDatas.associatedWallet && withdrawalFundDatas.associatedWallet.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Account*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="withdrawalFundDatas.account"  
                                            :show-labels="false" 
                                            :options="accountOptions" 
                                            label="name">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="withdrawalFundDatas.account && withdrawalFundDatas.account.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <!-- <li>
                            <div class="form-group">
                                <span class="float-label">
                                    <input type="text" v-model="withdrawalFundDatas.accountNumber" class="form-control" id="accountNumber" placeholder="Account Number*" disabled>
                                    <label for="accountNumber" class="control-label">Account Number</label>
                                </span>
                            </div>
                        </li> -->
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Withdraw Fund</b-button>
                            <b-button @click="$bvModal.hide('withdraw_fund')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
import shared from "@/shared.js";
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default

export default {
    name: 'AddWithdrawFundModal',
    props: {
        buttonClass: String
    },components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'main-loader':LoaderComponent
    },data(){
        return{
            addFundDatas:{
                // businessName:'',
                associatedWallet:null,
                // currency:'GB',
                paymentOption:null,
                paymentReference:'',
                addAmount:'',
                fundProofFile:null
            },
            images: null,

            withdrawalFundDatas:{
                businessName:'',
                associatedWallet:'',
                currency:'GB',
                availableBalance:'100',
                withdrawlAmount:'',
                account:null,
                // accountNumber:'20400952521459'
            },
            selectedBusinessType: '',
            mobileInputError:'',

            selectedBusinessName : '',
            selectedCurrency : '',
            base64File : '',
            
            businessNameOptions: [],
            walletOptions : [],
            accountOptions : [
                {'id':'1', 'name':'HSBC Holdings'},
                {'id':'2', 'name':'JPMorgan Chase & Co'},
                {'id':'3', 'name':'Airtel Ghana Limited'},
            ],
            paymentOptions: [
                // {'id':'1', 'name': 'Bank Deposit'},
                // {'id':'2', 'name': 'Debit Card'},
                // {'id':'3', 'name': 'Credit Card'}
            ],
            errorArr : [],

            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            elementName : '',
            apiErrorCode: '',
            formType: ''
        }
    },methods:{
        resetForm(){
            // clear single business form fields
            Object.keys(this.singleBusinessDatas).forEach(v => this.singleBusinessDatas[v] = '')
        },
        checkNameFormat(e, fieldName, elementName, form, elementIndex) {
            if(form==='single') {
                this.formType = this.singleBusinessDatas;
            }
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                var splitStr = e.target.value.split(' ');
                for (var i = 0; i < splitStr.length; i++) {
                    if(splitStr[0]!='')
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
            this.formType[fieldName] = splitStr.join(' ');
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = '';
                        this.errorArr[elementIndex].errorMessage = '';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                    return true; // Match with regex 

                } else {
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = fieldName;
                        this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   



                }
            } else {
                this.formType[fieldName] = char;
                this.fieldErrorMsg = 'Please provide valid value for '+elementName;
                if(!this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr.push({'fieldName': fieldName, errorMessage:this.fieldErrorMsg, 'index': elementIndex})
                } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr[elementIndex].fieldName =  fieldName;
                    this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
                    this.errorArr[elementIndex].index = elementIndex;
                } else {
                    // do nothing 
                }
               
                e.preventDefault(); // If not match, don't add to input text
            }
            console.log(this.errorArr);
        },

        uploadDocument(e){
            let file = e.target.files[0];
            let fileType = file.type;
            if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg' || fileType == 'image/JPEG' || fileType == 'application/pdf'){
                // Start For convert and set base64
                const reader = new FileReader()
                let rawImg;
                reader.onloadend = () => {
                    rawImg = reader.result;
                    this.base64File = rawImg;
                    // console.log(this.base64File, fileType);

                }
                reader.readAsDataURL(file);
                // End For convert and set base64
            } else {
                this.$swal('Error!', 'Please provide image file', 'error', 'Close');
            }
        },

        uploadFile(e) {
            let file = e.target.files[0];
            let fileType = file.type;
            if(fileType == 'application/pdf' || fileType == 'application/PDF'){
                this.Images = file;//e.target.files[0];
            } else {
                this.$swal('Error!', 'Please provide pdf file', 'error', 'Close');
            }
        },

        //add fund submit form data
        addFundForm(){
            this.$refs.loader.show();
            const formData = new FormData();
            formData.append('businessid', sessionStorage.getItem("businessId"));
            formData.append('walletid', this.addFundDatas.associatedWallet.id);
            formData.append('amount', parseInt(this.addFundDatas.addAmount));
            formData.append('paymentreference', this.addFundDatas.paymentReference);
            formData.append('paymentoptionid', this.addFundDatas.paymentOption.id);
            formData.append('file', this.addFundDatas.fundProofFile ? this.Images : '');


            // let addFund = {
            //     businessid : sessionStorage.getItem("businessId"),
            //     walletid : this.addFundDatas.associatedWallet.id,
            //     amount : parseInt(this.addFundDatas.addAmount),
            //     paymentreference : this.addFundDatas.paymentReference,
            //     paymentoptionid : this.addFundDatas.paymentOption.id,
            //     file : this.addFundDatas.fundProofFile ? this.addFundDatas.fundProofFile.name : ''
            // }
            // console.log(this.addFundDatas.fundProofFile.type); this.base64File
            // console.log(formData);
            this.$http.post(this.$baseurl+'wallet/fund/business', formData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken"),
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0100-000') {
                    this.$bvModal.hide('add_fund');
                    this.$refs.loader.hide();
                    this.$root.$refs.FundComponent.refreshFundTable();
                    shared.toastrSuccess('Your fund successfully added');
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(res.message);
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.detail;
                // shared.toastrError(error.response.data.message);
            });

        },

        setCurrency(defaultCurrency){
            // console.log(defaultCurrency);
            this.selectedCurrency = defaultCurrency.currency;
        },

        modalShow(){
            this.businessData();
            this.getWalletData();
            this.paymentOption();

            this.addFundDatas.associatedWallet = null;
            this.addFundDatas.paymentOption = null;
            this.addFundDatas.paymentReference = '';
            this.addFundDatas.addAmount = '';
            this.addFundDatas.fundProofFile = null;
            this.selectedCurrency = '';
        },

        async businessData(){
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '20',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    if(item.businessid == sessionStorage.getItem("businessId")){
                        this.selectedBusinessName = item.name
                    }
                    // let businessLists = {
                    //     id : item.businessid,
                    //     name: item.name
                    // };
                    // this.businessNameOptions.push(businessLists);
                });                
            } catch (error) {
                console.log(error);
            }
        },

        async getWalletData(){
            try {
                const response = await this.$http.get(this.$baseurl+"wallet/business/"+sessionStorage.getItem("businessId"), { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                this.walletOptions = [];
                if(response.data.code == '0100-000'){
                    response.data.details.map((item) => {
                        let walletLists = {
                            id : item.walletid,
                            name : item.name,
                            currency : item.currencycode
                        };
                        this.walletOptions.push(walletLists);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        },

        async paymentOption(){
            try {
                const response = await this.$http.get(this.$baseurl+"payment/options", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                this.paymentOptions = [];
                if(response.data.code == '0100-100'){
                    response.data.details.map((item) => {
                        let lists = {
                            id : item.id,
                            name : item.name
                        };
                        this.paymentOptions.push(lists);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        }

    },mounted () {
        // this.$root.$refs.AddBusinessModel = this;
    }
}
</script>
