<template>
    <div class="container-fluid my-profile">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        
        <div class="row clearfix row-deck">
            <!-- Account Holder Information -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <account-holder-information></account-holder-information>
            </div>

            <!-- Basic Business Information -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <basic-business-information @onBusinessTypeChange="businessTypeChange"></basic-business-information>
            </div>

            <!-- Business Contact Details -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <business-contact-detail :businessType="businessType"></business-contact-detail>
            </div>

            <!-- Tell us about your needs -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <tell-us-about-your-needs></tell-us-about-your-needs>
            </div>

            <!-- Controllers of your Business -->
                <!-- Directors -->
                <div class="col-lg-6 col-md-12 sol-sm-12" v-if="businessType == 'Business'">
                    <directors-component></directors-component>
                </div>
                <!-- Shareholders -->
                <div class="col-lg-6 col-md-12 sol-sm-12" v-if="businessType == 'Business'">
                    <shareholders-component></shareholders-component>
                </div>
                <!-- Ultimate Beneficial Owner (UBO) -->
                <div class="col-lg-6 col-md-12 sol-sm-12" v-if="businessType == 'Business'">
                    <ubo-component></ubo-component>
                </div>

            <!-- Business Structure, Condition and Size -->
            <div class="col-lg-6 col-md-12 sol-sm-12" v-if="businessType == 'Business'">
                <business-structure-condition-size></business-structure-condition-size>
            </div>

            <!-- Your Business and Regulations -->
            <div class="col-lg-6 col-md-12 sol-sm-12" v-if="businessType == 'Business'">
                <business-regulations-component></business-regulations-component>
            </div>

            <!-- Compliance Declarations -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <compliance-declarations-component :businessType="businessType"></compliance-declarations-component>
            </div>

            <!-- Supporting Documents -->
            <div class="col-lg-12 col-md-12 sol-sm-12">
                <supporting-documents-component :businessType.sync="businessType"></supporting-documents-component>
            </div>

            <!-- Finally, in case we need to reach you who should we contact? -->
            <div class="col-lg-12 col-md-12 sol-sm-12">
                <contact-component></contact-component>
            </div>

            <!-- Business Logo -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <business-logo-component></business-logo-component>
            </div>

            <!-- Logo Icon -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <logo-icon-component></logo-icon-component>
            </div>

            <!-- Profile Photo -->
            <div class="col-lg-6 col-md-12 sol-sm-12">
                <profile-photo-component></profile-photo-component>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const AccountHolderInfo = require('@/components/core/AccountHolderInfo-Profile.vue').default
const BasicBusinessInfo = require('@/components/core/BasicBusinessInfo-Profile.vue').default
const BusinessContactDetails = require('@/components/core/BusinessContactDetails-Profile.vue').default
const YourNeeds = require('@/components/core/YourNeeds-Profile.vue').default
const Director = require('@/components/core/Director-Profile.vue').default
const Shareholders = require('@/components/core/Shareholders-Profile.vue').default
const UBOComponent = require('@/components/core/UBO-Profile.vue').default
const BusinessStructure = require('@/components/core/BusinessStructure-Profile.vue').default
const ComplianceDeclarations = require('@/components/core/ComplianceDeclarations-Profile.vue').default
const BusinessLogo = require('@/components/core/BusinessLogo-Profile.vue').default
const LogoIcon = require('@/components/core/LogoIcon-Profile.vue').default
const ProfilePhoto = require('@/components/core/ProfilePhoto-Profile.vue').default
const SupportingDocument = require('@/components/core/SupportingDocument-Profile.vue').default
const Contact = require('@/components/core/Contact-Profile.vue').default
const BusinessRegulations = require('@/components/core/BusinessRegulations-Profile.vue').default

export default {
    name:'MyProfileComponent',
    components: {
        BreadCrumb,
        'account-holder-information':AccountHolderInfo,
        'basic-business-information':BasicBusinessInfo,
        'business-contact-detail':BusinessContactDetails,
        'tell-us-about-your-needs':YourNeeds,
        'directors-component':Director,
        'shareholders-component':Shareholders,
        'ubo-component': UBOComponent,
        'business-structure-condition-size': BusinessStructure,
        'compliance-declarations-component': ComplianceDeclarations,
        'business-logo-component': BusinessLogo,
        'logo-icon-component': LogoIcon,
        'profile-photo-component': ProfilePhoto,
        'supporting-documents-component': SupportingDocument,
        'contact-component': Contact,
        'business-regulations-component': BusinessRegulations
    },data (){
        return{
           businessType: 'Business'
        }
    },methods:{
        businessTypeChange (value) {
            this.businessType = value;
        }
    }
}
</script>