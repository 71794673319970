import { render, staticRenderFns } from "./BusinessStructure-Profile.vue?vue&type=template&id=b9c055fe&"
import script from "./BusinessStructure-Profile.vue?vue&type=script&lang=js&"
export * from "./BusinessStructure-Profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports