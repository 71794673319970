<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :showCountry="true"></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Recent transactions</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <div class="mr-0 mr-lg-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search transactions" aria-label="Search transactions" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="fliter-main mr-auto mt-3 mt-lg-0">
                                <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <div class="date-range-main mt-3 mt-lg-0">
                                <template>
                                    <v-md-date-range-picker
                                        :opens="opens"
                                        :presets="presets"
                                        :autoApply="autoApplyDateRange"
                                    ></v-md-date-range-picker>
                                </template>
                            </div>
                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <b-dropdown id="payment" right size="lg" variant="primary btn-mw" class="mb-0">
                                    <template #button-content>
                                        Create Payment <i class="fa fa-plus-square"></i>
                                    </template>
                                    <b-dropdown-item href="">Single Payment</b-dropdown-item>
                                    <b-dropdown-item href="">Bulk Upload</b-dropdown-item>
                                </b-dropdown>
                                <b-dropdown class="mr-lg-3 mr-0 mt-3 mt-lg-0 mb-0" id="download" text="Download" size="lg" variant="outline-primary btn-mw">
                                    <template #button-content>
                                        Download <i class="fa fa-download"></i>
                                    </template>
                                    <b-dropdown-item href="">Export as PDF</b-dropdown-item>
                                    <b-dropdown-item href="">Export as CSV</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <b-table 
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(dropdawn)="data">
                                <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="onChange($event,(currentPage-1) * perPage + data.index + 1)">
                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const TransactionsTopRow = require('@/components/core/TransactionsTopRow.vue').default
const moment = require('moment')
const { VMdDateRangePicker } = require("v-md-date-range-picker")

export default {
    name:'TransactionsComponent',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow,
        'v-md-date-range-picker':VMdDateRangePicker,
    },data(){
        return{
            isVisibleFilter: false,
            transactions_toprows:[
                {
                    icon:'fa-dollar',
                    text:'Amount',
                    price:'$22,500',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-bar-chart-o',
                    text:'Count',
                    price:'$500',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-area-chart',
                    text:'Avg Anount',
                    price:'215',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-users',
                    text:'No. of Customers',
                    price:'21,215',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last week'
                }
            ],

            autoApplyDateRange: false,
            opens: 'right',
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],

            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as PDF",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as CSV",
                    url: "javascript:void(0);"
                },
            ],

            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],

            items:[
                {date: '21-July-2018 06:51:51',type:'Buy',amount:'0.58647',amount2:'0.58647',price:'11900.12',usd:'$ 1597.78',fees:'0.023',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '22-July-2018 06:50:50',type:'Sell',amount:'1.38647',amount2:'0.38647',price:'11905.09',usd:'$ 2496.36',fees:'0.017',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '23-July-2018 06:49:51',type:'Buy',amount:'0.45879',amount2:'0.45879',price:'11901.85',usd:'$ 3165.44',fees:'0.013',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '24-July-2018 06:51:51',type:'Buy',amount:'0.89877',amount2:'0.89877',price:'11899.28',usd:'$ 25830.6',fees:'0.011',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '25-July-2018 06:51:51',type:'Sell',amount:'0.45712',amount2:'0.45712',price:'11908.19',usd:'$ 2586.34',fees:'0.024',dropdawn:{selected:'cancel',class:'btn-outline-danger'}},
                {date: '26-July-2018 06:51:51',type:'Buy',amount:'0.58647',amount2:'0.58647',price:'11900.12',usd:'$ 1597.78',fees:'0.023',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '27-July-2018 06:51:51',type:'Buy',amount:'0.58647',amount2:'0.58647',price:'11900.12',usd:'$ 1597.78',fees:'0.023',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '28-July-2018 06:50:50',type:'Sell',amount:'1.38647',amount2:'0.38647',price:'11905.09',usd:'$ 2496.36',fees:'0.017',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '29-July-2018 06:49:51',type:'Buy',amount:'0.45879',amount2:'0.45879',price:'11901.85',usd:'$ 3165.44',fees:'0.013',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '30-July-2018 06:51:51',type:'Buy',amount:'0.89877',amount2:'0.89877',price:'11899.28',usd:'$ 25830.6',fees:'0.011',dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {date: '31-July-2018 06:51:51',type:'Sell',amount:'0.45712',amount2:'0.45712',price:'11908.19',usd:'$ 2586.34',fees:'0.024',dropdawn:{selected:'cancel',class:'btn-outline-danger'}},
                {date: '01-Aug-2018 06:51:51',type:'Buy',amount:'0.58647',amount2:'0.58647',price:'11900.12',usd:'$ 1597.78',fees:'0.023',dropdawn:{selected:'accept',class:'btn-outline-success'}},
            ],
           
            fields: [
                { key: 'date', label: 'Date',sortable: true,thClass:'th_sortfix'},
                { key: 'type', label: 'Type',sortable: true,thClass:'th_sortfix'},
                { key: 'amount', label: 'Amount',sortable: true,thClass:'th_sortfix'},
                { key: 'amount2', label: 'Remaining',sortable: true,thClass:'th_sortfix'},
                { key: 'price', label: 'Price',sortable: true,thClass:'th_sortfix'},
                { key: 'usd', label: 'USD',sortable: true,thClass:'th_sortfix'},
                { key: 'fees', label: 'Fee (%)',sortable: true,thClass:'th_sortfix'},
                { key: 'dropdawn', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },methods: {
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        }
    },mounted() {
        this.totalRows = this.items.length
    }
}
</script>