<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Payment Option</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown">
                            <b-button size="lg" variant="primary btn-mw" @click="createPaymentOption()">Create Payment Option <i class="fa fa-plus-square"></i></b-button>
                        </div>
                        <b-table 
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item >Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <!-- <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <add-payment-option></add-payment-option>
        <main-loader ref="loader"></main-loader>
    </div>
</template>

<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const AddPaymentOption = require('@/components/admin/payment_option/AddPaymentOptionModal.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";

export default {
    name:'PaymentOptionComponent',
    components: {
        BreadCrumb,
        'add-payment-option': AddPaymentOption,
        'main-loader':LoaderComponent
    },data(){
        return{
            singleBusinessDatas:{
                fundOption:''
            },

            items:[
                {name:'Bank Deposit',action:''},
                {name:'Debit Card',action:''},
                {name:'Credit Card',action:''}
            ],

            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },methods: {
        createPaymentOption(){
            this.$bvModal.show('add_payment_option');
        }
    }, mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.totalRows = this.items.length;
            // this.getFundData();
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>