<template>
    <div class="right-content mx-auto my-auto">
        <div class="header pb-3 text-center">
            <p class="lead">Login to your account</p>
        </div>
        <div class="body">
            <ValidationObserver v-slot="{ passes }">
                <form class="form-auth-small" @submit.prevent="passes(loginSubmit)" id="login">
                    <!-- Email -->
                    <ValidationProvider name="signin_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !loginformdata.signin_email || errors[0],'success':loginformdata.signin_email && !errors[0], 'mb-0':apiErrorCode == 'E0004-001'}">
                            <span class="float-label">
                                <input type="email" v-model="loginformdata.signin_email" class="form-control" name="email1" id="signin_email" placeholder="Email*">
                                <label for="signin_email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.signin_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="loginformdata.signin_email && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please provide valid username</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0004-001'">{{apiErrorMessage}}</span>
                    <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !loginformdata.passwords,'success':loginformdata.passwords, 'mb-0':apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'}">
                            <span class="float-label">
                                <input type="password" v-model="loginformdata.passwords" class="form-control" id="signin-password" placeholder="Password*">
                                <label for="signin-password" class="control-label">Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.passwords"></i>
                                <i class="fa fa-check" v-if="loginformdata.passwords"></i>
                            </span>
                        </div>
                    </ValidationProvider> 
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'">{{apiErrorMessage}}</span>                         
                    <!-- <div class="form-group clearfix"><label class="fancy-checkbox element-left"><input type="checkbox"><span>Remember me</span></label></div> -->
                    <button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
                    <div class="bottom"><span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/authentication/page-forgot-password">Forgot password?</router-link></span><span>Don't have an account? <router-link to="/authentication/page-register">Register</router-link></span></div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'LoginV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        loginSubmit: function() {
            let loginData = {
                email: this.loginformdata.signin_email,
                password: this.loginformdata.passwords
            }
            this.$http.post(this.$baseurl+"business/auth/login", loginData)
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                if(res.data.code == '0004-000') {
                    // this.$swal('Success!', res.data.message, 'success', 'OK');
                    sessionStorage.setItem("jwtToken", res.data.token);
                    this.$router.push({ path: '/account' })
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                // console.log('login',error.response.data.code);
                this.apiErrorCode = error.response.data.code;
                if(this.apiErrorCode == "V0004-001") {
                    this.apiErrorMessage = error.response.data.detail;
                } else if(this.apiErrorCode == "E0004-003") {
                    let activationMessage = error.response.data.message;// + ', Please provide your OTP and activate your Account.'
                    this.$swal('Error!', activationMessage, 'error', 'Close');
                    this.$store.dispatch('contactDetail/addContact', loginData);
                    this.$router.push({ path: '/authentication/page-otp' })
                } else {
                    this.apiErrorMessage = error.response.data.message;
                }
            });
        },
    },
    data(){
        return{
            loginformdata:{
                signin_email:'',
                passwords:''
            },
            apiErrorCode : '',
            apiErrorMessage : ''
        }
    },
    mounted () {
        
    }
}
</script>
<style scoped>

</style>
