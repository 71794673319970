<template>
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="card card-top-line no-shadow">
                        <div class="body">
                            <div class="mb-4">
                                <h6>Add Invoice Information</h6>
                            </div>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(invoiceDetailsForm)" id="invoice-info">
                                    <ul class="list-unstyled mb-0 common-form small-form add-invoice-information">
                                        <li>
                                            <div class="form-group" >
                                                <span class="float-label">
                                                    <b-input-group>
                                                        <input type="text" v-model="invoiceInfo.invoiceNumber" class="form-control" id="invoiceNumber" placeholder="Invoice Number" :disabled="invoiceInfo.invoiceNumberReadOnly" @keypress="onlyNumber($event)">
                                                        <b-input-group-append>
                                                            <b-button ref="btnToggle" class='input-group-text btn-edit-save' :variant="invoiceInfo.invoiceNumberReadOnly ? 'secondary' :'primary'" size="lg" @click="editInvoiceNumber()">Edit</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group> 
                                                    <label for="tax" class="control-label">Invoice Number</label>
                                                    <div class="line"></div>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !invoiceInfo.currency,'success':invoiceInfo.currency}">
                                                    <span class="float-label">
                                                        <label for="functions">Invoice Currency*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="invoiceInfo.currency"  
                                                            :show-labels="false" 
                                                            :options="currencyOptions" 
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name" >
                                                            <template slot="option" slot-scope="props">
                                                                <div class="option-title">
                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                    <span>{{ props.option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors.length && !invoiceInfo.currency"></i>
                                                        <i class="fa fa-check" v-if="invoiceInfo.currency"></i>
                                                    </span>
                                                </div>
                                            </ValidationProvider>
                                        </li>
                                        <li>
                                            <div class="form-group datepicker">
                                                <span class="float-label">
                                                    <b-input-group>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Invoice Date*</span>
                                                            <span class="input-group-text" @click="$refs.datepicker.showCalendar()"><i class="icon-calendar"></i></span>
                                                        </div>
                                                        <datepicker class="form-control"  ref="datepicker" :bootstrap-styling="false" placeholder="" v-model="invoiceInfo.invoiceDate" :typeable="true"></datepicker>
                                                    </b-input-group>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group datepicker">
                                                <span class="float-label">
                                                    <b-input-group>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Due Date*</span>
                                                            <span class="input-group-text" @click="$refs.dueDate.showCalendar()"><i class="icon-calendar"></i></span>
                                                        </div>
                                                        <datepicker class="form-control" ref="dueDate" :bootstrap-styling="false" placeholder="Due date*" v-model="invoiceInfo.dueDate" :disabled-dates="disabledDates"></datepicker>
                                                    </b-input-group>
                                                </span>
                                            </div>
                                        </li>
                                        <li v-for="(taxinfo,taxRowId) in invoiceInfo.taxRows" :key="taxRowId">
                                            <strong v-if="taxRowId == 0">Tax</strong>
                                            <b-input-group class="form-group">
                                                <!-- dropdawn -->
                                                <template #prepend>
                                                    <b-dropdown class="mb-0" :text="taxinfo.taxSelectedOptions" variant="outline-secondary input-group-text" menu-class="border-0 shadow">
                                                        <b-dropdown-item v-for="(taxOption,index) in taxOptions" :key="index"  @click="activeTaxOptions(taxRowId,index)" :active="index == taxinfo.taxActiveIndex">
                                                            {{ taxOption.name }}
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                                <!-- other tax name -->
                                                <b-form-input v-if="taxinfo.taxSelectedOptions == 'Other'" type="text" v-model="taxinfo.otherTaxName" :id="'otherTaxName_'+taxRowId" placeholder="Tax Name"></b-form-input>
                                                <span class="input-group-btn" style="width:0px;" v-if="taxinfo.taxSelectedOptions == 'Other'"></span>
                                                <!-- tax value input -->
                                                <b-form-input type="text" v-model="taxinfo.taxValue" :id="'taxName_'+taxRowId" placeholder="" @keypress="onlyNumber($event)" class="text-right"></b-form-input>
                                                <b-input-group-append>
                                                    <div class="input-group-text">%</div>
                                                    <div class="input-group-text border-danger text-danger bg-white" v-if="invoiceInfo.taxRows.length > 1" @click="removeTaxRow(taxRowId)"><i class="fa fa-remove"></i></div>
                                                </b-input-group-append>
                                                <!-- remove tax row button-->
                                            </b-input-group>
                                        </li>
                                        <li class="mt-2">
                                            <div class="text-right">
                                                <a href="javascript:void(0)" @click="addNewTaxRow">Add New</a>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>Discount</strong>
                                            <b-input-group>
                                                <input type="text" class="form-control text-right" name="discount" id="discount" @keypress="onlyNumber($event)">
                                                <b-input-group-append>
                                                    <b-dropdown class="mb-0" variant="outline-secondary input-group-text" menu-class="border-0 shadow" right>
                                                        <template #button-content>{{ invoiceInfo.discount }}</template>
                                                        <b-dropdown-item v-for="(discountInfo,index) in discountOptions" :key="index"  @click="activeDiscountOptions(index)" :active="index == invoiceInfo.discountActiveIndex">
                                                            {{ discountInfo.name }}
                                                        </b-dropdown-item>
                                                    </b-dropdown> 
                                                </b-input-group-append>
                                            </b-input-group>   
                                        </li>
                                        <li v-if="invoiceType == 'recurring'">
                                            <ul class="list-unstyled">
                                                <li>
                                                    <div class="mb-3 mt-3">
                                                        <h6>Recurring Configuration:</h6>
                                                    </div>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="frequency" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.frequency,'success':schedule.frequency}">
                                                            <span class="float-label">
                                                                <label for="functions">Select a Frequency*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="schedule.frequency"  
                                                                    :show-labels="false" 
                                                                    :options="frequencyOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !schedule.frequency"></i>
                                                                <i class="fa fa-check" v-if="schedule.frequency"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li v-if="schedule.frequency == 'Weekly'">
                                                    <ValidationProvider name="days" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.days,'success':schedule.days}">
                                                            <span class="float-label">
                                                                <label for="functions">Select a days*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="schedule.days"  
                                                                    :show-labels="false" 
                                                                    :options="daysOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !schedule.days"></i>
                                                                <i class="fa fa-check" v-if="schedule.days"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li v-if="schedule.frequency == 'Custom'">
                                                    <ValidationProvider name="daysAfter" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.daysAfter,'success':schedule.daysAfter}">
                                                            <span class="float-label">
                                                                <label for="functions">Select days frequncy*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="schedule.daysAfter"  
                                                                    :show-labels="false" 
                                                                    :options="daysAfterOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !schedule.daysAfter"></i>
                                                                <i class="fa fa-check" v-if="schedule.daysAfter"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="startDate" rules="required" v-slot="{ errors }">
                                                        <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.startDate && !errors[0]}">
                                                            <span class="float-label">
                                                                <b-input-group>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">Start Date*</span>
                                                                        <span class="input-group-text" @click="$refs.startdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                    </div>
                                                                    <datepicker ref="startdate"  class="form-control" :bootstrap-styling="false" v-model="schedule.startDate" :typeable="true"></datepicker>
                                                                </b-input-group>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="schedule.startDate && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="endDate" rules="required" v-slot="{ errors }">
                                                        <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.endDate && !errors[0]}">
                                                            <span class="float-label">
                                                                <b-input-group>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">End Date*</span>
                                                                        <span class="input-group-text" @click="$refs.endDate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                    </div>
                                                                    <datepicker ref="endDate"  class="form-control" :bootstrap-styling="false" v-model="schedule.endDate" :typeable="true"></datepicker>
                                                                </b-input-group>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="schedule.endDate && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="endAfter" rules="required" v-slot="{ errors }">
                                                        <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.endAfter && !errors[0]}">
                                                            <span class="float-label">
                                                                <b-input-group>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">End After*</span>
                                                                        <span class="input-group-text" @click="$refs.endAfter.showCalendar()"><i class="icon-calendar"></i></span>
                                                                    </div>
                                                                    <datepicker ref="endAfter"  class="form-control" :bootstrap-styling="false" v-model="schedule.endAfter" :typeable="true"></datepicker>
                                                                </b-input-group>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="schedule.endAfter && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="timezone" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.timezone,'success':schedule.timezone}">
                                                            <span class="float-label">
                                                                <label for="functions">Select a timezone*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="schedule.timezone"  
                                                                    :show-labels="false" 
                                                                    :options="timezoneOptions" 
                                                                    :searchable="false">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && !schedule.timezone"></i>
                                                                <i class="fa fa-check" v-if="schedule.timezone"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                                <router-link  to="/invoice/list" class="btn btn-secondary btn-mw btn-lg">Cancel</router-link>
                                                <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Continue</b-button>
                                            </div>
                                        </li>
                                    </ul>
                                </form> 
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const Datepicker = require('vuejs-datepicker').default
const moment = require('moment')
var todayDate = moment(new Date()).format("YYYY,MM,DD");
export default {
    name:'AddInvoiceInfoComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        Datepicker,
    },data(){
        return{
            invoiceInfo:{
                invoiceNumberReadOnly:true,
                invoiceNumber:'608978',
                currency:null,
                invoiceDate:new Date(todayDate),
                dueDate:new Date(todayDate),
                taxRows:[
                    {
                        taxValue:'',
                        taxSelectedOptions:'VAT',
                        taxActiveIndex:0,
                        otherTaxName:'',
                    }
                ],
                discount:'%',
                discountActiveIndex: 0,
            },
            disabledDates: {
                to: new Date(todayDate)
            },
            currencyOptions: [
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],

            taxOptions:[
                {
                    name:'VAT'
                },
                {
                    name:'Sales Tax'
                },
                {
                    name:'Other'
                }
            ],

            discountOptions:[
                {
                    name:'%'
                },
                {
                    name:'Amt'
                }
            ],

            invoiceType: this.$route.query.type,
            schedule:{
                frequency:'',
                startDate:null,
                daysAfter:null,
                days:null,
                endDate:null,
                endAfter:null,
                timezone:null
            },
            frequencyOptions:['Daily', 'Weekly', 'Monthly', 'Yearly','Custom'],
            timezoneOptions:[
                '(UTC-12:00) International Date Line West',
                '(UTC-11:00) Coordinated Universal Time-11',
                '(UTC-10:00) Hawaii',
                '(UTC-09:00) Alaska',
                '(UTC-08:00) Baja California',
                '(UTC-07:00) Pacific Time (US &amp; Canada)',
                '(UTC-08:00) Pacific Time (US &amp; Canada)',
                '(UTC-07:00) Arizona',
                '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
                '(UTC-07:00) Mountain Time (US &amp; Canada)',
                '(UTC-06:00) Central America',
                '(UTC-06:00) Central Time (US &amp; Canada)',
                '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
                '(UTC-06:00) Saskatchewan',
                '(UTC-05:00) Bogota, Lima, Quito'
            ],
            daysOptions:[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            daysAfterOptions:[
                'Every 2 Days',
                'Every 3 Days',
                'Every 4 Days',
                'Every 5 Days',
                'Every 10 Days',
                'Every 15 Days',
                'Every 20 Days'
            ]
        }
    },methods:{
        invoiceDetailsForm(){
            this.$router.push({path:'/invoice/add-invoice-item', query:{ type: this.$route.query.type, recipient: this.$route.query.recipient }});
        },

        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },

        activeDiscountOptions(menuIndex){
            this.invoiceInfo.discountActiveIndex = menuIndex;
            this.invoiceInfo.discount = this.discountOptions[menuIndex].name;
        },

        editInvoiceNumber(){
            if(this.invoiceInfo.invoiceNumber){
                this.invoiceInfo.invoiceNumberReadOnly = !this.invoiceInfo.invoiceNumberReadOnly 
                this.$refs.btnToggle.innerText = this.invoiceInfo.invoiceNumberReadOnly ? 'Edit':'Save';
            }
        },

        activeTaxOptions(taxRowId,menuIndex){
            this.invoiceInfo.taxRows[taxRowId].taxActiveIndex = menuIndex;
            this.invoiceInfo.taxRows[taxRowId].taxSelectedOptions = this.taxOptions[menuIndex].name;
        },

        addNewTaxRow(){
            this.invoiceInfo.taxRows.push(
                {
                    taxValue:'',
                    taxSelectedOptions:'VAT',
                    taxActiveIndex:0,
                    otherTaxName:'',
                }
            );
        },

        removeTaxRow(index){
            this.invoiceInfo.taxRows.splice(index, 1);
        }
    }
}
</script>