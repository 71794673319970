<template>
   <nav class="navbar navbar-fixed-top">
        <div class="container-fluid">
            <div class="navbar-btn">
                <button type="button" class="btn btn-toggle-offcanvas"><i class="lnr lnr-menu fa fa-bars" v-on:click="toggleSideMenu()"></i></button>
            </div> 
            <div class="navbar-right">
                <!-- <form id="navbar-search" class="navbar-form search-form">
                    <input value="" class="form-control" placeholder="Search here..." type="text">
                    <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                </form> -->

                <div id="navbar-menu">
                    <ul class="nav navbar-nav">
                        <!-- <li>
                            <router-link to="/uikit/file-documents" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="fa fa-folder-open-o"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/uikit/app-calendar" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="icon-calendar"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/uikit/app-chat" class="icon-menu d-none d-sm-block"><i class="icon-bubbles"></i></router-link>
                        </li>
                        <li>
                            <router-link to="/uikit/app-inbox" class="icon-menu d-none d-sm-block"><i class="icon-envelope"></i><span class="notification-number">8</span></router-link>
                        </li> -->
                        <li class="dropdown" v-bind:class="{ 'show': notificationMenu}"  @click="notificationMenu = !notificationMenu" v-click-outside="notificationOutside">
                            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                                <i class="icon-bell"></i>
                                <span class="notification-number">0</span>
                            </a>
                            <!-- <ul class="dropdown-menu notifications" v-bind:class="{ 'show': notificationMenu}" :style="notificationMenu ? 'top: 100%;' : 'top: 80%;'">
                                <li class="header"><strong>You have 4 new Notifications</strong></li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-info text-warning"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Campaign <strong>Holiday Sale</strong> is nearly reach budget limit.</p>
                                                <span class="timestamp">10:00 AM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-like text-success"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Your New Campaign <strong>Holiday Sale</strong> is approved.</p>
                                                <span class="timestamp">11:30 AM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                 <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-pie-chart text-info"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Website visits from Twitter is 27% higher than last week.</p>
                                                <span class="timestamp">04:00 PM Today</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <div class="media">
                                            <div class="media-left">
                                                <i class="icon-info text-danger"></i>
                                            </div>
                                            <div class="media-body">
                                                <p class="text">Error on website analytics configurations</p>
                                                <span class="timestamp">Yesterday</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="footer"><a href="javascript:void(0);" class="more">See all notifications</a></li>
                            </ul> -->
                        </li>
                        <li class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive; showBusinessMenuOption()" v-click-outside="outside">
                            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown"><i class="icon-equalizer"></i></a>
                            <ul class="dropdown-menu user-menu menu-icon" v-bind:class="{ 'show': dropActive}" :style="dropActive ? 'top: 100%;' : 'top: 80%;'">
                                <li class="menu-heading">ACCOUNT SETTINGS</li>
                                <!-- <li><a href="javascript:void(0);"><i class="icon-note"></i> <span>Basic</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-equalizer"></i> <span>Preferences</span></a></li> -->
                                <li><a href="javascript:void(0);"><i class="icon-lock"></i> <span>Privacy</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-bell"></i> <span>Notifications</span></a></li>
                                <li v-if="showBusinessMenu"><a href="javascript:void(0);" @click="showBusiness()"><i class="fa fa-briefcase"></i> <span>Business</span></a></li>
                                <!-- <li class="menu-heading">BILLING</li>
                                <li><a href="javascript:void(0);"><i class="icon-credit-card"></i> <span>Payments</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-printer"></i> <span>Invoices</span></a></li>
                                <li><a href="javascript:void(0);"><i class="icon-refresh"></i> <span>Renewals</span></a></li> -->
                            </ul>
                        </li>
                        <li>
                            <router-link to="javascript:void(0)" class="icon-menu" @click.native="logout()"><i class="icon-login"></i></router-link>
                        </li>
                    </ul>
                </div>
                <business-modal></business-modal>
            </div>
        </div>
    </nav>
</template>

<script>
//import { EventBus } from '@/plugins/event-bus.js';
const businessModal = require('@/components/admin/account/BusinessModal.vue').default
export default {
    name: 'HeaderComponent',
    components: {
        'business-modal':businessModal
    },methods:{
        outside: function() {
           this.dropActive = false;
        },
        notificationOutside: function() {
           this.notificationMenu = false
        },
        toggleSideMenu: function () {
            document.body.classList.toggle('offcanvas-active')
        },
        logout(){
            // sessionStorage.clear();
            //sessionStorage.removeItem('jwtToken');
            sessionStorage.clear()
            this.$router.push({ path: '/standapp-home' })
        },
        showBusiness(){
            this.$root.$refs.BusinessModal.showmodal('Switch Account');
        },
        showBusinessMenuOption(){
            this.getBusinessList();
        },
        async getBusinessList (){
            // alert(1);
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '2000',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let businessLists = {
                        id : item.businessid,
                        title: item.name,
                        countryName: item.countryname
                    };
                    this.businessList.push(businessLists);
                });  
                if(this.businessList.length > 1)  {
                    this.showBusinessMenu = true;
                } else {
                    this.showBusinessMenu = false;
                }
                // alert(this.showBusinessMenu);
                // console.log(this.businessList);            
            } catch (error) {
                console.log(error);
            }
        }
    },data() {
        return {
            dropActive: false,
            notificationMenu: false,
            toggleSidebarClass: false,
            //toggleSidebarTheme: '',
            showBusinessMenu:false,
            businessList:[]
        }
    },directives: {
    'click-outside': {
        bind: function(el, binding) {
            // Define Handler and cache it on the element
            const bubble = binding.modifiers.bubble
            const handler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e)
            }
            }
            el.__vueClickOutside__ = handler

            // add Event Listeners
            document.addEventListener('click', handler)
            },
        }
    }
    // ,mounted() {
    //     this.getBusinessList();
    // }
}
</script>

