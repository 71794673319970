<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="mb-3">
                            <h6>Add Invoice Recipient</h6>
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(invoiceRecipientForm)" id="invoice-recipient">
                                <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true" v-model="currentActiveTabIndex">
                                    <b-tab title="Business" active>
                                        <ul class="list-unstyled mb-0 common-form small-form">
                                            <li>
                                                <ValidationProvider name="country" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':business.country && !errors[0]}">
                                                            <span class="float-label">
                                                                <label for="Country">Country*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="business.country" 
                                                                    :show-labels="false" 
                                                                    :options="countrysOptions" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    @select="setPhoneNumberFlag">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="business.country && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="businessName" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':business.businessName && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Business Name*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="business.businessName" 
                                                                    :show-labels="false" 
                                                                    :options="businessOptions" 
                                                                    label="name"
                                                                    :searchable="true"
                                                                    @select="businessNameSelected">
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="business.businessName && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="firstName" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !business.firstName,'success':business.firstName}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="business.firstName" class="form-control" id="firstName" placeholder="Contact First Name*">
                                                            <label for="firstName" class="control-label">Contact First Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !business.firstName"></i>
                                                            <i class="fa fa-check" v-if="business.firstName"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="lastName" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !business.lastName,'success':business.lastName}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="business.lastName" class="form-control" id="lastName" placeholder="Last Name*">
                                                            <label for="lastName" class="control-label">Last Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !business.lastName"></i>
                                                            <i class="fa fa-check" v-if="business.lastName"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="form-group" :class="{'errors': phoneNumberError == false,'success':business.phoneNumber && phoneNumberError}">
                                                    <div class="float-label">
                                                        <label for="primary_number" class="control-label">Phone Number*</label>
                                                        <vue-tel-input
                                                            v-model="business.phoneNumber"
                                                            v-bind="primaryMobileNumber"
                                                            @validate ="mobileInputValidate"
                                                            ref="telInput"
                                                            @keydown.native="restrictSpecialCharacter($event)">
                                                            <template v-slot:arrow-icon>
                                                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                            </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="phoneNumberError == false"></i>
                                                        <i class="fa fa-check" v-if="phoneNumberError && business.phoneNumber"></i>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <ValidationProvider name="email" :rules="currentActiveTabIndex == 0 ? 'required|email' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !business.email || errors[0],'success':business.email && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="business.email" class="form-control" name="business-email" id="business-email">
                                                            <label for="business-email" class="control-label">Email*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !business.email || errors[0]"></i>
                                                            <i class="fa fa-check" v-if="business.email && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="form-group" >
                                                    <span class="float-label">
                                                        <input type="text" v-model="business.address1" class="form-control" id="address1" placeholder="Address Line 1*">
                                                        <label for="address1" class="control-label">Address Line 1</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="business.address2" class="form-control" id="address2" placeholder="Address Line 2">
                                                        <label for="address2" class="control-label">Address Line 2</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="business.city" class="form-control" id="city" placeholder="City*">
                                                        <label for="city" class="control-label">Town/City</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="business.scpr" class="form-control" id="state" placeholder="State/Province/Region/County*"/>
                                                        <label for="state" class="control-label">State/County/Province/Region</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="business.postCode" class="form-control" id="zipCode" placeholder="Post/Zip Code*"/>
                                                        <label for="zipCode" class="control-label">Post/Zip/Address Code</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <strong>Note</strong>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <textarea type="text" v-model="business.note" class="form-control" id="note" placeholder="Note"/>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group clearfix">
                                                    <label class="fancy-checkbox new-fancy">
                                                        <input type="checkbox" v-model="business.futureReference">
                                                        <span>Save this customer for future reference</span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                                    <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Use These Details</b-button>
                                                </div>
                                            </li>
                                        </ul>
                                    </b-tab>
                                    <b-tab title="Individual">
                                        <ul class="list-unstyled mb-0 common-form small-form">
                                            <li>
                                                <ValidationProvider name="select_country" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':individual.country && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Country*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="individual.country" 
                                                                    :show-labels="false" 
                                                                    :options="countrysOptions" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    @select="setPhoneNumberFlag">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="individual.country && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="fname" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !individual.fname,'success':individual.fname}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="individual.fname" class="form-control" id="fname" placeholder="Contact First Name*">
                                                            <label for="fname" class="control-label">Contact First Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !individual.fname"></i>
                                                            <i class="fa fa-check" v-if="individual.fname"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="lname" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !individual.lname,'success':individual.lname}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="individual.lname" class="form-control" id="lname" placeholder="Last Name*">
                                                            <label for="lname" class="control-label">Last Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !individual.lname"></i>
                                                            <i class="fa fa-check" v-if="individual.lname"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="form-group" :class="{'errors': indivisualPhoneNumberError == false,'success':individual.phoneNumber && indivisualPhoneNumberError}">
                                                    <div class="float-label">
                                                        <label for="phoneNumber" class="control-label">Phone Number*</label>
                                                        <vue-tel-input
                                                            v-model="individual.phoneNumber"
                                                            v-bind="primaryMobileNumber"
                                                            @validate ="indivisualMobileInputValidate"
                                                            ref="telInput"
                                                            @keydown.native="restrictSpecialCharacter($event)">
                                                            <template v-slot:arrow-icon>
                                                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                            </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="indivisualPhoneNumberError == false"></i>
                                                        <i class="fa fa-check" v-if="indivisualPhoneNumberError && individual.phoneNumber"></i>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <ValidationProvider name="individual-email" :rules="currentActiveTabIndex == 1 ? 'required|email' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !individual.email || errors[0],'success':individual.email && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="individual.email" class="form-control" name="individual-email" id="individual-email">
                                                            <label for="individual-email" class="control-label">Email*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !individual.email || errors[0]"></i>
                                                            <i class="fa fa-check" v-if="individual.email && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                             <li>
                                                <div class="form-group" >
                                                    <span class="float-label">
                                                        <input type="text" v-model="individual.address1" class="form-control" id="individual-address1" placeholder="Address Line 1*">
                                                        <label for="individual-address1" class="control-label">Address Line 1</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="individual.address2" class="form-control" id="individual-address2" placeholder="Address Line 2">
                                                        <label for="individual-address2" class="control-label">Address Line 2</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="individual.city" class="form-control" id="individual-city" placeholder="City">
                                                        <label for="individual-city" class="control-label">Town/City</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="individual.scpr" class="form-control" id="individual-state" placeholder="State/Province/Region/County*"/>
                                                        <label for="individual-state" class="control-label">State/County/Province/Region</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="individual.postCode" class="form-control" id="individual-zipCode" placeholder="Post/Zip Code*"/>
                                                        <label for="individual-zipCode" class="control-label">Post/Zip/Address Code</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <strong>Note</strong>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <textarea type="text" v-model="individual.note" class="form-control" id="note" placeholder="Note"/>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group clearfix">
                                                    <label class="fancy-checkbox new-fancy">
                                                        <input type="checkbox" v-model="individual.futureReference">
                                                        <span>Save this customer for future reference</span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                                    <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Use These Details</b-button>
                                                </div>
                                            </li>
                                        </ul>
                                    </b-tab>
                                </b-tabs>
                            </form> 
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const { VueTelInput } = require('vue-tel-input')
export default {
    name:'AddCustomerComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data(){
        return{
            currentActiveTabIndex: 0,
            business: {
                country: null,
                firstName:'',
                lastName:'',
                businessName: '',
                phoneNumber:'',
                email:'',
                address1:'',
                address2:'',
                city:'',
                scpr:'',
                postCode:'',
                note:'',
                futureReference:true
            },
            individual:{
                country: null,
                fname:'',
                lname:'',
                phoneNumber:'',
                email:'',
                address1:'',
                address2:'',
                city:'',
                scpr:'',
                postCode:'',
                note:'',
                futureReference:true
            },
            countrysOptions: [
                // { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                // { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
                // { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
                // { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            ],

            phoneNumberError:'',
            indivisualPhoneNumberError:'',
            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            },

            businessOptions: [], //['PAYINC GROUP LTD','PAYINC CA LLC','PAYINC US LLC','PAYINC GHANA LTD'],
        }
    },methods:{
        invoiceRecipientForm(){
            alert(this.currentActiveTabIndex);
            if(this.currentActiveTabIndex == 0){
                if(typeof(this.phoneNumberError) == 'undefined'){
                    this.phoneNumberError = false
                }
                if(this.phoneNumberError){
                    this.$router.push({ path: '/invoice/customer-list' })
                }
            }else{
                if(typeof(this.indivisualPhoneNumberError) == 'undefined'){
                    this.indivisualPhoneNumberError = false
                }
                if(this.indivisualPhoneNumberError){
                    this.$router.push({ path: '/invoice/customer-list' })
                }
            }
           
        },

        setPhoneNumberFlag(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
        },

        mobileInputValidate(phoneObject){
            this.phoneNumberError = phoneObject.valid
        },
        indivisualMobileInputValidate(phoneObject){
            this.indivisualPhoneNumberError = phoneObject.valid
        },
        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        async businessNameSelected(businessName){
            if(businessName){
                // let searchparam = {
                //     search_name : businessName
                // }
                // const response = await this.$http.get(this.$apiv2url+"invoice/bussinesses/",{
                //     params: searchparam,
                //     headers: {
                //         'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                //     }
                // });
                // console.log(response);

                this.business.country =  [{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'}],
                this.$refs.telInput.choose(this.business.country[0].primaryNumberCode)
                this.business.firstName = 'John',
                this.business.lastName = 'Smith',
                this.business.phoneNumber = '7564387654',
                this.business.email = 'payangl@gmail.com'
                this.business.address1 = '10 Downing Street,LONDON',
                this.business.address2 = '1 Bishopthorpe Road',
                this.business.city = 'Penllyn',
                this.business.scpr = 'England',
                this.business.postCode = 'CF71 0HZ'
            }
        },

        async getCountryData() {
            try {
                const response = await this.$http.get(this.$baseurl+"countries/available");
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                        primaryNumberCode:item.isocode,
                        code: item.mobilecountrycode
                    }
                    this.countrysOptions.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        }, 
        
        async getBusinessData() {
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '2000',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let businessLists = {
                        id : item.businessid,
                        name: item.name
                    };
                    this.businessOptions.push(businessLists);
                });                
            } catch (error) {
                console.log(error);
            }
        }
    }, mounted(){
        this.getCountryData();
        this.getBusinessData();
    }
}
</script>