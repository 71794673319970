<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card no-shadow">
                     <!-- Header section-->
                    <!-- <div class="header">
                        <h2>{{businessName}}</h2>
                    </div> -->
                    <div class="body pt-0">
                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <!-- Subsidiaries Tab -->
                            <b-tab title="Personnels">
                                <!-- Personnel Tab -->
                                
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="personnelSearchText" placeholder="Search Users" aria-label="Search Users" aria-describedby="search-personnel" v-on:keyup.enter="searchPersonnelData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-personnel"><i class="icon-magnifier" @click="searchPersonnelData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <router-link to="/setting/branch-user-add" class="btn btn-primary btn-lg btn-mw">Add User <i class="fa fa-plus-square ml-1"></i></router-link>
                                        </div>
                                    </div>
                                </div>
                                
                                <b-table
                                    ref = "personnelTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="personnel_items" 
                                    :fields="personnel_fields"
                                    :current-page="personnelCurrentPage"
                                    :per-page="personnelPerPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(details)="data">
                                        <div class="media-object flag-img mr-2">
                                            <img :src="data.value.user_img" alt="" width="50px" class="rounded-circle">
                                            <span :class="data.value.flagClass"></span>
                                        </div>
                                        <span class="user-name">{{ data.value.name }}</span>
                                    </template>
                                    <template #cell(action)>
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item>Edit</b-dropdown-item>
                                            <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                    <template #cell(function)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-success" v-for="(functions,index) in data.value" :key="index">{{ functions }}</span>
                                        </p>
                                    </template>
                                    <template #cell(role)="data">
                                        <p class="role-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-info" v-for="(roles,index) in data.value" :key="index">{{ roles }}</span>
                                        </p>
                                    </template>
                                    <template #cell(services)="data">
                                        <p class="services-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-default" v-for="(services,index) in data.value" :key="index">{{ services }}</span>
                                        </p>
                                    </template>
                                    <template #cell(type)="data">
                                        <p class="access-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-danger" v-for="(access,index) in data.value" :key="index">{{ access }}</span>
                                        </p>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="personnelTotalRows > 0?' d-flex':'d-none'">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="personnelPerPage" :options="personnelPageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="personnelCurrentPage" 
                                            :total-rows="personnelTotalRows" 
                                            :per-page="personnelPerPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page</span>
                                            </div>
                                            <input type="text" v-model="personnelsCurrentPageJump" class="form-control"  value="" @keyup.enter="personnelOnEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <business-model :formTypeData="subBusinessType" :formTypeTitle="modalTitle"></business-model>
        <main-loader ref="loader"></main-loader>

    </div>
    
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const AddBusinessModel = require('@/components/core/AddBusinessModel.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default


import '@/plugins/sweetalert2'
import shared from "@/shared.js";
export default {
    name:'BusinesDetailComponent',
    components: {
        BreadCrumb,
        'business-model':AddBusinessModel,
        'main-loader':LoaderComponent
    },data (){
        return{
            //All Subsidiaries List table pagination options
            subsidiariesTotalRows: 0,
            subsidiariesCurrentPage: 1,
            subsidiariesCurrentPageJump: 1,
            subsidiariesPerPage: 10,
            subsidiariesPageOptions: [10,25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //All Subsidiaries list table json
            // subsidiaries_items:[
            //     {uuid:'',name:'PayAngel Group',merchant_id:'722102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Active',class:'badge-success'},action:''},
            //     {uuid:'',name:'Thememakker Group',merchant_id:'209812',dor:'17.01.2017',owner:'Maxine Jennings',ltd_to:'$0.20',status:{name:'Suspended',class:'badge-danger'},action:''},
            //     {uuid:'',name:'Wrraptheme Group',merchant_id:'628472',dor:'15.01.2017',owner:'Eddie Kane',ltd_to:'$0.11',status:{name:'Dormant',class:'badge-warning'},action:''},
            //     {uuid:'',name:'Angel community Group',merchant_id:'392659',dor:'16.01.2017',owner:'Harry Bowen',ltd_to:'$0.16',status:{name:'Active',class:'badge-success'},action:''},
            // ],
           
            //All Subsidiaries list table header fields
            subsidiaries_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'businessname', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchandid', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Clients List table pagination options
            clientsTotalRows: 0,
            clientsCurrentPage: 1,
            clientsCurrentPageJump: 1,
            clientsPerPage: 10,
            clientsPageOptions: [10,25, 50, { value: 100, text: 100 }],


            //All Clients list table json
            // clients_items:[
            //     {uuid:'',name:'PayAngel Group',merchant_id:'729102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Active',class:'badge-success'},action:''},
            //     {uuid:'',name:'Thememakker Group',merchant_id:'842102',dor:'09.11.2017',owner:'Maxine Jennings',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
            //     {uuid:'',name:'Wrraptheme Group',merchant_id:'422002',dor:'10.09.2018',owner:'Eddie Kane',ltd_to:'$0.10',status:{name:'Suspended',class:'badge-danger'},action:''},
            //     {uuid:'',name:'Angel community Group',merchant_id:'156102',dor:'11.03.2019',owner:'Harry Bowen',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
            // ],
           
            //All Clients list table header fields
            clients_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'businessname', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchandid', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Branch List table pagination options
            branchTotalRows: 0,
            branchCurrentPage: 1,
            branchCurrentPageJump: 1,
            branchPerPage: 10,
            branchPageOptions: [10,25, 50, { value: 100, text: 100 }],

            //All Branch list table json
            branch_items:[
                {uuid:'',name:'PayAngel Group',merchant_id:'722102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
                {uuid:'',name:'Thememakker Group',merchant_id:'209812',dor:'17.01.2017',owner:'Maxine Jennings',ltd_to:'$0.20',status:{name:'Suspended',class:'badge-danger'},action:''},
                {uuid:'',name:'Wrraptheme Group',merchant_id:'628472',dor:'15.01.2017',owner:'Eddie Kane',ltd_to:'$0.11',status:{name:'Suspended',class:'badge-danger'},action:''},
                {uuid:'',name:'Angel community Group',merchant_id:'392659',dor:'16.01.2017',owner:'Harry Bowen',ltd_to:'$0.16',status:{name:'Suspended',class:'badge-danger'},action:''},
            ],
           
            //All Branch list table header fields
            branch_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'branchid', label: 'Merchant Code',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Personnel List table pagination options
            personnelTotalRows: 0,
            personnelCurrentPage: 1,
            personnelsCurrentPageJump: 1,
            personnelPerPage: 10,
            personnelPageOptions: [10,25, 50, { value: 100, text: 100 }],

            //All Personnel list table json
            //All Personnel list table json
            personnel_items:[
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                    function:['ADMIN'],
                    role:['ADMIN','MANAGER'],
                    services:['DASHBOARD'],
                    type:['READ'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                    function:['ADMIN','FINANCE','TELLER'],
                    role:['OWNER','ADMIN','MANAGER','USER','TELLER'],
                    services:['DASHBOARD','USER MANAGEMENT'],
                    type:['READ','WRITE','EDIT','FULL'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar3.jpg`),flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                    function:['ADMIN'],
                    role:['OWNER','MANAGER'],
                    services:['TRANSACTIONS'],
                    type:['EDIT'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-eu fi-md fi-round"},
                    function:['SALES','HR'],
                    role:['USER'],
                    services:['DASHBOARD'],
                    type:['WRITE'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar2.jpg`),flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                    function:['HR'],
                    role:['MANAGER'],
                    services:['DASHBOARD','USER MANAGEMENT'],
                    type:['READ'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-ru fi-md fi-round"},
                    function:['SALES','HR'],
                    role:['USER'],
                    services:['DASHBOARD'],
                    type:['WRITE'],
                    action:''
                },
            ],
           
            //All Personnel list table header fields
            personnel_fields: [
                { key: 'details', label: 'Name',sortable: true,thClass:'user-name-img th_sortfix'},
                { key: 'function', label: 'Function',sortable: true,thClass:'function-badge th_sortfix'},
                { key: 'role', label: 'Role',sortable: true,thClass:'role-badge th_sortfix'},
                { key: 'services', label: 'Services',sortable: true,thClass:'services-badge th_sortfix'},
                { key: 'type', label: 'Access Type',sortable: true,thClass:'access-badge th_sortfix'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            breadCrumbIteams:[
                {
                    title:'Business',
                    links:'/business/list'
                },
                {
                    title:'',
                    links:''
                }
            ],
            subBusinessType: '',
            modalTitle: '',
            businessName : '',
            subsidiarySearchText:'',
            clientSearchText: '',
            branchSearchText: '',
            personnelSearchText: '',
            isPersonnel: false
        }
    },methods: {
        searchPersonnelData() {
            this.refreshPersonnelTable();
        },
        getSelectedItem(arg) {
            this.perPage = arg;
            this.getSubsidiaryList();
        },
        refreshPersonnelTable() {
            this.$refs.personnelTable.refresh();
        },

       

        //personnel go to personnel
        personnelOnEnter(e) {
            var paginationMenu = Math.ceil(this.personnelTotalRows / this.personnelPerPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.personnelCurrentPage = this.personnelsCurrentPageJump = e.target.value
                } else {
                   this.personnelCurrentPage = this.personnelsCurrentPageJump = paginationMenu; 
                }
            } else {
                this.personnelCurrentPage = this.personnelsCurrentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },

        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,              
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }
            })
        },
        
        async getPersonnelList(ctx) {
            try{
                this.$refs.loader.show();
                let currentpage  = '';
                let perpage = '';
                let sortby = '';
                let sortdesc = false;
                if(ctx.currentPage != undefined) {
                    currentpage =  ctx.currentPage;
                } else {
                    currentpage = 1;
                }
                if(ctx.perPage != undefined) {
                    perpage = ctx.perPage;
                } else {
                    perpage = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sortdesc = 'DESC'
                } else {
                    sortdesc = 'ASC';
                }
                
                this.personnelsCurrentPageJump = currentpage;
                //subsidiariesCurrentPage: 0,
                //subsidiariesCurrentPageJump: 0,
                this.personnelItems = [];
                const response = await this.$http.get(this.$baseurl+'business/personnels?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&businessid='+sessionStorage.getItem('businessIdFromBusinessList')+'&search='+this.personnelSearchText,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                    if(response.data.code == '0067-000') {
                        response.data.details.forEach(element => {
                            let businessItem = {
                                details: {name:element.firstname,user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                                function:['ADMIN'],
                                role:['ADMIN','MANAGER'],
                                services:['DASHBOARD'],
                                type:['READ'],
                                action:''
                        };
                    
                        this.personnelItems.push(businessItem);
                    })
                        this.personnelTotalRows = response.data.query.total; 
                        this.$refs.loader.hide();
                        return this.personnelItems;
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);                            
                    }
            } catch(error) {
                //return [];
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);                            
            }
        },
        
    },
    mounted() {
        if(sessionStorage.getItem('personnelList')==='1') {
            this.isPersonnel = true;
        }
        // if(!sessionStorage.getItem("countryId")){
        //     shared.toastrError('Please select a business first');
        //     this.$router.push({path:'/business/list'});
        // }
        if(sessionStorage.getItem("jwtToken")){
            //this.subsidiariesTotalRows = this.subsidiaries_items.length
            //this.clientsTotalRows = this.clients_items.length
            //this.branchTotalRows = this.branch_items.length
            //this.personnelTotalRows = this.personnel_items.length
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
        this.$root.$refs.BusinesDetailComponent = this;
        this.businessName = sessionStorage.getItem('businessName');
        this.breadCrumbIteams[1].title = this.businessName;
        sessionStorage.removeItem('personnelList');
    },computed: {
        
    }
}

</script>
<style lang="sass">

</style>
