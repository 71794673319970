<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="mb-4">
                            <h6>Add New Group</h6>
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(groupSubmitForm)" id="add-group">
                                <ul class="list-unstyled mb-0 common-form small-form">
                                    <li>
                                        <ValidationProvider name="groupName" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !group.groupName,'success':group.groupName}">
                                                <span class="float-label">
                                                    <input type="text" v-model="group.groupName" class="form-control" id="groupName" placeholder="Group Name*">
                                                    <label for="groupName" class="control-label">Group Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !group.groupName"></i>
                                                    <i class="fa fa-check" v-if="group.groupName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown curancy-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':group.members && !errors[0]}">
                                                    <span class="float-label">
                                                        <label for="roles">Select Members</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="group.members" 
                                                            :show-labels="false" 
                                                            :options="MemberOptions" 
                                                            :searchable="true"
                                                            :multiple="true" 
                                                            :close-on-select="true"
                                                            :clear-on-select="false" 
                                                            label="name" 
                                                            track-by="name"  
                                                            :limit="0"
                                                            :limit-text="displayText"
                                                            @select="isCheckedInput"
                                                            @remove="isCheckedInput"
                                                            @search-change="searchQuery">
                                                            <template slot="noResult" >
                                                                <span class="option__title">No results were found</span>
                                                            </template>
                                                            <template slot="beforeList" v-if="group.hideAllOptionOnSearch">
                                                                <span class="multiselect__option" @click="selectAllChanged">
                                                                    <div class="option__desc">
                                                                        <span class="option__title">All</span>
                                                                    </div>
                                                                    <div class="multiselect__element multiselect-all multi-drop">
                                                                        <div class="control-inline fancy-checkbox" for="isChecked">
                                                                            <input type="checkbox" id="checkbox2" name="checkbox2" :checked="group.isAllChecked">
                                                                            <span for="checkbox2"></span>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template slot="option" slot-scope="props">
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                </div>
                                                                <div class="multiselect__element multiselect-all multi-drop">
                                                                    <div class="control-inline fancy-checkbox" for="isChecked">
                                                                        <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                                        <span for="isChecked"></span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </multiselect>  
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="group.members && !errors[0]"></i> 
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <strong>Note</strong>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea type="text" v-model="group.note" class="form-control" id="note" placeholder="Note"/>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group clearfix">
                                            <label class="fancy-checkbox new-fancy">
                                                <input type="checkbox" v-model="group.futureReference">
                                                <span>Save this group for future reference</span>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                            <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                            <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Use These Details</b-button>
                                        </div>
                                    </li>
                                </ul>
                            </form> 
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
export default {
    name:'AddGroupComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data(){
        return{
            group:{
                groupName:'',
                members:'',
                note:'',
                futureReference:true,
                isAllChecked:false,
                hideAllOptionOnSearch : true
            },
            MemberOptions: [
                {id:0,name: 'PayAngel',isChecked: false},
                {id:1,name: 'PayAngel Ghana',isChecked: false},
                {id:2,name: 'PayAngel Group',isChecked: false},
                {id:3,name: 'StandApp Group', isChecked: false},
                {id:4,name: 'Angel Communities',isChecked: false}
            ]
        }
    },methods:{
        groupSubmitForm(){
            this.$router.push({ path: '/invoice/customer-list' })
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.group.members[0].name
            }else if(count == this.MemberOptions.length){
                this.group.isAllChecked = true;
                return count + ' Members Selected.'
              
            }else {
                this.group.isAllChecked = false;
                return  count + ' Members Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.group.isAllChecked = false;
                this.group.members = [];
                this.MemberOptions.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.group.isAllChecked = true;
                this.group.members = this.MemberOptions.slice();
                this.MemberOptions.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.MemberOptions[actionName.id].isChecked = !this.MemberOptions[actionName.id].isChecked
        },

        searchQuery(query){
            if(query){
                this.group.hideAllOptionOnSearch = false
            }else{
                this.group.hideAllOptionOnSearch = true
            }
        }
    },computed: {
        isAllSelected: function() {
            return  (this.group.members.length === this.MemberOptions.length)
        }
    }
}
</script>