<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card no-shadow">
                     <!-- Header section-->
                    <div class="header">
                        <h2>Ghana</h2>
                    </div>
                    <div class="body pt-0">
                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <!-- Contact Details Tab -->
                            <b-tab title="Contact Details">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(contactDetailSubmit)" id="contactdetail-form">
                                                        <!-- <ValidationProvider name="address" rules="required" v-slot="{ errors}"> -->
                                                            <div class="form-group">
                                                             <!-- :class="{'errors': errors.length && !contactDetail.address,'success':contactDetail.address}"> -->
                                                                <span class="float-label">
                                                                    <textarea type="text" v-model="contactDetail.address" class="form-control" id="address" placeholder="Physical Address*"/>
                                                                    <label for="address" class="control-label">Physical Address*</label>
                                                                    <div class="line"></div>
                                                                    <!-- <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="contactDetail.address && !errors[0]"></i> -->
                                                                </span>
                                                            </div>
                                                        <!-- </ValidationProvider> -->

                                                        <!-- <ValidationProvider name="email" rules="required|email" v-slot="{ errors}"> -->
                                                            <div class="form-group">
                                                                 <!-- :class="{'errors': errors.length && !contactDetail.email || errors[0],'success':contactDetail.email && !errors[0]}"> -->
                                                                <span class="float-label">
                                                                    <input type="email" v-model="contactDetail.email" class="form-control" name="email" id="signin-email" placeholder="Email*">
                                                                    <label for="signin-email" class="control-label">Email Address*</label>
                                                                    <div class="line"></div>
                                                                    <!-- <i class="fa fa-times" v-if="errors.length && !contactDetail.email || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="contactDetail.email && !errors[0]"></i> -->
                                                                </span>
                                                                <!-- <span class="text-danger" v-if="errors[0]">Please enter a valid Email Address</span> -->
                                                            </div>
                                                        <!-- </ValidationProvider> -->
                                                        
                                                        <!-- <ValidationProvider name="mobile_number" rules="required|numeric" v-slot="{ errors }"> -->
                                                            <div class="form-group">
                                                                 <!-- :class="{'errors': errors[0],'success':contactDetail.mobile_number && !errors[0]}"> -->
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="mobile_number" v-model="contactDetail.mobile_number" placeholder="Contact Mobile Number*">
                                                                    <label for="mobile_number" class="control-label">Contact Mobile Number*</label>
                                                                    <div class="line"></div>
                                                                    <!-- <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="contactDetail.mobile_number && !errors[0]"></i> -->
                                                                </span>
                                                            </div>
                                                        <!-- </ValidationProvider> -->
                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- Bank Details Tab -->
                            <b-tab title="Bank Account Details">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(bankDetailSubmit)" id="bankdetail-form">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.bank_name" class="form-control" name="bank_name" id="bank_name" placeholder="Bank Name">
                                                                    <label for="bank_name" class="control-label">Bank Name</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="bic" v-model="bankDetail.bic" placeholder="BIC">
                                                                    <label for="bic" class="control-label">BIC</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.account_number" class="form-control" name="account_number" id="account_number" placeholder="Account Number">
                                                                    <label for="account_number" class="control-label">Account Number</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="sort_code" v-model="bankDetail.sort_code" placeholder="Sort Code">
                                                                    <label for="sort_code" class="control-label">Sort Code</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.iban" class="form-control" name="iban" id="iban" placeholder="IBAN">
                                                                    <label for="iban" class="control-label">IBAN</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            
                            <!-- Limits Tab -->
                            <!-- <b-tab title="Limits">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(transferLimitSubmit)" id="transferlimit-form">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.fbank_min" class="form-control" id="fbank_min" placeholder="Fast Bank Minimum Limit">
                                                                    <label for="fbank_min" class="control-label">Fast Bank Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="fbank_max" v-model="transferLimit.fbank_max" placeholder="Fast Bank Maximum Limit">
                                                                    <label for="fbank_max" class="control-label">Fast Bank Maximum Limit</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.ccollection_min" class="form-control" id="ccollection_min" placeholder="Cash Collection Minimum Limit">
                                                                    <label for="ccollection_min" class="control-label">Cash Collection Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="ccollection_max" v-model="transferLimit.ccollection_max" placeholder="Cash Collection Maximum Limit">
                                                                    <label for="ccollection_max" class="control-label">Cash Collection Maximum Limit</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.mobile_money_min" class="form-control" id="mobile_money_min" placeholder="Mobile Money Minimum Limit">
                                                                    <label for="mobile_money_min" class="control-label">Mobile Money Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.mobile_money_max" class="form-control" id="mobile_money_max" placeholder="Mobile Money Maximum Limit">
                                                                    <label for="mobile_money_max" class="control-label">Mobile Money Maximum Limit</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab> -->

                            <!-- Discounts Tab -->
                            <!-- <b-tab title="Discounts">
                               <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(transferDiscountSubmit)" id="transferdiscount-form">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Fast Bank Deposit</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.fbank_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Cash Collection</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.ccollection_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activeccollection(index)" :active="index == transferDiscount.ccollection_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.ccollection_discount_amount"  placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Mobile Money</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.mmoney_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activemmoney(index)" :active="index == transferDiscount.mmoney_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.mmoney_discount_amount"  placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab> -->

                            <!-- Supported Currency -->
                            <b-tab title="Supported Currency">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(supportedCurrencySubmit)" id="transferdiscount-form">
                                                        <div class="country-dropdown">
                                                            <div class="mb-3">
                                                                <label class="typo__label">Currency</label>
                                                                <multiselect
                                                                    placeholder="Type to search"
                                                                    v-model="supportedCurrency" 
                                                                    :options="allCurrency" 
                                                                    :searchable="true"
                                                                    :multiple="true" 
                                                                    label="name" 
                                                                    track-by="name">
                                                                    <span slot="noResult">Oops! No records found.</span>
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                            <!-- <span class="option__title"> - {{ props.option.currencyName }}</span> -->
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                            </div>
                                                        </div>
                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- Manage fund option -->
                            <b-tab title="Limits / Discount / Charges">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <b-table 
                                                fixed
                                                responsive
                                                hover 
                                                table-class="js-basic-example table-custom mb-0 risk-table"
                                                head-variant="light"
                                                :items="supportedCurrency" 
                                                :fields="fields">
                                                <template #cell(action)>
                                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                        <b-dropdown-item v-for="(item,index) in fundOptionitems" :key="index" @click="showModal(item.value)">{{item.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                                <template #cell(paymentaction)>
                                                    <b-dropdown text="Actions" right variant="outline-primary">
                                                        <b-dropdown-item v-for="(item,index) in fundOptionitems" :key="index" @click="showModal(item.value)">{{item.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>

                                <b-modal id="manage_payout_modal" title="Manage Payout" hide-footer hide-header centered no-stacking no-close-on-backdrop>
                                    <div class="modal-body">
                                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                                            <b-tab title="Limits">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <div class="body">
                                                                <form @submit.prevent="passes(transferLimitSubmit)" id="transferlimit-form">
                                                                    <div class="row">
                                                                        <div class="form-group col-md-6">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="transferLimit.fbank_min" class="form-control" id="fbank_min" placeholder="Minimum Transfer Limit">
                                                                                <label for="fbank_min" class="control-label">Minimum Transfer Limit</label>
                                                                            </span>
                                                                        </div>
                                                                        <div class="form-group col-md-6">
                                                                            <span class="float-label">
                                                                                <input type="text" class="form-control" id="fbank_max" v-model="transferLimit.fbank_max" placeholder="Maximum Transfer Limit">
                                                                                <label for="fbank_max" class="control-label">Maximum Transfer Limit</label>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ValidationObserver>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab title="Discount">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <div class="body">
                                                                <form @submit.prevent="passes(transferDiscountSubmit)" id="transferdiscount-form">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h6>Discount</h6>
                                                                            <b-input-group class="mb-3">
                                                                                <template #prepend>
                                                                                    <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                                        <template #button-content>
                                                                                            {{ transferDiscount.fbank_discount_type }}
                                                                                        </template>
                                                                                        <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                                            {{ type.name }}
                                                                                        </b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </template>
                                                                                <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                                            </b-input-group>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ValidationObserver>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab title="Charge">
                                                <div class="form-group radio-check-grp col-md-12">
                                                    <label class="fancy-radio mb-0">
                                                        <input type="radio" name="charges" v-bind:value="'charges'" @change="setBankDepositType('charges')">
                                                        <span><i></i>Charges</span>
                                                    </label>
                                                    <label class="fancy-radio mb-0">
                                                        <input type="radio" name="charges" v-bind:value="'free'" @change="setBankDepositType('free')"> 
                                                        <span><i></i>No Charge</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-12" v-if="chargeType === 'charges'">
                                                    <div class="form-group col-md-6">
                                                        <h6>Charge Value</h6>
                                                        <b-input-group class="mb-3">
                                                            <template #prepend>
                                                                <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                    <template #button-content>
                                                                        {{ transferDiscount.fbank_discount_type }}
                                                                    </template>
                                                                    <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                        {{ type.name }}
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </template>
                                                            <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary">Update</button>
                                        <button type="button" class="btn btn-danger" @click="$bvModal.hide('manage_payout_modal')">CLOSE</button>
                                    </div>
                                </b-modal>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import { ValidationObserver } from "vee-validate";
import Multiselect from 'vue-multiselect'
export default {
    name:'CountryDetailComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        Multiselect,
    },data (){
        return{
            contactDetail: {
                email: "",
                address: "",
                mobile_number: ""
            },

            bankDetail: {
                bank_name: "",
                bic: "",
                account_number: "",
                sort_code:"",
                iban:""
            },

            transferLimit: {
                fbank_min: "",
                fbank_max: "",
                ccollection_min: "",
                ccollection_max: "",
                mobile_money_min: "",
                mobile_money_max: ""
            },

            transferDiscount: {
                fbank_discount_amount: "",
                fbank_discount_index: 0,
                fbank_discount_type: '',
                ccollection_discount_amount: "",
                ccollection_discount_index: 1,
                ccollection_discount_type: "",
                mmoney_discount_amount: "",
                mmoney_discount_index: 0,
                mmoney_discount_type: "",
                discountType:[
                    {
                        name:'Flat'
                    },
                    {
                        name:'Percentage'
                    }
                ]
            },

            breadCrumbIteams:[
                {
                    title:'Country',
                    links:'/country/list'
                },
                {
                    title:'Ghana',
                    links:''
                }
            ],

            fundManage : {
                fundOptionType:[],
                setdata:[],
                minLimit:[],
                maxLimit:[],
                discount:[],
                discount_type: '',
                discount_index: 1,
                discountType:[
                    {
                        name:'Flat'
                    },
                    {
                        name:'Percentage'
                    }
                ]
            },           


            fundOptionitems:[
                {index:0, name:'Bank Deposit',value:'bankDeposit'},
                {index:1, name:'Mobile Money',value:'mobileMoney'},
                {index:2, name:'Cash Pickup',value:'cashPickup'}
            ],
            
            currencyName: [],
            allCurrency: [],
            supportedCurrency: [
                {'id':'8ee2b557-1047-49c4-bac2-ee7bf35e24e3', 'name':'GHS'},
                {'id':'00785522-5243-4856-b8d4-77bac7c31d70', 'name':'USD'}
            ],

            fields: [
                { key: 'name', label: 'Currency'},
                { key: 'action', label: 'Payout Options'},
                { key: 'paymentaction', label: 'Payment Options'}
            ],

            chargeType:'',

            showFundOption: [],
            showTransferTypeDiv : [],
            showLimitDiscountDiv: [],
            selectedIndex: []
        }
    },methods: {  
        showModal(payoutValue){
            console.log(payoutValue);
            this.$bvModal.show('manage_payout_modal')
        },

        selectIndex(e, fieldName,elementindex){
            // console.log(this.setdata[elementindex], elementindex);
            if(e.target.checked) {
                if(!this.showTransferTypeDiv.some(obj => obj.index == elementindex)) {
                    for(let i=0; i<elementindex; i++){
                        if(this.showTransferTypeDiv[i] === undefined){
                            this.showTransferTypeDiv.push({'fieldName': '', 'index': i})           
                        }
                    }
                    this.showTransferTypeDiv.push({'fieldName':fieldName, 'index': elementindex})
                } else {
                    this.showTransferTypeDiv[elementindex].fieldName = fieldName;
                    this.showTransferTypeDiv[elementindex].index = elementindex;
                }  
                // console.log('if',this.showTransferTypeDiv);
            } else {
                // alert(1);
                this.showTransferTypeDiv[elementindex].fieldName = '';
                this.showTransferTypeDiv[elementindex].index = elementindex;
                // console.log('else',this.showTransferTypeDiv);

                if(this.showLimitDiscountDiv[elementindex]){
                    this.fundManage.setdata.splice(elementindex,1);
                    this.showLimitDiscountDiv[elementindex].fieldName = '';
                    this.showLimitDiscountDiv[elementindex].index = elementindex;
                }
            }
        },

        setBankDepositType(type){
            this.chargeType = type;
            // alert(type);
            // this.fundManage.setdata[elementIndex] = type;
            // // console.log(this.fundManage.setdata);
            // if(!this.showLimitDiscountDiv.some(obj => obj.index == elementIndex)) {
            //     for(let i=0; i<elementIndex; i++){
            //         if(this.showLimitDiscountDiv[i] === undefined){
            //             this.showLimitDiscountDiv.push({'depositType': '', 'fieldName': '', 'index': i})           
            //         }
            //     }
            //     this.showLimitDiscountDiv.push({'depositType':type, 'fieldName':fieldName, 'index': elementIndex})
            // } else {
            //     this.showLimitDiscountDiv[elementIndex].depositType = type;
            //     this.showLimitDiscountDiv[elementIndex].fieldName = fieldName;
            //     this.showLimitDiscountDiv[elementIndex].index = elementIndex;
            // } 
        },

        selectCurrency(e, currencyName,elementindex){
            console.log(currencyName,elementindex);
            if(e.target.checked) {
                if(!this.showFundOption.some(obj => obj.index == elementindex)) {
                    for(let i=0; i<elementindex; i++){
                        if(this.showFundOption[i] === undefined){
                            this.showFundOption.push({'fieldName': '', 'index': i})           
                        }
                    }
                    this.showFundOption.push({'fieldName':currencyName, 'index': elementindex})
                } else {
                    this.showFundOption[elementindex].fieldName = currencyName;
                    this.showFundOption[elementindex].index = elementindex;
                }  
                console.log('if',this.showFundOption);
            } else {
                // alert(1);showFundOption
                this.showFundOption[elementindex].fieldName = '';
                this.showFundOption[elementindex].index = elementindex;
                console.log('else',this.showFundOption);
                // if(this.showTransferTypeDiv[elementindex]){
                //     this.showTransferTypeDiv[elementindex].fieldName = '';
                //     this.showTransferTypeDiv[elementindex].index = elementindex;
                // }   
                // if(this.showLimitDiscountDiv[elementindex]){
                //     this.fundManage.setdata.splice(elementindex,1);
                //     this.showLimitDiscountDiv[elementindex].fieldName = '';
                //     this.showLimitDiscountDiv[elementindex].index = elementindex;
                // }
            }
        },

        fundOptionSubmit(){
            // console.log(this.fundManage.fundOptionType);
            // console.log(this.fundManage.setdata);
            this.fundManage.fundOptionType.forEach((element,index) => {
                console.log(element, this.fundManage.setdata[index]);
            })
            // console.log(this.fundOptionType['charges'], this.fundOptionType['free'])
        },

        //set active prefix
        activefbanktype(menuIndex){
            this.transferDiscount.fbank_discount_index = menuIndex;
            this.transferDiscount.fbank_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activeccollection(menuIndex){
            this.transferDiscount.ccollection_discount_index = menuIndex;
            this.transferDiscount.ccollection_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activemmoney(menuIndex){
            this.transferDiscount.mmoney_discount_index = menuIndex;
            this.transferDiscount.mmoney_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activefund(menuIndex){
            this.fundManage.discount_index = menuIndex;
            this.fundManage.discount_type = this.fundManage.discountType[menuIndex].name;
        },

        async contactDetailData() {
            this.contactDetail.email = 'test@gmail.com',
            this.contactDetail.address = 'Demo, Test, Ghana, 123456',
            this.contactDetail.mobile_number = '0123456789'
        //   try {
        //       const response = await this.$http.get(this.$baseurl+"countries/source");
        //       // JSON responses are automatically parsed.
        //       response.data.map((item) => {
        //           let countryData = {
        //               id:item.id, 
        //               code: item.isoCode, 
        //               name: item.name, 
        //               flagClass:"flag-icon flag-icon-"+item.isoCode.toLowerCase()+" fi-xl fi-round",
        //               isChecked:false
        //           }
        //           this.countrys_options.push(countryData);
        //       })
        //   } catch (error) {
        //       console.log(error);
        //   }
        },

        contactDetailSubmit() {
            console.log("Submitting contact detail");
        },

        async bankDetailData() {
            this.bankDetail.bank_name = 'N/A',
            this.bankDetail.bic = '123457',
            this.bankDetail.account_number = '110123456789',
            this.bankDetail.sort_code = 'TEST123',
            this.bankDetail.iban = ''
        },
        bankDetailSubmit() {
            console.log("Submitting bank detail");
        },

        async transferLimitData() {
            this.transferLimit.fbank_min = "100",
            this.transferLimit.fbank_max = "1000",
            this.transferLimit.ccollection_min = "100",
            this.transferLimit.ccollection_max = "1001",
            this.transferLimit.mobile_money_min = "100",
            this.transferLimit.mobile_money_max = "1002"
        },
        transferLimitSubmit() {
            console.log("Submitting transfer limit");
        },

        async transferDiscountData() {
            this.transferDiscount.fbank_discount_amount= '10',
            this.transferDiscount.fbank_discount_type = 'Flat',
            this.transferDiscount.ccollection_discount_amount = '5',
            this.transferDiscount.ccollection_discount_type = 'Percentage',
            this.transferDiscount.mmoney_discount_amount = '50',
            this.transferDiscount.mmoney_discount_type = 'Flat'
        },
        transferDiscountSubmit() {
            console.log("Submitting transfer discount",this.transferDiscount.fbank_discount_type,this.transferDiscount.ccollection_discount_type,this.transferDiscount.mmoney_discount_type);
        },

        async getAllCurrency(){
            try {
              const response = await this.$http.get(this.$baseurl+"countries");
              // JSON responses are automatically parsed.
                response.data.map((item) => {
                    var countryIsocode = item.isoCode;//'';
                    // if(item.currency == 'USD') {
                    //     countryIsocode = 'us';
                    // } else if(item.currency == 'EUR') {
                    //     countryIsocode = 'fr';
                    // } else {
                    //     countryIsocode = item.isoCode;
                    // }
                    let currencyData = {
                        id:item.countryid,
                        flagClass: "flag-icon flag-icon-"+countryIsocode.toLowerCase(),
                        name: item.currencycode,
                        // currencyName: 'Test'
                    }
                    if(!this.allCurrency.some(currency => currency.name === item.currencycode)){
                        this.allCurrency.push(currencyData);
                    }
                })
            } catch (error) {
                console.log(error);
            }
        },

        supportedCurrencySubmit(){
            console.log('success',this.supportedCurrency)
        },

        async getSupportedCurrency(){
            try {
            //   const response = await this.$http.get(this.$baseurl+"countries/destination");
            //   // JSON responses are automatically parsed.
            //   console.log(response);
                // response.data.map((item) => {
                //     var countryIsocode = item.isoCode;//'';
                //     let currencyData = {
                //         flagClass: "flag-icon flag-icon-"+countryIsocode.toLowerCase(),
                //         name: item.currency,
                //         // currencyName: 'Test'
                //     }
                //     if(!this.allCurrency.some(currency => currency.name === item.currency)){
                //         this.allCurrency.push(currencyData);
                //     }
                // })
            } catch (error) {
                console.log(error);
            }
        }
    },mounted() {
        
    },computed: {
        
    },created() {
        this.contactDetailData();
        this.bankDetailData();
        this.transferLimitData();
        this.transferDiscountData();
        this.getAllCurrency();
        this.getSupportedCurrency();
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }
}
</script>
<style lang="sass">

</style>
