<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- All Acounts-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow">
                    <b-tabs class="account-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                        <!-- Account Tab -->
                        <b-tab title="Accounts" active>
                            <div class="header p-0 d-flex align-items-center justify-content-between">
                                <h2>All Acounts</h2>
                                <!-- Select Currencies/Pay or transfer/Add Account list/grid Buttons -->
                                <div class="btn-grp d-flex">
                                    <!-- <div class="country-dropdown curancy-dropdown mb-0">
                                        <div class="form-group form-select multiselect-grp mb-0">
                                            <span class="float-label">
                                                <label for="roles">Select Currencies</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="curancy" 
                                                    :show-labels="false" 
                                                    :options="currency_options" 
                                                    :searchable="false"
                                                    :multiple="true" 
                                                    :close-on-select="true"
                                                    :clear-on-select="false" 
                                                    label="name" 
                                                    track-by="name"  
                                                    :limit="0"
                                                    :limit-text="displayText"
                                                    @select="isCheckedInput"
                                                    @remove="isCheckedInput">
                                                    <template slot="beforeList">
                                                        <span class="multiselect__option" @click="selectAllChanged">
                                                            <div class="option__desc">
                                                                <span class="option__title">All</span>
                                                            </div>
                                                            <div class="multiselect__element multiselect-all multi-drop">
                                                                <div class="control-inline fancy-checkbox" for="isChecked">
                                                                    <input type="checkbox" id="checkbox2" name="checkbox2" :checked="isAllChecked">
                                                                    <span for="checkbox2"></span>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                        <div class="multiselect__element multiselect-all multi-drop">
                                                            <div class="control-inline fancy-checkbox" for="isChecked">
                                                                <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                                <span for="isChecked"></span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </multiselect>   
                                            </span>
                                        </div>
                                    </div> -->
                                    <!-- Pay or Transfer Button Component-->
                                    <!-- <pay-or-transfer-model-button></pay-or-transfer-model-button> -->
                                    <!-- Add Account Model Button-->
                                    <b-button size="lg" variant="outline-primary ml-3" v-b-modal.add_account><i class="fa fa-plus"></i></b-button>
                                    <b-modal id="add_account" title="Add Account" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
                                        <div class="modal-area">
                                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="add_accounts" @submit.prevent="passes(addAccount)">
                                                        <li>
                                                            <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !account.currency,'success':account.currency}">
                                                                    <span class="float-label">
                                                                        <label for="functions">Select Currency*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="account.currency"  
                                                                            :show-labels="false" 
                                                                            :options="currencyOptions" 
                                                                            :searchable="false"
                                                                            label="name" 
                                                                            track-by="name" >
                                                                            <template slot="option" slot-scope="props">
                                                                                <div class="option-title">
                                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                    <span>{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && !account.currency"></i>
                                                                        <i class="fa fa-check" v-if="account.currency"></i>
                                                                    </span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </li>
                                                        <li>   
                                                            <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                                                <div class="form-group" :class="{'errors': errors.length && !account.accountName || errors[0],'success':account.accountName && !errors[0]}">
                                                                    <span class="float-label">
                                                                        <input type="text" v-model="account.accountName" class="form-control" name="accountName" id="accountName" placeholder="Account Name*">
                                                                        <label for="accountName" class="control-label">Account Name*</label>
                                                                        <div class="line"></div>
                                                                        <i class="fa fa-times" v-if="errors.length && !account.accountName || errors[0] "></i>
                                                                        <i class="fa fa-check" v-if="account.accountName && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </li>
                                                        <li>
                                                            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                <b-button type="submit" variant="primary btn-mw" size="lg">Add Account</b-button>
                                                                <b-button @click="$bvModal.hide('add_account')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                            </div>
                                                        </li>
                                                    </form>
                                                </ValidationObserver>
                                            </ul>
                                        </div>
                                    </b-modal>
                                    <!--  list/grid Buttons -->
                                    <b-button class="ml-2" size="lg" :variant="activeTab == 'grid' ? 'primary' : 'outline-primary'" @click="isActiveTab('grid')"><i class="fa fa-th-large"></i></b-button>
                                    <b-button class="ml-2" size="lg" :variant="activeTab == 'list' ? 'primary' : 'outline-primary'"  @click="isActiveTab('list')"><i class="fa fa-bars"></i></b-button>
                                </div>
                            </div>
                            
                            <div class="body px-0" v-if="totalRecord < 1">
                                <div class="align-items-center body d-flex flex-column flex-md-row justify-content-between">
                                    <p class="font-18 mb-0 mb-5 mb-md-0 w-50">
                                        <strong class="d-block font-28">Welcome to StandApp</strong>
                                        <strong class="d-block font-24 mt-2">You Don't have any Data yet</strong>
                                        Create your first account to get Started
                                    </p>
                                    <img src="@/assets/not-data.svg" width="500" alt="" class="img-fluid">
                                </div>
                            </div>
                            
                            <div class="body px-0" v-if="totalRecord >= 1">
                                <!-- Grid View -->
                                <div class="row grid-view" :class="{'active show': activeTab == 'grid'}">
                                    <div class="col-lg-6" v-for="(account_card,index) in account_cards" :key="index">
                                        <b-card class="card-top-line">
                                            <div class="d-flex justify-content-between align-items-stretch">
                                                
                                                <div class="content">
                                                    <div class="text">
                                                        <h6 class="mb-0">
                                                            {{ account_card.name }} 
                                                            <i @click="editAccountModal(account_card)" class="fa fa-edit ml-1 text-primary cursor-pointer"></i>
                                                        </h6>
                                                        <small class="d-block text-muted">{{ account_card.currency }}</small>
                                                    </div>
                                                    <h4 class="number pt-4 mb-0">
                                                        {{ account_card.currency_symbol }} {{ account_card.balance }}
                                                    </h4>
                                                </div>
                                                <div class="img d-flex flex-column align-items-center">
                                                    <span :class="account_card.img_class"></span>
                                                    <b-button  :variant="account_card.isEnableColor" size="sm">{{ account_card.isEnableText }}</b-button>
                                                </div>
                                            </div>                        
                                        </b-card>
                                    </div>
                                </div>

                                <!-- List View / Table -->
                                <div class="row list-view" :class="{'active show': activeTab == 'list'}">
                                    <div class="col-12">
                                        <b-table 
                                            responsive
                                            hover 
                                            outlined
                                            table-class="js-basic-example table-custom mb-0"
                                            head-variant="light"
                                            :items="items" 
                                            :fields="fields"
                                            :current-page="currentPage"
                                            :per-page="perPage"
                                            :sort-by.sync="sortBy"
                                            :sort-desc.sync="sortDesc"
                                            :sort-direction="sortDirection">
                                            <!-- Custom icons -->
                                            <template #head()="{label,  field: { key }}">
                                                {{ label }}
                                                <template>
                                                    <i v-if="sortBy !== key" class=""></i>
                                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                </template>  
                                            </template>
                                            <template #cell(currency)="data">
                                                <div class="d-flex align-items-center">
                                                    <span :class="data.value.flagClass" class="mr-2"></span>
                                                    <span>{{ data.value.cname }}</span>
                                                </div>
                                            </template>
                                            <template #cell(action)="row">
                                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                    <b-dropdown-item @click="editAccountModal(row)">Edit</b-dropdown-item>
                                                </b-dropdown>
                                            </template>
                                        </b-table>

                                        <!-- Table Pagination -->
                                        <!-- <div class="py-3 align-items-center justify-content-between table-pagination" :class="items.length > 0?' d-flex':'d-none'" v-if="items.length > 10">
                                            <div class="per-page">
                                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                                </b-form-group>
                                            </div>
                                            <div class="number-page">
                                                <b-pagination 
                                                    v-model="currentPage" 
                                                    :total-rows="totalRows" 
                                                    :per-page="perPage"
                                                    hide-goto-end-buttons 
                                                    hide-ellipsis 
                                                    prev-text="Previous page" 
                                                    next-text="Next page" 
                                                    align="right">
                                                </b-pagination>
                                            </div>
                                            <div class="go-page">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Go To Page</span>
                                                    </div>
                                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>

                                <!-- <business-modal></business-modal> -->
                                
                            </div>
                        </b-tab>

                        <!-- Summary Tab -->
                        <b-tab title="Summary">
                            <p>Summary</p>
                        </b-tab>

                        <!-- Transaction Tab -->
                        <b-tab title="Transaction">
                            <p>Transaction</p>
                        </b-tab>
                    </b-tabs>
                    <!-- <edit-account-modal></edit-account-modal> -->

                    <b-modal id="edit_account" title="Edit Account" hide-footer>
                        <div class="modal-area">
                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                <ValidationObserver v-slot="{ passes }">
                                    <form  id="add_accounts" @submit.prevent="passes(updateAccountData)">
                                        <li>   
                                            <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                                <div class="form-group" :class="{'errors': errors.length && !editAccount.accountName || errors[0],'success':editAccount.accountName && !errors[0]}">
                                                    <span class="float-label">
                                                        <input type="text" v-model="editAccount.accountName" class="form-control" name="accountName" id="accountName" placeholder="Account Name*">
                                                        <label for="accountName" class="control-label">Account Name*</label>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="errors.length && !editAccount.accountName || errors[0] "></i>
                                                        <i class="fa fa-check" v-if="editAccount.accountName && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </ValidationProvider>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between align-items-center m-b-20">
                                                <p class="mb-0">Enable account</p>
                                                <div class="custom-switch success-error-bg">
                                                    <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="editAccount.enableAccount">
                                                    <label class="custom-switch-label" for="customSwitch1">
                                                        <span class="custom-switch-text1">Yes</span>
                                                        <span class="custom-switch-text2">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                <b-button type="submit" variant="primary btn-mw" size="lg">Save Changes</b-button>
                                                <b-button @click="$bvModal.hide('edit_account')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                            </div>
                                        </li>
                                    </form>
                                </ValidationObserver>
                            </ul>
                        </div>
                    </b-modal>


                </div>
            </div>
            <main-loader ref="loader"></main-loader>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
// const PayorTransferModel = require('@/components/core/PayorTransferModel.vue').default
const Multiselect = require('vue-multiselect').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";
const getSymbolFromCurrency = require('currency-symbol-map')
// const businessModal = require('@/components/admin/account/BusinessModal.vue').default
export default {
    name:'AccountComponent',
    components: {
        BreadCrumb,
        Multiselect,
        // 'pay-or-transfer-model-button': PayorTransferModel,
        ValidationObserver,
        ValidationProvider,
        'main-loader':LoaderComponent,
        // 'business-modal':businessModal
    },data (){
        return{
            activeTab: 'grid', //current active tab is grid

            //All Acounts grid cards json
            account_cards:[],

            businessList:[],

            //All Acounts list table json
            items:[],
           
            //All Acounts list table header fields
            fields: [
                { key: 'currency', label: 'CURRENCY',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'NAME',sortable: true,thClass:'th_sortfix'},
                { key: 'balance', label: 'BALANCE',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'ACTION', thStyle:'width:50px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

             //All Acounts list table json
            subsidiariesItems:[
                {currency:{flagClass:"flag-icon flag-icon-gh fi-xl fi-round",cname:'CRE'},name:'PayAngel Group',dopen:'30.12.2016',uuid:'',balance:'₵70,548.00',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-gb fi-xl fi-round",cname:'GBP'},name:'Thememakker Group',dopen:'17.01.2017',uuid:'',balance:'£50,630.50',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-us fi-xl fi-round",cname:'USD'},name:'Wrraptheme Group',dopen:'15.01.2017',uuid:'',balance:'$32,540.30',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-eu fi-xl fi-round",cname:'EUR'},name:'Angel community Group',dopen:'16.01.2017',uuid:'',balance:'€42,540.30',status:{selected:'accept',class:'btn-outline-success'}},
            ],
           
            //All Acounts list table header fields
            subsidiariesFields: [
                { key: 'currency', label: 'CURRENCY',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'NAME',sortable: true,thClass:'th_sortfix'},
                { key: 'dopen', label: 'DATE OPENED',sortable: true,thClass:'th_sortfix'},
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'balance', label: 'BALANCE',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'STATUS'},
                { key: 'action', label: 'ACTION'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Acounts list table status fields option
            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],
            
             //All Acounts list table status fields option
            subsidiariesOptions: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ], 

            //All Acounts list table pagination options
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [1,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            isAllChecked:false,
            subSortBy: '',
            subSortDesc: false,
            //All Acounts curancy dropdawn option
            // curancy: [{name: 'Ghanaian Cedi'},{name: 'British Pound'},{name: 'United States Dollar'},{name:'Euro'}],
            curancy: [],
            currency_options: [
                { id:0,name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round",isChecked: false},
                { id:1,name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round",isChecked: false},
                { id:2,name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round",isChecked: false},
                { id:3,name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked: false},
                { id:4,name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked: false},
                { id:5,name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round",isChecked: false},
                { id:6,name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round",isChecked: false},
                { id:7,name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round",isChecked: false},
            ],

            //Add Account Model currency dropdawn v-model Options
            account:{
                currency:null,
                accountName:''
            },

            business_id : '',
            wallet_id : '',
            editAccount:{
                accountName:'',
                enableAccount:''
            },

            //Add Account Model currency dropdawn Options
            currencyOptions: [],
            displayAlert: null,
            prevRoute: null,

            totalRecord:''
        }
    },methods: {
        //All Acounts list table status fields option on select change color/text 
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.status.class = classVar
                }
            });
        },

       subsidiariesStatus(event,index) {
          var classVar =''
            return this.subsidiariesItems.map((subitem,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    subitem.status.class = classVar
                }
            });
        },
        //check which tab is active(Grid/List)
        isActiveTab(button_title){
            this.activeTab = button_title;
        },

        //check Single Transfer form Account Number field is empty
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                } else {
                   this.currentPage = paginationMenu;
                }
            } else {
                this.currentPage = 1;
            }
        },

        //All Acounts Currencies dropdawn selection message is no of Currencies selected
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.curancy[0].name
            }else if(count == this.currency_options.length){
                this.isAllChecked = true;
                return count + ' Currencies Selected.'
              
            }else {
                this.isAllChecked = false;
                return  count + ' Currencies Selected.'
            }
        },

        //Currencies dropdawn checkbox checked(one or more)
        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.curancy = [];
                this.currency_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.curancy = this.currency_options.slice();
                this.currency_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        //all Currencies dropdawn checkbox checked
        isCheckedInput (actionName) {
            this.currency_options[actionName.id].isChecked = !this.currency_options[actionName.id].isChecked
        },

        resetForm(){
            Object.keys(this.account).forEach(v => this.account[v] = '')
        },

        //add account form submit
        addAccount(){
            this.$refs.loader.show();
            let accountData = {
                businessid: sessionStorage.getItem("businessId"),
                name: this.account.accountName,
                currencycode: this.account.currency.currency
            }
            // console.log(accountData);
            this.$http.post(this.$baseurl+'wallet/business', accountData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0100-000') {
                    this.$bvModal.hide('add_account');
                    if(sessionStorage.getItem("businessId")){
                        this.getBusinessWiseWallet(sessionStorage.getItem("businessId"))
                    } else {
                        this.getAllWallet();
                    }
                    this.$refs.loader.hide();
                    shared.toastrSuccess('Your account successfully added');
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(res.message);
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        },

        async getAllWallet(){
            this.$refs.loader.show();
            try {
                const response = await this.$http.get(this.$baseurl+"wallet/business",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response.data);
                this.account_cards = [];
                this.items = [];
                if(response.data.code == '0100-000'){
                    this.totalRecord = response.data.details.length;
                    response.data.details.map((item) => {
                        let accountData = {
                            businessid : item.businessid,
                            walletid : item.walletid,
                            img_class : "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                            name : item.name,
                            isEnabled : item.enabled,
                            isEnableText: 'Enabled',
                            isEnableColor : item.enabled ? 'primary  mt-auto' : 'secondary mt-auto',
                            currency : item.currencycode,//'Ghanaian Cedi',
                            currency_symbol : getSymbolFromCurrency(item.currencycode),
                            balance: parseFloat(item.availablebalance).toFixed(2) //'70,548.00'
                        }
                        this.account_cards.push(accountData);

                        let listviewData = {
                            businessid : item.businessid,
                            walletid : item.walletid,
                            currency:{flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",cname:item.currencycode},
                            name:item.name,
                            balance:getSymbolFromCurrency(item.currencycode) + parseFloat(item.availablebalance).toFixed(2),
                            isEnabled : item.enabled
                        }
                        this.items.push(listviewData);
                    })
                    this.$refs.loader.hide();
                }
            } catch (error) {
                this.$refs.loader.hide();
                console.log(error);
            }
        },

        async getBusinessWiseWallet(){
            // alert(businessId);
            const businessId = sessionStorage.getItem("businessId");
            if(businessId){
                try {
                    const response = await this.$http.get(this.$baseurl+"wallet/business/"+businessId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    });
                    // JSON responses are automatically parsed.
                    // console.log(response.data);
                    this.account_cards = [];
                    this.items = [];
                    if(response.data.code == '0100-000'){
                        this.totalRecord = response.data.details.length;
                        response.data.details.map((item) => {
                            let accountData = {
                                businessid : item.businessid,
                                walletid : item.walletid,
                                img_class : "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                name : item.name,
                                isEnabled : item.enabled,
                                isEnableText: 'Enabled',
                                isEnableColor : item.enabled ? 'primary  mt-auto' : 'secondary mt-auto', 
                                currency : item.currencycode,//'Ghanaian Cedi',
                                currency_symbol : getSymbolFromCurrency(item.currencycode),
                                balance: parseFloat(item.availablebalance).toFixed(2) //'70,548.00'
                            }
                            this.account_cards.push(accountData);

                            let listviewData = {
                                businessid : item.businessid,
                                walletid : item.walletid,
                                currency:{flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",cname:item.currencycode},
                                name:item.name,
                                balance:getSymbolFromCurrency(item.currencycode) + parseFloat(item.availablebalance).toFixed(2),
                                isEnabled : item.enabled
                            }
                            this.items.push(listviewData);
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                return [];
            }
            
        },

        async getAllCurrency(){
            try {
                let paramData = {
                    sort : 'asc',
                    sortby : 'id',
                    size : '2000',
                    page : '1'
                }
                const response = await this.$http.get(this.$baseurl+"currencies",{
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response.data);
                
                if(response.data.code == '0003-100'){
                    response.data.details.map((item) => {
                        let currencyData = {
                            name : item.name,
                            flagClass : "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                            currency:item.currencycode
                        }
                        this.currencyOptions.push(currencyData);
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },

        editAccountModal(row){
            // console.log(row);
            if(row.item){
                this.business_id = row.item.businessid;
                this.wallet_id = row.item.walletid;
                this.editAccount.accountName = row.item.name;
                this.editAccount.enableAccount = row.item.isEnabled;
            } else {
                this.business_id = row.businessid;
                this.wallet_id = row.walletid;
                this.editAccount.accountName = row.name;
                this.editAccount.enableAccount = row.isEnabled;
            }            
            this.$bvModal.show('edit_account');
        },

        updateAccountData(){
            this.$refs.loader.show();
            let updateaccountData = {
                businessid: this.business_id,
                name: this.editAccount.accountName,
                enabled: this.editAccount.enableAccount
            }
            // console.log(updateaccountData);
            this.$http.put(this.$baseurl+'wallet/business/'+this.wallet_id, updateaccountData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0100-000') {
                    this.$bvModal.hide('edit_account');
                    if(sessionStorage.getItem("businessId")){
                        this.getBusinessWiseWallet(sessionStorage.getItem("businessId"))
                    } else {
                        this.getAllWallet();
                    }
                    this.$refs.loader.hide();
                    shared.toastrSuccess('Your account successfully updated');
                } else {
                    this.$refs.loader.hide();
                    shared.toastrError(res.message);
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        }

    },mounted() {
        //list table total rows
        this.totalRows = this.items.length;
        this.getAllCurrency();
        this.$root.$refs.AccountComponent = this;
        if(sessionStorage.getItem("businessId")){
            this.getBusinessWiseWallet()
        } else {
            this.getAllWallet();
        }
    },computed: {
        //check how many Currencies dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.curancy.length === this.currency_options.length)
        }
    },beforeRouteEnter(to, from, next) {
        next(that => {
            that.prevRoute = from.name
            if(that.prevRoute == 'slogin' || that.prevRoute == 'slinkbusiness'){
                const swalWithBootstrapButtons = that.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-primary btn-lg my-3',
                        cancelButton: 'btn btn-outline-secondary btn-lg'
                    },
                    buttonsStyling: false
                })
               
                swalWithBootstrapButtons.fire({
                    title: "Setup your profile",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Setup now',
                    cancelButtonText: 'Skip for later',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        sessionStorage.setItem('setupProfile','yes');
                        that.$root.$refs.BusinessModal.showmodal('Select Your Business');
                        // that.$router.push({ path: '/setting/setup-profile' })
                    } else {
                        that.$root.$refs.BusinessModal.showmodal('Select Your Business');
                        // that.$bvModal.show('business_list_modal')
                    }
                })  
            }
        })
    },
}
</script>