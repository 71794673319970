<template>
    <b-modal id="business_list_modal" title="Add Primary Contact" hide-footer centered scrollable no-stacking no-close-on-backdrop header-class="align-items-center">
        <template #modal-header>
            <h5 class="modal-title">{{pageTitle}}</h5>
            <!-- <b-button pill variant="primary" v-b-modal.headre_add_business><i class="fa fa-plus"></i> Add</b-button> -->
        </template>
        <div class="modal-area">
            <ul class="list-unstyled mb-0 transfers-list switch-account-list">
                <template v-for="(busines,index) in businesses">
                    <li class="d-flex align-items-center cursor-pointer" :class="{'active': busines.isActive}" :key="index" @click="setBusinessid(busines)">
                        <!-- v-on:click="onSelect(index)" -->
                        <div class="left">
                            <i aria-hidden="true" class="fa" v-if="!busines.img">{{ busines.title | short_hand }}</i>
                            <img class="fa" :src="busines.img" v-if="busines.img"/>
                        </div>
                        <div class="right">
                            <strong class="font-16 text-uppercase">{{ busines.title }}  </strong>
                            <p>{{busines.countryName}}</p>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </b-modal>
</template>
<script>
export default {
    name:'BusinessModal',
    data (){
        return{
            businesses:[],
            pageTitle:'',

            // businesses:[
            //     {
            //         id:'',
            //         img: '',
            //         title:'PAYINC GROUP LIMITED',
            //         subTitle:'Business',
            //         isActive: false
            //     },
            //     {
            //         id:'',
            //         img: '',
            //         title:'GUDEND STRATEGIES LIMITED',
            //         subTitle:'Business',
            //         isActive: true
            //     },
            //     {
            //         id:'',
            //         img: '',
            //         title:'PAYANGEL FINANCIAL SOLUTIONS',
            //         subTitle:'Business',
            //         isActive: false
            //     },
            //     {
            //         id:'',
            //         img: '',
            //         title:'PAYINC TECHNOLOGIES LIMITED',
            //         subTitle:'Business',
            //         isActive: false
            //     }
            // ]
        }
    },methods: {
        async getBusinessList (){
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '2000',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let businessLists = {
                        id : item.businessid,
                        countryid: item.countryofresidence,
                        isocode: item.isocode,
                        img: '',
                        title: item.name,
                        countryName: item.countryname,
                        isActive: false
                    };
                    this.businesses.push(businessLists);
                });    
            } catch (error) {
                console.log(error);
            }
        },

        showmodal(modalTitle){
            this.pageTitle = modalTitle;
            if(this.businesses.length > 1){
                this.$bvModal.show('business_list_modal')
            } else {
                this.businesses.map((item) => {
                    sessionStorage.setItem("businessId",item.id);
                    sessionStorage.setItem("countryId",item.countryid);
                    sessionStorage.setItem("isoCode",item.isocode);

                });
            }
        },

        setBusinessid(businessData){
            sessionStorage.setItem("businessId",businessData.id);
            sessionStorage.setItem("countryId",businessData.countryid);
            sessionStorage.setItem("isoCode",businessData.isocode);
            this.businesses.forEach(function (business) {
                if (business.id === businessData.id) {
                    business.isActive = true
                } else {
                    business.isActive = false
                }
            });
            
            if(sessionStorage.getItem('setupProfile')  == 'yes'){
                sessionStorage.removeItem('setupProfile');
                this.$router.push({ path: '/setting/setup-profile' })
            } else {
                if(this.$route.path == '/account'){
                    this.$root.$refs.AccountComponent.getBusinessWiseWallet(businessData.id);
                } else if(this.$route.path == '/fund'){
                    this.$root.$refs.FundComponent.refreshFundTable();
                }
            }
            this.$bvModal.hide('business_list_modal')
        }
    },mounted() {
        this.getBusinessList();
        this.$root.$refs.BusinessModal = this;
    },filters: {
        short_hand (companyName) {
            let words = companyName.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    }
}
</script>