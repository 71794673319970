<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- All Acounts-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow">
                    <b-tabs nav-class="nav-tabs-new2" :no-nav-style="true">
                        <!-- List Tab -->
                        <b-tab title="List" active>
                           <ComplianceListComponent></ComplianceListComponent>
                        </b-tab>

                        <!-- Requirements Tab -->
                        <b-tab title="Requirements">
                            <ComRequirementsComponent></ComRequirementsComponent>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const ComplianceListComponent = require('@/components/admin/setting/ComplianceListComponent').default
const ComRequirementsComponent = require('@/components/admin/setting/ComRequirementsComponent').default
export default {
    name:'ComplianceComponent',
    components: {
        BreadCrumb,
        ComRequirementsComponent,
        ComplianceListComponent
    }
}
</script>
