<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 card-top-line">
                    <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true">

                        <!-- Summary Tab -->
                        <!-- Business List Tab -->
                        <b-tab title="Business List" active>

                            <!-- Header section-->
                            <!-- <div class="header">
                                <h2>All Businesses</h2>
                            </div> -->
                            <div class="body pt-0">
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="singleBusinessDatas.searchText" placeholder="Search Business" aria-label="Search Invoices" aria-describedby="search-business" v-on:keyup.enter="searchData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-invoices"><i class="icon-magnifier"  @click="searchData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <b-button variant="primary btn-mw" size="lg" @click="showSingleForm()">Add Business <i class="fa fa-plus-square ml-1"></i></b-button>
                                        </div>
                                    </div>
                                </div>
                           
                            <b-modal id="add_single" title="Add Single Business" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
                                <ul class="list-unstyled mb-0 payment-single-transfer">
                                    <ValidationObserver v-slot="{ passes }">
                                        <form @submit.prevent="passes(singleBusinessForm)" id="single-form">
                                            <li>
                                                <ValidationProvider name="select_country" rules="required" v-slot="{ errors }">
                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':singleBusinessDatas.country && singleBusinessDatas.country.length != 0  && !errors[0]}">
                                                        <span class="float-label">
                                                        <label for="Country">Select Country* </label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="singleBusinessDatas.country"   
                                                                :show-labels="false" 
                                                                :options="countrysOptions"
                                                                :searchable="false"
                                                                label="name" 
                                                                track-by="name" 
                                                                @select="onSelect($event,'single')">
                                                                <template slot="option" slot-scope="props">
                                                                    <div class="option-title">
                                                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                                                        <span>{{ props.option.name }}</span>
                                                                    </div>
                                                                </template>
                                                            </multiselect>
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.country && singleBusinessDatas.country.length != 0 && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>

                                            <li>

                                                <ValidationProvider name="businessName" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.businessName || errorArr[0] && errorArr[0].fieldName!='','success':singleBusinessDatas.businessName && errorArr[0] && errorArr[0].fieldName=='','mb-0':apiErrorCode == 'E0096-005'}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="singleBusinessDatas.businessName" class="form-control" id="businessName" placeholder="Business Name*" @input="checkNameFormat($event, 'businessName', 'Business Name', 'single',0)">
                                                            <label for="businessName" class="control-label">Business Name* </label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.businessName || errorArr[0] && errorArr[0].fieldName!=''"></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.businessName && errorArr[0] && errorArr[0].fieldName==''"></i>
                                                        </span>
                                                        <span class="text-danger error-msg" v-if="errorArr[0] && errorArr[0].fieldName!=''">{{errorArr[0].errorMessage}}</span>
                                                    </div>
                                                </ValidationProvider>
                                                <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0096-005'">{{apiErrorMsg}}</span> 
                                            </li>
                                            <li>
                                                <ValidationProvider name="ownerFirstName" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.ownerFirstName || errorArr[1] && errorArr[1].fieldName!='','success':singleBusinessDatas.ownerFirstName && errorArr[1] && errorArr[1].fieldName==''}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="singleBusinessDatas.ownerFirstName" class="form-control" id="ownerFirstName" placeholder="Superadmin First Name*" @input="checkNameFormat($event, 'ownerFirstName', 'Superadmin First Name', 'single', 1)">
                                                            <label for="owner" class="control-label">Superadmin First Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.ownerFirstName || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.ownerFirstName && errorArr[1] && errorArr[1].fieldName==''"></i>
                                                        </span>
                                                        <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="ownerLastName" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.ownerLastName || errorArr[2] && errorArr[2].fieldName!='','success':singleBusinessDatas.ownerLastName && errorArr[2] && errorArr[2].fieldName==''}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="singleBusinessDatas.ownerLastName" class="form-control" id="ownerLastName" placeholder="Superadmin Last Name*" @input="checkNameFormat($event, 'ownerLastName', 'Superadmin Last Name', 'single', 2)">
                                                            <label for="owner" class="control-label">Superadmin Last Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.ownerLastName || errorArr[2] && errorArr[2].fieldName!=''"></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.ownerLastName && errorArr[2] && errorArr[2].fieldName==''"></i>
                                                        </span>
                                                        <span class="text-danger error-msg" v-if="errorArr[2] && errorArr[2].fieldName!=''">{{errorArr[2].errorMessage}}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="businessEmail" rules="required|email" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.businessEmail || errors[0],'success':singleBusinessDatas.businessEmail && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="singleBusinessDatas.businessEmail" class="form-control" name="email1" id="businessEmail" placeholder="Email Address*">
                                                            <label for="businessEmail" class="control-label">Email Address*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.businessEmail || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.businessEmail && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>

                                                <div class="form-group" :class="{'errors': mobileInputError == false,'success':singleBusinessDatas.phoneNumber && mobileInputError}">
                                                    <div class="float-label">
                                                        <label for="primary_number" class="control-label">Contact Mobile Number*</label>
                                                        <vue-tel-input
                                                            v-model="singleBusinessDatas.phoneNumber"
                                                            v-bind="primaryMobileNumber"
                                                            @validate ="mobileInputValidate"
                                                            ref="telInput"
                                                            @keypress.native="onlyNumber($event)">
                                                            <template v-slot:arrow-icon>
                                                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                            </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                        <i class="fa fa-check" v-if="mobileInputError && singleBusinessDatas.phoneNumber"></i>
                                                    </div>
                                                </div>
                                                <!-- <div class="d-flex code-mobile">
                                                    <div class="mr-2 left">
                                                        <span class="float-label">
                                                            <b-input-group >
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text bg-transparent"><span :class="singleBusinessDatas.countryflag"></span></span>
                                                                </div>
                                                                <input type="text" class="form-control" name="countryCode" v-model="singleBusinessDatas.countryCode" disabled>
                                                            </b-input-group>
                                                        </span>
                                                    </div>
                                                    <div class="ml-2 right">
                                                        <ValidationProvider name="phoneNumber" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.phoneNumber,'success':singleBusinessDatas.phoneNumber}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="singleBusinessDatas.phoneNumber" class="form-control" id="phoneNumber" placeholder="Phone Number*">
                                                                    <label for="phoneNumber" class="control-label">Phone Number*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.phoneNumber"></i>
                                                                    <i class="fa fa-check" v-if="singleBusinessDatas.phoneNumber"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div> -->
                                                
                                            </li>
                                            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                <b-button type="submit" variant="primary btn-mw" size="lg">Add Single Business</b-button>
                                                <b-button @click="$bvModal.hide('add_single')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </ul>
                            </b-modal>

                            <!-- <div class="body px-0"> -->
                                <!-- table datas-->
                                <b-table 
                                    ref = "table"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getBusinessList" 
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(isapproved)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                        </p>
                                    </template>
                                    <template #cell(uuid)="data">
                                        <b-button 
                                            id="button1" 
                                            variant="outline-primary" 
                                            size="sm" 
                                            v-b-tooltip.hover
                                            :data-original-title="data.value.businessid"
                                            :title="data.value.businessid" 
                                            @click.prevent="copyTooltip($event)">
                                            <i class="fa fa-2x fa-barcode"></i>
                                        </b-button>
                                    </template>
                                    <template #cell(businessname)="row">
                                        <!-- <router-link class="font-color font-weight-bold" to="/business/details">{{ row.value }} <i class="fa fa-link text-theme"></i></router-link> -->
                                        <a class=" cursor-pointer font-color font-weight-bold" @click="setBusiness(row)">{{ row.value }}<i class="fa fa-link text-theme"></i></a>
                                    </template>
                                    <!-- <template #cell(action)>
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item>Review</b-dropdown-item>
                                            <b-dropdown-item>Freeze</b-dropdown-item>
                                            <b-dropdown-item>Disable</b-dropdown-item>
                                            <b-dropdown-item>Reject</b-dropdown-item>
                                            <b-dropdown-item>Approve</b-dropdown-item>
                                        </b-dropdown>
                                    </template> -->
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'" v-if="totalRows > 10">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="currentPage" 
                                            :total-rows="totalRows" 
                                            :per-page="perPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page  </span>
                                            </div>
                                            <input type="text" v-model="currentPageJump" class="form-control"  @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->
                            </div>
                        </b-tab>

                    </b-tabs>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
        <!-- <business-model></business-model> -->
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
//const AddBusinessModel = require('@/components/core/AddBusinessModel.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')

const Multiselect = require('vue-multiselect').default
const { VueTelInput } = require('vue-tel-input')
import parsePhoneNumber from 'libphonenumber-js'
import shared from "@/shared.js";


export default {
    name:'BusinessComponent',
    components: {
        BreadCrumb,
        //'business-model':AddBusinessModel,
        'main-loader':LoaderComponent,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data (){
        return{
            // Single Business
            singleBusinessDatas:{
                businessName:'',
                ownerFirstName:'',
                ownerLastName:'',
                businessEmail:'',
                phoneNumber:'',
                country:null,
                countryCode:'',
                countryflag:'',
                searchText: ''
            },
            mobileInputError:'',
            primaryMobileNumber:{},
            countrysOptions: [],
            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            apiErrorCode: '',
            
            errorArr : [],


            //All Acounts list table json
            items:[],
           
            //All Acounts list table header fields
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'businessname', label: 'Business Name',sortable: true,thClass:'th_sortfix'},
                { key: 'countryname', label: 'Country Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchandid', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'registrationDate', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                //{ key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'account_type', label: 'Account Type',sortable: true,thClass:'th_sortfix'},
                { key: 'isapproved', label: 'Status',sortable: true,thClass:'th_sortfix'},
               // { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Acounts list table pagination options
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            isBusy: false,
            breadCrumbIteams:[
                {
                    title:'Business',
                    links:''
                }
            ]
        }
    },methods: {
        async getCountryDataByCountryId(countryId) {
            try {
                this.primaryMobileNumber = {};
                const response = await this.$http.get(this.$baseurl+"countries/"+countryId);
                let countryData = {
                            id:         response.data.id, 
                            isocode:    response.data.isoCode, 
                            code:       response.data.dialCode, 
                            name:       response.data.name, 
                            flagClass:  "flag-icon flag-icon-"+response.data.isocode.toLowerCase()+" fi-xl fi-round",
                            isChecked:true
                        }                
               this.singleBusinessDatas.country = countryData;
               this.primaryMobileNumber = {
                mode: "auto",
                defaultCountry:response.data.isoCode,
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            };
               //this.subBusinessPrimaryMobileNumber.defaultCountry = response.data.isoCode;
                
            } catch (error) {
                console.log(error);
            }
        },



        searchData() {
            this.refreshTable();
        },
        // Single Business Methods
        resetForm(){
            // clear single business form fields
            Object.keys(this.singleBusinessDatas).forEach(v => this.singleBusinessDatas[v] = '')
            this.getCountryDataByCountryId(sessionStorage.getItem('countryId'));           
            this.fieldErrorMsg = '';
            this.apiErrorMsg = '';
            this.apiErrorCode = '';
            this.fieldName = '';
            this.errorArr = [];
            
        },
        checkNameFormat(e, fieldName, elementName, form, elementIndex) {
            if(form==='single') {
                this.formType = this.singleBusinessDatas;
            } else {
               this.formType = this.subBisnessData; 
            }
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                var splitStr = e.target.value.split(' ');
                for (var i = 0; i < splitStr.length; i++) {
                    if(splitStr[0]!='')
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
            this.formType[fieldName] = splitStr.join(' ');
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = '';
                        this.errorArr[elementIndex].errorMessage = '';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                    return true; // Match with regex 

                } else {
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = fieldName;
                        this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                }
            } else {
                this.formType[fieldName] = char;
                this.fieldErrorMsg = 'Please provide valid value for '+elementName;
                if(!this.errorArr.some(obj => obj.index == elementIndex)) {
                    for(let i=0; i<elementIndex; i++) {
                        if(this.errorArr[i] === undefined){
                            this.errorArr.push({'fieldName': '', errorMessage:'', 'index': i})                        
                        }
                        //if(this.errorArr[elementIndex].fieldName == '')
                    }
                    this.errorArr.push({'fieldName': fieldName, errorMessage:this.fieldErrorMsg, 'index': elementIndex})
                } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr[elementIndex].fieldName =  fieldName;
                    this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
                    this.errorArr[elementIndex].index = elementIndex;
                } else {
                    // do nothing 
                }
               
                e.preventDefault(); // If not match, don't add to input text
            }
        },
        //single business submit form data
        singleBusinessForm(){
            try {
                this.$refs.loader.show();
                if(typeof(this.mobileInputError) == 'undefined'){
                    this.mobileInputError = false
                }
                if(this.mobileInputError) {
                    const phoneNumber = parsePhoneNumber(this.singleBusinessDatas.phoneNumber, this.singleBusinessDatas.country.isocode);
                    //this.$bvModal.hide('add_single');
                    let buisnessData = {
                        country: this.singleBusinessDatas.country.isocode,
                        email: this.singleBusinessDatas.businessEmail,
                        phone: phoneNumber.number,
                        businessname: this.singleBusinessDatas.businessName,
                        ownerfirstname: this.singleBusinessDatas.ownerFirstName,
                        ownerlastname: this.singleBusinessDatas.ownerLastName
                    };
                    
                    this.$http.post(this.$baseurl+"business/singlebusiness/create", buisnessData,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((response) => {
                        if(response.data.code == '0096-000') {
                            this.$refs.loader.hide();
                            shared.toastrSuccess(response.data.message);
                            this.$bvModal.hide('add_single');
                            //this.getBusinessList();
                            this.refreshTable();
                            // shared.toastrSuccess(res.data.message);
                            // this.getComplianceListData();
                            // this.$bvModal.hide('business_category');
                            // this.apiErrorMsg = '';
                            // this.apiErrorCode = '';
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(response.data.message);                            
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        this.apiErrorMsg = error.response.data.message;
                        this.$refs.loader.hide();
                        //shared.toastrError(error.response.data.message);                            
                    });
                }
            }catch(error) {
                this.$refs.loader.hide();
                shared.toastrError(error);
            }
        },
        async getCountryData() {
                try {
                    this.$refs.loader.show();
                    const response = await this.$http.get(this.$baseurl+"countries/available");
                    if(response.data.code === '0003-000'){ 
                        response.data.details.map((item) => {
                                let countryData = {
                                    id:item.countryid, 
                                    isocode: item.isocode, 
                                    code: item.mobilecountrycode, 
                                    name: item.countryname, 
                                    flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                    isChecked:false
                                }
                            this.countrysOptions.push(countryData);
                        })
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);
                    }
                    // JSON responses are automatically parsed.
                } catch (error) {
                    console.log(error);
                    this.$refs.loader.hide();
                    shared.toastrError(error);
                }
            }, 
        onSelect(value, formType) {
            if(formType === 'single') {
                this.singleBusinessDatas.countryCode = value.code; //("+ "+"("+value.code+")")
                this.singleBusinessDatas.countryflag = value.flagClass
                this.$refs.telInput.choose(value.isocode);
            } else {
                this.subBisnessData.countryCode = value.code; //("+ "+"("+value.code+")")
                this.subBisnessData.countryflag = value.flagClass
                this.$refs.telInput.choose(value.isocode);
            }
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        onlyNumber (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },
        refreshTable() {
            this.$refs.table.refresh();
        },
        
        showSingleForm() {
            this.$bvModal.show('add_single');
        },  
        // Single Business Methods

        // getSelectedItem(arg) {
        //     this.perPage = arg;
        //     this.getBusinessList();
        // },

        setBusiness(row) {
            sessionStorage.setItem('businessIdFromBusinessList', row.item.uuid.businessid);
            sessionStorage.setItem('countryId', row.item.country);
            sessionStorage.setItem('businessName', row.item.businessname);
            this.$router.push({path:'/business/details'});
        },
        //check Single Transfer form Account Number field is empty
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },
            async getBusinessList(ctx) {
                try{
                    this.$refs.loader.show();
                    let currentpage  = '';
                    let perpage = '';
                    let sortby = '';
                    let sortdesc = false;
                    if(ctx.currentPage != undefined) {
                        currentpage =  ctx.currentPage;
                    } else {
                        currentpage = 1;
                    }
                    if(ctx.perPage != undefined) {
                        perpage = ctx.perPage;
                    } else {
                        perpage = 10;
                    }
                    if(ctx.sortBy == '') {
                        sortby = 'createdat'
                    } else {
                        sortby = ctx.sortBy;
                    }
                    if(ctx.sortDesc == false) {
                        sortdesc = 'DESC'
                    } else {
                        sortdesc = 'ASC';
                    }
                   
                    
                    this.currentPageJump = currentpage;
                    this.items = [];
                    const response = await this.$http.get(this.$baseurl+'business/list?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&search='+this.singleBusinessDatas.searchText,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                            },
                        });
                        if(response.data.code == '0075-000'){
                            response.data.details.forEach(element => {
                                // Set country Id in session storage based on business selected
                                if(element.businessid == sessionStorage.getItem("businessId")) {
                                    sessionStorage.setItem('countryId', element.countryofresidence);
                                    this.getCountryDataByCountryId(sessionStorage.getItem('countryId'));           
                                }
                                let statusObj = {};
                                // element.status==0?
                                // (statusObj.name ='Pending', statusObj.class= 'badge-danger')
                                // :(statusObj.name ='Active', statusObj.class= 'badge-success')
                                if(element.status == 0) {
                                    statusObj.name ='Pending';
                                    statusObj.class= 'badge-danger';

                                } else if(element.status == 1) {
                                    statusObj.name ='Approved';
                                    statusObj.class= 'badge-success';

                                } else if(element.status == 2) {
                                    statusObj.name ='Rejected';
                                    statusObj.class= 'badge-warning';

                                } else if (element.status == 3) {
                                    statusObj.name ='Freezed';
                                    statusObj.class= 'badge-danger';

                                } else if(element.status == 4) {
                                    statusObj.name ='Suspended';
                                    statusObj.class= 'badge-danger';
                                } else if(element.status == 5) {
                                    statusObj.name ='Suspended';
                                    statusObj.class= 'badge-danger';
                               
                                } else {
                                    statusObj.name ='Pending';
                                    statusObj.class= 'badge-danger';
                                }



                                let businessItem = {
                                    uuid:{businessid:element.businessid},
                                    businessname:element.name,
                                    countryname:element.countryname,
                                    country:element.countryofresidence,
                                    merchandid: element.merchandid,
                                    registrationDate:element.registrationdate==null?'-':element.registrationdate,
                                    owner: '',
                                    ltd_to:element.annualturnover==null?'-':element.annualturnover,
                                    account_type:element.categoryname,
                                    isapproved:statusObj,
                                    action:''
                            };
                            this.items.push(businessItem);
                        })
                            this.totalRows = response.data.query.total; 
                            this.$refs.loader.hide();
                            return this.items;

                        }
                } catch(error) {
                    return [];
                }
                


        }
    },
    watch: {
        mobileNumber(val) {
            this.singleBusinessDatas.phoneNumber = val.replace(/\W/g, "");
        },
    },
    mounted() {
        if(sessionStorage.getItem("jwtToken")){
            //this.getBusinessList();
           // this.totalRows = this.items.length
           this.getCountryData();
            this.$root.$refs.BusinessComponent = this;
            
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
    },computed: {
        
    }
}

</script>
<style lang="sass">

</style>
