import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast)

export const toastFunction = {
    showSuccess: function(message) {
        Vue.$toast.open({
            message: '<h6 class="text-success">Success Message</h6>'+message,
            type: "success",
            duration: 3000,
            position: 'bottom-right',
            dismissible: true
        })
    }
};