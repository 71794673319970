<template>
  <div class="container-fluid">
    <div class="block-header">
      <bread-crumb></bread-crumb>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-top-line">
          <div class="header">
            <h2>SMS</h2>
          </div>
          <div class="body pt-0">
            <div class="chart-top-action d-lg-flex justify-content-between py-3">
              <div class="d-md-flex justify-content-between">
                  <div class="mr-0 mr-md-3 action-top-search">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search sms" aria-label="Search sms" aria-describedby="search-sms" v-model="searchText" v-on:keyup.enter="refreshSmsTable">
                          <div class="input-group-append" @click="refreshSmsTable()">
                              <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                          </div>
                      </div>
                  </div>
                  <div class="fliter-main mt-3 mt-md-0">
                      <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                      <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                          <div class="d-flex justify-content-between p-3">
                              <div class="form-group form-select single-multiselect">
                                  <span class="float-label">
                                      <multiselect
                                          placeholder="Select Filter Type"
                                          v-model="filterBy"  
                                          :show-labels="false" 
                                          :options="filterOption" 
                                          label="name"
                                          :searchable="true">
                                      </multiselect> 
                                  </span>
                                  <div class="line"></div>
                              </div>
                              <div class="form-group form-select single-multiselect">
                                  <span class="float-label">
                                      <multiselect
                                          placeholder="Select Filter Type"
                                          v-model="condition"  
                                          :show-labels="false" 
                                          :options="conditionList" 
                                          label="name"
                                          :searchable="true">
                                      </multiselect> 
                                  </span>
                                  <div class="line"></div>
                              </div>
                              <div class="form-group">
                                  <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                  <div class="line"></div>
                              </div>
                              <div class="form-group">
                                  <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshSmsTable()">Apply</button>
                              </div>
                              <div class="form-group">
                                  <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                              </div>
                          </div>
                      </b-collapse>
                  </div>
              </div>
            </div>
            <b-table
              ref="smsTable"
              responsive
              hover
              table-class="js-basic-example table-custom mb-0 border"
              head-variant="light"
              :items="getSmsData"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #head()="{label,  field: { key }}">
                {{ label }}
                <!-- Custom icons -->
                <template>
                  <i v-if="sortBy !== key" class=""></i>
                  <i
                    v-else-if="sortDesc"
                    class="fa fa-long-arrow-down ml-1"
                  ></i>
                  <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </template>
              </template>
              <template #cell(action)="row">
                <b-dropdown
                  id="dropdown-1"
                  text="Actions"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item @click="addCredit(row)">Add Credit</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Table Pagination -->
            <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'" v-if="totalRows > 0">
              <div class="per-page">
                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </div>
              <div class="number-page">
                <b-pagination 
                  v-model="currentPage" 
                  :total-rows="totalRows" 
                  :per-page="perPage"
                  hide-goto-end-buttons 
                  hide-ellipsis 
                  prev-text="Previous page" 
                  next-text="Next page" 
                  align="right">
                </b-pagination>
              </div>
              <div class="go-page">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Go To Page</span>
                  </div>
                  <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-loader ref="loader"></main-loader>
    <add-credit></add-credit>
  </div>
</template>

<script>
const BreadCrumb = require("@/components/BreadCrumb.vue").default;
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import shared from "@/shared.js";
import Multiselect from 'vue-multiselect'

const AddCredit = require("@/components/admin/sms/AddCreditComponent.vue").default;

export default {
  name: "PaymentOptionComponent",
  components: {
    BreadCrumb,
    "main-loader": LoaderComponent,
    "add-credit": AddCredit,
    Multiselect
  },
  data() {
    return {
      isVisibleFilter: false,

      paymentOptionId:'',
      modalTitle: '',

      items: [
        // {businessname:'BusinessItem',availablebalance:'10',action:''},
        // {businessname:'Test Business',availablebalance:'15',action:''},
        // {businessname:'DemoAdmin',availablebalance:'50',action:''}
      ],

      fields: [
        { key: "businessname", label: "Business Name", sortable: true, thClass: "th_sortfix" },
        { key: "availablebalance", label: "Available Balance", sortable: true, thClass: "th_sortfix" },
        { key: "lastcreditdate", label: "Last Credit Date", sortable: true, thClass: "th_sortfix" },
        { key: "action", label: "Action" },
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],

      filterOption: [
        { value: 'businessname', name:'Business Name' },
        { value: 'amount', name:'Available Balance' },
        { value: 'status', name:'Status' },
        { value: 'createdat', name:'Created At'},
        { value: 'createdby', name:'Created By' },
      ],

      conditionList: [
        { value: '==', name:'equals to' },
        { value: '>=', name:'greater than or equals to' },
        { value: '>', name:'greater than' },
        { value: '<', name:'less than' },
        { value: '<=', name:'less than or equals to' },
        { value: 'like', name:'like' }
      ],

      totalRows: 0,
      currentPage: 1,
      currentPageJump: 1,
      perPage: 10,
      pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchText: '',
      filterBy: '',
      filterValue: '',
      condition: '',

      errorArr : [],

      fieldName : '',
      fieldErrorMsg : '',
      apiErrorMsg : '',
      elementName : '',
      apiErrorCode: '',
    };
  },
  methods: {
    addCredit() {
    //   this.paymentOptionId = '';
      this.$bvModal.show("add_credit_modal");
    },

    async getPaymentOptionData() {
      try {
        const response = await this.$http.get(this.$baseurl + "payment/options/name",{
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
          },
        });
        // JSON responses are automatically parsed.
        // console.log(response);
        this.items = [];
        if (response.data.code == "0100-100") {
          response.data.details.map((item) => {
            let lists = {
              id: item.id,
              name: item.name,
              action: "",
            };
            this.items.push(lists);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    
    refreshSmsTable() {
      this.$refs.smsTable.refresh();
    },

    resetFilterValue(){
      this.filterBy= '',
      this.filterValue= '',
      this.condition= '',
      this.refreshSmsTable();
    },

    async getSmsData(ctx) {
      try {
        let page  = '';
        let size = '';
        let sortby = '';
        let sort = false;
        if(ctx.currentPage != undefined) {
            page =  ctx.currentPage;
        } else {
            page = 1;
        }
        if(ctx.perPage != undefined) {
            size = ctx.perPage;
        } else {
            size = 10;
        }
        if(ctx.sortBy == '') {
            sortby = 'createdat'
        } else {
            sortby = ctx.sortBy;
        }
        if(ctx.sortDesc == false) {
            sort = 'desc'
        } else {
            sort = 'asc';
        }
          
        this.currentPageJump = page;
        this.items = [];
        let paramData = {
            sort : sort,
            sortby : sortby,
            size : size,
            page : page,
            search : this.searchText,
            filterby : this.filterBy ? this.filterBy.value : '',
            filtervalue : this.filterValue,
            condition : this.condition ? this.condition.value : '=='
        }
        // console.log(paramData);
        const response = await this.$http.get(this.$baseurl+"middleware/sms/checkbalance", { 
            params: paramData,
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
            }
        });
        // JSON responses are automatically parsed.
        // console.log(response);
        response.data.details.map((item) => {
          let smsLists = {
            businessname:item.businessname,
            availablebalance:item.amount,
            lastcreditdate:item.lastcreditdate,
            action:''
          };
          this.items.push(smsLists);
        }); 
        this.totalRows = this.items.length;//response.data.query.total;
        return this.items;               
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("jwtToken")) {
      // this.totalRows = this.items.length;
    //   this.getPaymentOptionData();
    } else {
      shared.toastrError("Please login to continue");
      this.$router.push({ path: "/standapp-login" });
    }
  },
};
</script>
