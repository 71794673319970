<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recent transactions -->
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header d-flex align-items-center justify-content-between">
                        <h2>Country List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search country" aria-label="Search country" aria-describedby="search-country">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- Cancel/Request Approval button-->
                            <!-- <div class="btn-grp d-flex">
                                <b-button variant="outline-primary mr-1" size="lg">Cancel</b-button>
                                <b-button variant="primary" size="lg">Request Approval</b-button>
                            </div> -->
                        </div>
                        <b-table 
                            fixed
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 risk-table"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                            <template #cell(uuid)>
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="rendomNumber()"
                                    :title="rendomNumber()" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Active</b-dropdown-item>
                                    <b-dropdown-item>Inactive</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template #cell(details)="data">
                                <router-link class="font-color font-weight-bold" :to="'/country/details/' + data.value.id">
                                    <div class="align-items-center d-flex">
                                        <span :class="data.value.flagClass" class="mr-2"></span>
                                        <span>{{ data.value.name }}</span>
                                    </div>
                                </router-link>
                            </template>
                            <template #cell(source)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(destination)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(outbound)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template> 
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import BreadCrumb from '@/components/BreadCrumb'
  import TransactionsTopRow from '@/components/core/TransactionsTopRow.vue'

  export default  {
    name: 'country-component',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow
    },data (){
        return{
            isVisibleFilter: false,

            //Recent transactions table data
            items:[
                {
                    uuid:'',
                    details: {id:'1', name:'Ghana',flagClass: "flag-icon flag-icon-gh fi-md fi-round"}, 
                    isocode:'GH',
                    isocode3:'GHA',
                    currency:'GHS',
                    numeric:'288',
                    dialcode:'233',
                    source:{id:'customSourceSwitch1',isChecked:true},
                    destination:{id:'customDestSwitch1',isChecked:false},
                    outbound:{id:'customSwitch1',isChecked:false},
                    action:''
                },
                {
                    uuid:'',
                    details: {id:'2',name:'Canada',flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                    isocode:'CA',
                    isocode3:'CAN',
                    currency:'CAD',
                    numeric:'124',
                    dialcode:'1',
                    source:{id:'customSourceSwitch2',isChecked:true},
                    destination:{id:'customDestSwitch2',isChecked:true},
                    outbound:{id:'customSwitch2',isChecked:false},
                    action:''
                },
                {
                    uuid:'',
                    details: {id:'3',name:'United Kingdom',flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                    isocode:'GB',
                    isocode3:'GBR',
                    currency:'GBP',
                    numeric:'826',
                    dialcode:'44',
                    source:{id:'customSourceSwitch3',isChecked:false},
                    destination:{id:'customDestSwitch3',isChecked:true},
                    outbound:{id:'customSwitch3',isChecked:true},
                    action:''
                },
                {
                    uuid:'',
                    details: {id:'4',name:'France',flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                    isocode:'FR',
                    isocode3:'FRA',
                    currency:'EUR',
                    numeric:'250',
                    dialcode:'33',
                    source:{id:'customSourceSwitch4',isChecked:true},
                    destination:{id:'customDestSwitch4',isChecked:false},
                    outbound:{id:'customSwitch4',isChecked:false},
                    action:''
                },
                {
                    uuid:'',
                    details: {id:'5',name:'United States',flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                    isocode:'US',
                    isocode3:'USA',
                    currency:'USD',
                    numeric:'840',
                    dialcode:'1',
                    source:{id:'customSwitch5',isChecked:false},
                    destination:{id:'customSwitch5',isChecked:true},
                    outbound:{id:'customSwitch5',isChecked:false},
                    action:''
                }
            ],
           
            //Recent transactions table header
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'details', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'isocode', label: 'Alpha-2'},
                { key: 'isocode3', label: 'Alpha-3'},
                { key: 'numeric', label: 'Numeric' },
                { key: 'dialcode', label: 'Dialling Code' },
                { key: 'currency', label: 'Currency' },
                { key: 'source', label: 'Domestic'},
                { key: 'outbound', label: 'Cross Border'},
                { key: 'destination', label: 'Destination'},
                { key: 'action', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Recent transactions table pagination
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //Summary data
            transactions_toprows:[
                {
                    icon:'fa fa-globe',
                    text:'Total Countries',
                    price:'220',
                    progress_class:'bg-primary',
                    progressbar_width:'87',
                    // compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-map-o',
                    text:'Total Domestic Countries',
                    price:'100',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    // compared_text:'19% compared to last month'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Total Cross border Countries',
                    price:'70',
                    progress_class:'bg-warning',
                    progressbar_width:'20',
                    // compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Total Destination Countries',
                    price:'3200',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    // compared_text:'19% compared to last year'
                }
            ],
        }
    },methods: {
        //Recent transactions table go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },

        async getCountryData() {
            try {
                let paramData = {
                    sort : 'desc',
                    sortby : 'createdat',
                    size : '2000',
                    page : '1'
                }
                const response = await this.$http.get(this.$baseurl+"countries/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                console.log(response);
                // response.data.details.map((item) => {
                //     let businessLists = {
                //         id : item.businessid,
                //         name: item.name
                //     };
                //     this.businessOptions.push(businessLists);
                // });                
            } catch (error) {
                console.log(error);
            }
        }
    },mounted() {
        this.totalRows = this.items.length;
        this.getCountryData();
    }
  }
</script>

<style>
/* .flag-align{
    bottom: -4px;
} */
</style>
