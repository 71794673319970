<template>
    <div class="add-business">
        <!-- Add payment option -->
        <b-modal id="add_payment_option" title="Add Payment Option" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(addPaymentOptionForm)" id="single-form">
                        <li>
                            <ValidationProvider name="name" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !addPayment.name || errorArr[1] && errorArr[1].fieldName!='','success':addPayment.name && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="addPayment.name" class="form-control" id="name" placeholder="Add Payment Option Name*">
                                        <!--  @input="checkNameFormat($event, 'name', 'Owner First Name', 'single', 1)" -->
                                        <label for="owner" class="control-label">Add Payment Option Name</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !addPayment.name || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                        <i class="fa fa-check" v-if="addPayment.name && errorArr[1] && errorArr[1].fieldName==''"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Payment Option</b-button>
                            <b-button @click="$bvModal.hide('add_payment_option')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import Multiselect from 'vue-multiselect'

export default {
    name: 'AddPaymentOptionModal',
    props: {
        buttonClass: String
    },components: {
        ValidationObserver,
        ValidationProvider,
        // Multiselect
    },data(){
        return{
            addPayment:{
                name:''
            },
            selectedBusinessType: '',
            mobileInputError:'',
            
            errorArr : [],

            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            elementName : '',
            apiErrorCode: '',
            formType: ''
        }
    },methods:{
        resetForm(){
            // clear single business form fields
            Object.keys(this.addPayment).forEach(v => this.addPayment[v] = '')
        },

        //add fund submit form data
        addPaymentOptionForm(){
            console.log('add Payment')
        }

    }
}
</script>
