<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- User Management -->
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>User Management</h2>
                    </div>
                    <!-- User Management List -->
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- Add new user Buttons -->
                            <div class="mt-2 mt-sm-0">
                                <router-link to="/setting/user-add" class="btn btn-primary btn-lg btn-mw">Add User <i class="fa fa-plus-square ml-1"></i></router-link>
                            </div>
                        </div>
                        <!-- Table -->
                        <b-table 
                            responsive
                            outlined
                            table-class="mb-0 table-custom"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(details)="data">
                                <div class="media-object flag-img mr-2">
                                    <img :src="data.value.user_img" alt="" width="50px" class="rounded-circle">
                                    <span :class="data.value.flagClass"></span>
                                </div>
                                <span class="user-name">{{ data.value.name }}</span>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template #cell(function)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0 badge-success" v-for="(functions,index) in data.value" :key="index">{{ functions }}</span>
                                </p>
                            </template>
                            <template #cell(role)="data">
                                <p class="role-badge badge-group-table mb-0">
                                    <span class="badge ml-0 badge-info" v-for="(roles,index) in data.value" :key="index">{{ roles }}</span>
                                </p>
                            </template>
                            <template #cell(services)="data">
                                <p class="services-badge badge-group-table mb-0">
                                    <span class="badge ml-0 badge-default" v-for="(services,index) in data.value" :key="index">{{ services }}</span>
                                </p>
                            </template>
                            <template #cell(type)="data">
                                <p class="access-badge badge-group-table mb-0">
                                    <span class="badge ml-0 badge-danger" v-for="(access,index) in data.value" :key="index">{{ access }}</span>
                                </p>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const TransactionsTopRow = require('@/components/core/TransactionsTopRow.vue').default
import '@/plugins/sweetalert2'
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";

export default {
    name:'UserListComponent',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow,
        'main-loader':LoaderComponent,
    },
    methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
        
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        async getUserList(){
            // this.$refs.loader.show();
            try {
                let paramData = {
                    sort : 'asc',
                    sortby : 'id',
                    size : '20',
                    page : '1'
                }
                const response = await this.$http.get(this.$baseurl+"internal/user/list",{
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                console.log(response);
                
                
            } catch (error) {
                console.log(error);
            }
        }
    },data(){
        return{
            isVisibleFilter: false,
            items:[
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                    bname:'Themmakker',
                    function:['ADMIN'],
                    role:['ADMIN','MANAGER'],
                    services:['DASHBOARD'],
                    type:['READ'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                    bname:'Wrraptheme',
                    function:['ADMIN','FINANCE','TELLER'],
                    role:['OWNER','ADMIN','MANAGER','USER','TELLER'],
                    services:['DASHBOARD','USER MANAGEMENT'],
                    type:['READ','WRITE','EDIT','FULL'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar3.jpg`),flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                    bname:'Themmakker',
                    function:['ADMIN'],
                    role:['OWNER','MANAGER'],
                    services:['TRANSACTIONS'],
                    type:['EDIT'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-eu fi-md fi-round"},
                    bname:'Themmakker',
                    function:['SALES','HR'],
                    role:['USER'],
                    services:['DASHBOARD'],
                    type:['WRITE'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar2.jpg`),flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                    bname:'Wrraptheme',
                    function:['HR'],
                    role:['MANAGER'],
                    services:['DASHBOARD','USER MANAGEMENT'],
                    type:['READ'],
                    action:''
                },
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-ru fi-md fi-round"},
                    bname:'Themmakker',
                    function:['SALES','HR'],
                    role:['USER'],
                    services:['DASHBOARD'],
                    type:['WRITE'],
                    action:''
                },
            ],
           
            fields: [
                { key: 'details', label: 'Name',sortable: true,thClass:'user-name-img th_sortfix'},
                { key: 'bname', label: 'Business Name',sortable: true,thClass:'business-name th_sortfix'},
                { key: 'function', label: 'Function',sortable: true,thClass:'function-badge th_sortfix'},
                { key: 'role', label: 'Role',sortable: true,thClass:'role-badge th_sortfix'},
                { key: 'services', label: 'Services',sortable: true,thClass:'services-badge th_sortfix'},
                { key: 'type', label: 'Access Type',sortable: true,thClass:'access-badge th_sortfix'},
                { key: 'action', label: 'Action',thClass:'access-badge th_sortfix'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //Summary data
            transactions_toprows:[
                {
                    icon:'fa fa-user-plus',
                    text:'New Members(today)',
                    price:'220',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-calendar',
                    text:'New This Month',
                    price:'100',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last month'
                },
                {
                    icon:'fa-users',
                    text:'Total Member',
                    price:'3200',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Member Location',
                    price:'3000',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last year'
                }
            ],
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.totalRows = this.items.length;
            this.getUserList();
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>