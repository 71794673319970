import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

export const sweetAlertFunction = {
    displayAlertPopUp: function(data,confirmAction) {
        
        Vue.swal.fire({
            title: data.title,
            text: data.text,
            icon: data.icon,
            showCancelButton: data.showCancelButton,
            confirmButtonText: data.confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                confirmAction.loader.show();
                setTimeout(() => {
                    confirmAction.loader.hide();
                    confirmAction.toastMessage.showSuccess("Please do some stuff !");
                }, 2000);
            }
        })
    }
};
