<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Basic Business Information</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="general-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(businessInfoData)" id="business-info">
                        <ValidationProvider name="residence_country" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessInfo.residenceCountrys && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessInfo.readonlyMode}">
                                    <label for="Country">Select {{ businessInfo.businessTypeTitle }} Country of Residence*</label>
                                        <multiselect
                                            :disabled="businessInfo.readonlyMode"
                                            placeholder=""
                                            v-model="businessInfo.residenceCountrys" 
                                            :show-labels="false" 
                                            :options="residence_countrys" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template slot="option" slot-scope="props">
                                                <span :class="props.option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessInfo.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessInfo.residenceCountrys && !errors[0] && !businessInfo.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="business_type" rules="required" v-slot="{ errors }">
                            <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':businessInfo.businessType && !errors[0]}">
                                <span class="float-label" :class="{'multiselect--disabled':businessInfo.readonlyMode}">
                                <label for="functions">Select {{ businessInfo.businessTypeTitle }} Type*</label>
                                    <multiselect 
                                        :disabled="businessInfo.readonlyMode"
                                        placeholder=""
                                        v-model="businessInfo.businessType" 
                                        :options="businesstype_options" 
                                        :show-labels="false"
                                        label="businessTypeTitle" 
                                        track-by="businessTypeTitle"
                                        @input="onChangeBusiness">
                                        <template slot="option" slot-scope="props">
                                            <span :class="{'text-info':props.option.businessType == 'organisation'}">{{ props.option.businessTypeTitle }}</span>
                                        </template>
                                    </multiselect>
                                    <i class="fa fa-times" v-if="errors.length && errors[0] && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.businessType && !errors[0] && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Other" rules="required" v-slot="{ errors}" v-if="businessInfo.showOtherInput">
                            <div class="form-group" :class="{'errors': errors.length && !businessInfo.otherBusinessType,'success':businessInfo.otherBusinessType}">
                                <span class="float-label">
                                    <input type="text" v-model="businessInfo.otherBusinessType" class="form-control" id="other_type" placeholder="Other*" :disabled="businessInfo.readonlyMode">
                                    <label for="other_type" class="control-label">Other*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessInfo.otherBusinessType && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.otherBusinessType && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="business-organisation" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !businessInfo.businessOrganisation,'success':businessInfo.businessOrganisation}">
                                <span class="float-label">
                                    <input type="text" v-model="businessInfo.businessOrganisation" class="form-control" id="businessOrganisation" :placeholder="businessInfo.businessTypeTitle+ 'Name*'" :disabled="businessInfo.readonlyMode">
                                    <label for="businessOrganisation" class="control-label">{{ businessInfo.businessTypeTitle }} Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessInfo.businessOrganisation && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.businessOrganisation && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessInfo.readonlyMode}" v-if="!businessInfo.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Has your {{ businessInfo.businessTypeTitle }} ever changed its name? <span class="text-danger">*</span></p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="changed_BusinessName" v-model="businessInfo.changedBusinessName" :disabled="businessInfo.readonlyMode">
                                <label class="custom-switch-label" for="changed_BusinessName">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="Previous Business Name" :rules="businessInfo.changedBusinessName ? 'required' : ''" v-slot="{ errors}" v-if="businessInfo.changedBusinessName && !businessInfo.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessInfo.previousBusinessName,'success':businessInfo.previousBusinessName}">
                                <span class="float-label">
                                    <input type="text" v-model="businessInfo.previousBusinessName" class="form-control" id="previous_business" :placeholder="'Previous'+ businessInfo.businessTypeTitle + 'Name*'" :disabled="businessInfo.readonlyMode">
                                    <label for="previous_business" class="control-label">Previous {{ businessInfo.businessTypeTitle }} Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessInfo.previousBusinessName && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.previousBusinessName && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="registerBusinessDate" rules="required" v-slot="{ errors }" v-if="!businessInfo.readonlyMode">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':businessInfo.registerBusinessDate && !errors[0]}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':businessInfo.readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Register {{ businessInfo.businessTypeTitle }} Date</span>
                                            <span class="input-group-text" @click="$refs.register.showCalendar()"><i class="icon-calendar"></i></span>
                                        </div>
                                        <datepicker class="form-control"  ref="register" :bootstrap-styling="false" placeholder="" v-model="businessInfo.registerBusinessDate" :disabled="businessInfo.readonlyMode" :typeable="true"></datepicker>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors.length && errors[0] && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.registerBusinessDate && !errors[0] && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="startTrading" rules="required" v-slot="{ errors }" v-if="!businessInfo.readonlyMode">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':businessInfo.startTrading && !errors[0]}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':businessInfo.readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Start Trading</span>
                                            <span class="input-group-text" @click="$refs.start_trade.showCalendar()"><i class="icon-calendar"></i></span>
                                        </div>
                                        <datepicker class="form-control" ref="start_trade" :bootstrap-styling="false" placeholder="" v-model="businessInfo.startTrading" :disabled="businessInfo.readonlyMode" :typeable="true"></datepicker>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors.length && errors[0] && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.startTrading && !errors[0] && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessInfo.readonlyMode}" v-if="!businessInfo.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Does your {{ businessInfo.businessTypeTitle }} trade under a name different from the one above? <span class="text-danger">*</span></p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="displaytradingName" v-model="businessInfo.displaytradingName" :disabled="businessInfo.readonlyMode">
                                <label class="custom-switch-label" for="displaytradingName">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="trading_name" :rules="businessInfo.displaytradingName ? 'required' : ''" v-slot="{ errors}" v-if="businessInfo.displaytradingName && !businessInfo.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessInfo.tradingName || errors[0],'success':businessInfo.tradingName && !errors[0]}">
                                <span class="float-label">
                                    <input type="text" v-model="businessInfo.tradingName" class="form-control" id="trading_name" placeholder="Trading Name*" :disabled="businessInfo.readonlyMode">
                                    <label for="trading_name" class="control-label">Trading Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessInfo.tradingName || errors[0] && !businessInfo.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessInfo.tradingName && !errors[0] && !businessInfo.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div v-if="!businessInfo.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="businessInfoData()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const Datepicker = require('vuejs-datepicker').default
import "@/plugins/vee-validate";

export default {
    name: 'BasicBusinessInfo-Profile',
    components:{
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        Datepicker,
    },
    props: {
        businessType: {
            type: String,
            default: function () {
                return 'Business'
            }
        },
    },
    data(){
        return{
            businessInfo: {
                readonlyMode: true,
                businessInfoIsSubmit:false,
                businessTypeTitle: 'Business',
                showOtherInput:false,
                residenceCountrys: [{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                businessType: [{id: 1,businessType: "business",businessTypeTitle:'Registered Sole Proprietorship'}],
                otherBusinessType:'',
                businessOrganisation:'PayAngel',
                changedBusinessName:false,
                previousBusinessName:'Thememakker',
                registerBusinessDate:"2018-02-17",
                startTrading:'2019-03-15',
                displaytradingName:false,
                tradingName:'PayAngel.com'
            },

            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            businesstype_options:[
                {
                    id: 1,
                    businessType: "business",
                    businessTypeTitle:'Registered Sole Proprietorship',
                },
                {
                    id: 2,
                    businessType: "business",
                    businessTypeTitle:'Unregistered Solver Proprietorship',
                },
                {
                    id: 3,
                    businessType: "business",
                    businessTypeTitle:'Private Limited Liability Company',
                },
                {
                    id: 4,
                    businessType: "business",
                    businessTypeTitle:'Public Limited Company',
                },
                {
                    id: 5,
                    businessType: "business",
                    businessTypeTitle:'Limited Liability Partnership',
                },
                {
                    id: 6,
                    businessType: "business",
                    businessTypeTitle:'Unlimited Liability Company',
                },
                {
                    id: 7,
                    businessType: "organisation",
                    businessTypeTitle:'Alumni Association',
                },
                {
                    id: 8,
                    businessType: "organisation",
                    businessTypeTitle:'Club or Association',
                },
                {
                    id: 9,
                    businessType: "organisation",
                    businessTypeTitle:'Registered Charity',
                },
                {
                    id: 10,
                    businessType: "organisation",
                    businessTypeTitle:'Unregistered Charity',
                },
                {
                    id: 11,
                    businessType: "organisation",
                    businessTypeTitle:'Religious Organisation',
                },
                {
                    id: 12,
                    businessType: "organisation",
                    businessTypeTitle:'Membership Organisation',
                },
                {
                    id: 12,
                    businessType: "business",
                    businessTypeTitle:'Branch or External Company',
                },
                {
                    id: 13,
                    businessType: "business",
                    businessTypeTitle:'Government Institutions',
                },
                {
                    id: 14,
                    businessType: "business",
                    businessTypeTitle:'Trust Funds',
                },
                {
                    id: 15,
                    businessType: "other",
                    businessTypeTitle:'Other – Please Specify',
                },
            ],

            countrys_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

        }
    },methods:{
        editDetails(){
            this.businessInfo.readonlyMode = false
        },

        //submit General Information
        businessInfoData(){
            if(this.businessInfo.businessOrganisation == ""){
                this.businessInfo.businessOrganisation = 'PayAngel'
            }else if(this.businessInfo.previousBusinessName == ""){
                this.businessInfo.previousBusinessName = 'Thememakker'
            }else if(this.businessInfo.registerBusinessDate == ""){
                this.businessInfo.registerBusinessDate = '2018-02-17'
            }else if(this.businessInfo.startTrading == ""){
                this.businessInfo.startTrading = '2019-03-15'
            }else if(this.businessInfo.tradingName == ""){
                this.businessInfo.tradingName = 'PayAngel.com'
            }
            this.businessInfo.readonlyMode = true
        },

        onChangeBusiness (businesstypeOptions) {
            if(businesstypeOptions.businessType == 'business'){
                this.businessInfo.businessTypeTitle = 'Business'
            }else{
                this.businessInfo.businessTypeTitle = 'Organisation'
            }

            if(businesstypeOptions.businessType == 'other'){
                this.businessInfo.showOtherInput = true
            }else {
                this.businessInfo.showOtherInput = false
            }
            this.$emit('onBusinessTypeChange',this.businessInfo.businessTypeTitle)
        },
    }
}
</script>
