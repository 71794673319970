var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-top-line"},[_c('div',{staticClass:"header d-flex align-items-center justify-content-between"},[_c('h2',[_vm._v("Controllers of your Business")]),_c('i',{staticClass:"fa fa-edit font-20",on:{"click":function($event){return _vm.editDetails()}}})]),(!_vm.readonlyMode)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"account-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{attrs:{"id":"basic-info"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.UBODataSubmit)}}},[_vm._l((_vm.UBODatas),function(ubodata,index){return _c('div',{key:index,staticClass:"form-UBO"},[_c('h6',{staticClass:"mb-3 font-15"},[_c('i',{staticClass:"fa fa-level-up fa-rotate-90"}),_vm._v(" "),_c('strong',[_vm._v("Ultimate Beneficial Owner (UBO)")])]),_c('ValidationProvider',{attrs:{"name":'firstName_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.first_name,'success':ubodata.first_name}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.first_name),expression:"ubodata.first_name"}],staticClass:"form-control",attrs:{"type":"text","id":'firstName_'+index,"placeholder":"First Name*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "first_name", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'firstName_'+index}},[_vm._v("First Name*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.first_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.first_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":'middleName_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.middle_name,'success':ubodata.middle_name}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.middle_name),expression:"ubodata.middle_name"}],staticClass:"form-control",attrs:{"type":"text","id":'middleName_'+index,"placeholder":"Middle Name*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.middle_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "middle_name", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'middleName_'+index}},[_vm._v("Middle Name*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.middle_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.middle_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":'lastName_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.last_name,'success':ubodata.last_name}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.last_name),expression:"ubodata.last_name"}],staticClass:"form-control",attrs:{"type":"text","id":'lastName_'+index,"placeholder":"Last Name*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "last_name", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'lastName_'+index}},[_vm._v("Last Name*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.last_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.last_name && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":'ownership_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.ownership,'success':ubodata.ownership}},[_c('span',{staticClass:"float-label"},[_c('b-input-group',{class:{'ownership-disable':_vm.readonlyMode},attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"text","id":'ownership_'+index,"placeholder":"Ownership*","disabled":_vm.readonlyMode},model:{value:(ubodata.ownership),callback:function ($$v) {_vm.$set(ubodata, "ownership", $$v)},expression:"ubodata.ownership"}}),(errors.length && !ubodata.ownership && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.ownership && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1),_c('label',{staticClass:"control-label",attrs:{"for":'ownership_'+index}},[_vm._v("Ownership*")]),_c('div',{staticClass:"line"})],1)])]}}],null,true)}),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'occupation_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.occupation,'success':ubodata.occupation}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.occupation),expression:"ubodata.occupation"}],staticClass:"form-control",attrs:{"type":"text","id":'occupation_'+index,"placeholder":"Occupation*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.occupation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "occupation", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'occupation_'+index}},[_vm._v("Occupation*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.occupation && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.occupation && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'cob_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"country-dropdown"},[_c('div',{staticClass:"form-group form-select multiselect-grp",class:{'errors': errors[0],'success':ubodata.countryofBirth && !errors[0]}},[_c('span',{staticClass:"float-label",class:{'multiselect--disabled':_vm.readonlyMode}},[_c('label',{attrs:{"for":"Country"}},[_vm._v("Country of Birth*")]),_c('multiselect',{attrs:{"disabled":_vm.readonlyMode,"placeholder":"","show-labels":false,"options":_vm.countryofBirth_options,"searchable":false,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{class:props.option.flagClass}),_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}}],null,true),model:{value:(ubodata.countryofBirth),callback:function ($$v) {_vm.$set(ubodata, "countryofBirth", $$v)},expression:"ubodata.countryofBirth"}}),(errors.length && errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.countryofBirth && !errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'nationality_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"country-dropdown"},[_c('div',{staticClass:"form-group form-select multiselect-grp",class:{'errors': errors[0],'success':ubodata.nationality && !errors[0]}},[_c('span',{staticClass:"float-label",class:{'multiselect--disabled':_vm.readonlyMode}},[_c('label',{attrs:{"for":"Country"}},[_vm._v("Nationality*")]),_c('multiselect',{attrs:{"disabled":_vm.readonlyMode,"placeholder":"","show-labels":false,"options":_vm.nationality_countrys,"searchable":false,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{class:props.option.flagClass}),_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}}],null,true),model:{value:(ubodata.nationality),callback:function ($$v) {_vm.$set(ubodata, "nationality", $$v)},expression:"ubodata.nationality"}}),(errors.length && errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.nationality && !errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":'residenceCountry_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"country-dropdown"},[_c('div',{staticClass:"form-group form-select multiselect-grp",class:{'errors': errors[0],'success':ubodata.country_of_residence && !errors[0]}},[_c('span',{staticClass:"float-label",class:{'multiselect--disabled':_vm.readonlyMode}},[_c('label',{attrs:{"for":"Country"}},[_vm._v("Country of Residence*")]),_c('multiselect',{attrs:{"disabled":_vm.readonlyMode,"placeholder":"","show-labels":false,"options":_vm.residence_countrys,"searchable":false,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{class:props.option.flagClass}),_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}}],null,true),model:{value:(ubodata.country_of_residence),callback:function ($$v) {_vm.$set(ubodata, "country_of_residence", $$v)},expression:"ubodata.country_of_residence"}}),(errors.length && errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.country_of_residence && !errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])])]}}],null,true)}),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'address1_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.address1,'success':ubodata.address1}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.address1),expression:"ubodata.address1"}],staticClass:"form-control",attrs:{"type":"text","id":'address1_'+index,"placeholder":"Address Line 1*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.address1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "address1", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'address1_'+index}},[_vm._v("Address Line 1*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.address1 && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.address1 && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.address2),expression:"ubodata.address2"}],staticClass:"form-control",attrs:{"type":"text","id":"address2","placeholder":"Address Line 2","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.address2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "address2", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":"address2"}},[_vm._v("Address Line 2")]),_c('div',{staticClass:"line"})])]):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'town_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors[0],'success':ubodata.town && !errors[0]}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.town),expression:"ubodata.town"}],staticClass:"form-control",attrs:{"type":"text","d":'town_'+index,"placeholder":"Town*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.town)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "town", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'town_'+index}},[_vm._v("Town*")]),_c('div',{staticClass:"line"}),(errors.length && errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.town && !errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'city_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.city,'success':ubodata.city}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.city),expression:"ubodata.city"}],staticClass:"form-control",attrs:{"type":"text","id":'city_'+index,"placeholder":"City*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "city", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'city_'+index}},[_vm._v("City*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.city && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.city && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'state_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.state,'success':ubodata.state}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.state),expression:"ubodata.state"}],staticClass:"form-control",attrs:{"type":"text","id":'state_'+index,"placeholder":"State/Province/Region/County*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "state", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'state_'+index}},[_vm._v("State/Province/Region/County*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.state && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.state && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'zipCode_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !ubodata.zipCode,'success':ubodata.zipCode}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(ubodata.zipCode),expression:"ubodata.zipCode"}],staticClass:"form-control",attrs:{"type":"text","id":'zipCode_'+index,"placeholder":"Post/Zip Code*","disabled":_vm.readonlyMode},domProps:{"value":(ubodata.zipCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ubodata, "zipCode", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":'zipCode_'+index}},[_vm._v("Post/Zip Code*")]),_c('div',{staticClass:"line"}),(errors.length && !ubodata.zipCode && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.zipCode && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)}):_vm._e(),(!_vm.readonlyMode)?_c('ValidationProvider',{attrs:{"name":'dob_'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group datepicker",class:{'errors': errors[0],'success':ubodata.date_of_birth && !errors[0]}},[_c('span',{staticClass:"float-label"},[_c('b-input-group',{class:{'datepicker-disable':_vm.readonlyMode}},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("Date of Birth")]),_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"icon-calendar"})])]),_c('datepicker',{staticClass:"form-control",attrs:{"bootstrap-styling":false,"placeholder":"","disabled":_vm.readonlyMode,"typeable":true},model:{value:(ubodata.date_of_birth),callback:function ($$v) {_vm.$set(ubodata, "date_of_birth", $$v)},expression:"ubodata.date_of_birth"}})],1),(errors.length && errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(ubodata.date_of_birth && !errors[0] && !_vm.readonlyMode)?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])]}}],null,true)}):_vm._e()],1)}),(!_vm.readonlyMode)?_c('div',{staticClass:"justify-content-center d-flex"},[_c('b-button',{staticClass:"btn-mw",attrs:{"size":"lg"},on:{"click":function($event){return _vm.UBODataSubmit()}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"type":"submit","variant":"primary ml-1 btn-mw","size":"lg"}},[_vm._v("Update")])],1):_vm._e()],2)]}}],null,false,2092920199)})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }