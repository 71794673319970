<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Business Structure, Condition and Size</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body" v-if="!businessStructure.readonlyMode">
            <div class="edit-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(BStructureSubmit)" id="business-structure" ref="form">
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessStructure.readonlyMode}" v-if="!businessStructure.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business a parent of another?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="parentBusinessName" v-model="businessStructure.parentBusinessName" :disabled="businessStructure.readonlyMode">
                                <label class="custom-switch-label" for="parentBusinessName">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="Parent_profile" :rules="businessStructure.parentBusinessName ? 'required' : ''" v-slot="{ errors}" v-if="businessStructure.parentBusinessName && !businessStructure.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessStructure.parentProfile,'success':businessStructure.parentProfile}">
                                <span class="float-label">
                                    <input type="text" v-model="businessStructure.parentProfile" class="form-control" id="Parent_profile" placeholder="Parent Profile*" :disabled="businessStructure.readonlyMode">
                                    <label for="Parent_profile" class="control-label">Parent Profile*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessStructure.parentProfile && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessStructure.parentProfile && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessStructure.readonlyMode}" v-if="!businessStructure.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business a subsidiary of another business?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="subsidiary" v-model="businessStructure.subsidiary" :disabled="businessStructure.readonlyMode">
                                <label class="custom-switch-label" for="subsidiary">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="subsidiary_Name" :rules="businessStructure.subsidiary ? 'required' : ''" v-slot="{ errors}" v-if="businessStructure.subsidiary && !businessStructure.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessStructure.subsidiaryName,'success':businessStructure.subsidiaryName}">
                                <span class="float-label">
                                    <input type="text" v-model="businessStructure.subsidiaryName" class="form-control" id="subsidiary_Name" placeholder="Parent Name/ Business Activity/ Registration Number" :disabled="businessStructure.readonlyMode">
                                    <label for="subsidiary_Name" class="control-label">Parent Name/ Business Activity/ Registration Number</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessStructure.subsidiaryName && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessStructure.subsidiaryName && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="companyStatus" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessStructure.companyStatus && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                    <label for="Country">Company Status*</label>
                                        <multiselect
                                            :disabled="businessStructure.readonlyMode"
                                            placeholder=""
                                            v-model="businessStructure.companyStatus" 
                                            :show-labels="false" 
                                            :options="companyStatus_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessStructure.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessStructure.companyStatus && !errors[0] && !businessStructure.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="NoOfEmp" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessStructure.NoOfEmp && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                    <label for="Country">Number of Employees*</label>
                                        <multiselect
                                            :disabled="businessStructure.readonlyMode"
                                            placeholder=""
                                            v-model="businessStructure.NoOfEmp" 
                                            :show-labels="false" 
                                            :options="NoOfEmp_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessStructure.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessStructure.NoOfEmp && !errors[0] && !businessStructure.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="CurrentBTurnover" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessStructure.CurrentBTurnover && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                    <label for="Country">Current Business Turnover*</label>
                                        <multiselect
                                            :disabled="businessStructure.readonlyMode"
                                            placeholder=""
                                            v-model="businessStructure.CurrentBTurnover" 
                                            :show-labels="false" 
                                            :options="CurrentBTurnover_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessStructure.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessStructure.CurrentBTurnover && !errors[0] && !businessStructure.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="ExpectedBTurnover" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessStructure.ExpectedBTurnover && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                    <label for="Country">Expected Business Turnover*</label>
                                        <multiselect
                                            :disabled="businessStructure.readonlyMode"
                                            placeholder=""
                                            v-model="businessStructure.ExpectedBTurnover" 
                                            :show-labels="false" 
                                            :options="ExpectedBTurnover_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessStructure.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessStructure.ExpectedBTurnover && !errors[0] && !businessStructure.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="size" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessStructure.size && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                    <label for="Country">Size*</label>
                                        <multiselect
                                            :disabled="businessStructure.readonlyMode"
                                            placeholder=""
                                            v-model="businessStructure.size" 
                                            :show-labels="false" 
                                            :options="size_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !businessStructure.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="businessStructure.size && !errors[0] && !businessStructure.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <div v-if="!businessStructure.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="BStructureSubmit()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        
    </div>
    
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
import "@/plugins/vee-validate";
export default {
    name: 'BusinessStructure-Profile',
    components:{
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },
    data(){
        return{
            businessStructure:{
                readonlyMode:true,
                parentBusinessName:false,
                parentProfile:'',
                subsidiary:false,
                subsidiaryName:'',
                companyStatus:['Dormant'],
                NoOfEmp:['1 - 5'],
                CurrentBTurnover:['0 - 1 Million GBP'],
                ExpectedBTurnover:['0 - 1 Million GBP'],
                size:['Small']
            },

            companyStatus_option: ['Active', 'Dormant', 'Disqualified', 'Suspended', 'Discontinued', 'Inactive'],
            NoOfEmp_option: ['1 - 5', '5 - 10', '10 - 20', '20 - 50', '50 - 100', '100+'],
            CurrentBTurnover_option: ['0 - 1 Million GBP', '1 - 5 Million GBP', '5 - 10 Million GBP', '10 - 20 Million GBP', '20 - 50 Million GBP', '50 - 100 Million GBP', '100+ Million GBP'],
            ExpectedBTurnover_option: ['0 - 1 Million GBP', '1 - 5 Million GBP', '5 - 10 Million GBP', '10 - 20 Million GBP', '20 - 50 Million GBP', '50 - 100 Million GBP', '100+ Million GBP'],
            size_option: ['Micro', 'Small', 'Medium', 'Large'],
        }
    },methods:{
        editDetails(){
            this.businessStructure.readonlyMode = false
        },

        //submit Personal Details information
        BStructureSubmit(){
            this.businessStructure.readonlyMode  = true
        },
    }
}
</script>
