<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :showRecipientButtons="true"></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="invoice-search">
                            <form class="search-form">
                                <div class="form-group mb-0">
                                    <span class="float-label">
                                        <input type="text" id="recipientName" class="form-control" placeholder="Search Invoice Recipient's Name" v-model="searchQuery" @input="showLoadingScreen">
                                        <label for="recipientName" class="control-label">Search Invoice Recipient's Name</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                                <button type="button" class="btn btn-default"><i class="fa fa-search font-16"></i></button>
                            </form>
                        </div>
                        <ul class="list-unstyled mb-0 search-list" v-if="!searchQuery && !showInvoiceInfoSection">
                            <template v-for="(recipient,i) in topRecipients">
                                <li class="title-label" :key="'toprecipient_'+i">
                                    <h6 class="d-inline">{{ recipient.recipientTypeName }}</h6>
                                    <a class="d-inline text-primary float-right" v-if="recipient.type == 'recipients'" href="javascript:void(0);" id="allRecipients" @click="showAllRecipients()">See All Recipients</a>
                                </li>
                                <li v-for="(menu) in recipient.optionMenu" :key="menu.id">
                                    <div class="search-list-area d-flex justify-content-between align-items-center" @click="showInvoiceInformationssection(recipient)">
                                        <template>
                                            <!-- recipients section -->
                                            <div class="user-img-name d-flex align-items-center" v-if="recipient.type == 'recipients'">
                                                <img :src="menu.recipientImage" alt="User" class="rounded-circle border mr-3" width="40">
                                                <h5 class="mb-0">{{ menu.name }}</h5>
                                            </div>
                                            <!-- group section -->
                                            <div class="user-img-name d-flex align-items-center" v-if="recipient.type == 'groups'">
                                                <h5 class="mb-0">{{ menu.name }}</h5>
                                            </div>
                                            <!-- group section -->
                                            <div class="users-list mx-2" v-if="recipient.type == 'groups'">
                                                <ul class="list-unstyled team-info mt-0">
                                                    <li v-for="(image,i) in menu.groupImages" :key="'image_'+i">
                                                        <img :src="image" title="Avatar" alt="Avatar">
                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- both section -->
                                            <div class="ml-3 right-icon">
                                                <img :src="require('@/assets/img/icon-right-grediant.svg')">
                                            </div>
                                        </template>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <ul class="list-unstyled mb-0 search-list" v-if="searchQuery" :class="loadingSearch ? 'search-list-recipient':'search-list-blank'">
                            <template v-for="(recipient,i) in recipienList">
                                <li class="title-label" :key="'recipient_'+i">
                                    <h6>{{ recipient.recipientTypeName }}</h6>
                                </li>
                                <li v-for="(menu) in recipient.optionMenu" :key="menu.id">
                                    <div class="search-list-area d-flex justify-content-between align-items-center" @click="showInvoiceInformationssection(recipient)">
                                        <template v-if="loadingSearch">
                                            <!-- recipients section -->
                                            <div class="user-img-name d-flex align-items-center" v-if="recipient.type == 'recipients'">
                                                <img :src="menu.recipientImage" alt="User" class="rounded-circle border mr-3" width="40">
                                                <h5 class="mb-0">{{ menu.name }}</h5>
                                            </div>
                                            <!-- group section -->
                                            <div class="user-img-name d-flex align-items-center" v-if="recipient.type == 'groups'">
                                                <h5 class="mb-0">{{ menu.name }}</h5>
                                            </div>
                                            <!-- group section -->
                                            <div class="users-list mx-2" v-if="recipient.type == 'groups'">
                                                <ul class="list-unstyled team-info mt-0">
                                                    <li v-for="(image,i) in menu.groupImages" :key="'image_'+i">
                                                        <img :src="image" title="Avatar" alt="Avatar">
                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- both section -->
                                            <div class="ml-3 right-icon">
                                                <img :src="require('@/assets/img/icon-right-grediant.svg')">
                                            </div>
                                        </template>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <ul class="list-unstyled mb-0 search-list no-search-list" v-if="recipienList.length == 0">
                            <li class="title-label">
                                <h6>No Recipients found</h6>
                            </li>
                            <li>
                                <router-link  to="/invoice/add-customer" class="btn btn-add-new-recipients d-flex align-items-center justify-content-start w-100"><i class="fa fa-plus"></i> Add new  Recipients</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card card-top-line no-shadow" v-if="showInvoiceInfoSection && !searchQuery">
                    <div class="body">
                        <ul class="list-unstyled mb-0 common-form small-form">
                            <li>
                                <h4 class="page-title mb-0">Invoice Informations</h4>
                                <ul class="list-unstyled invoice-list mt-2">
                                    <li class="row" v-for="(invoiceInfo,i) in invoiceInformations" :key="i">
                                        <label class="col-sm-6 font-weight-bold">{{ invoiceInfo.key }}</label>
                                        <p class="col-sm-6">{{ invoiceInfo.value }}</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                    <router-link to="/invoice/list" class="btn btn-secondary btn-mw btn-lg">Cancel</router-link>
                                    <b-button variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg" @click="customerSubmitForm()">Continue</b-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
import "@/plugins/vee-validate";
export default {
    name:'AllCustomerComponent',
    components: {
        BreadCrumb
    },data(){
        return{
            invoiceInformations:[
                {key:'Business Name',value:'PayAngel'},
                {key:'Email',value:'payangel@gmail.com'},
                {key:'Phone',value:'289-335-6503'},
                {key:'country',value:'United Kingdom'},
                {key:'First Name',value:'Michael'},
                {key:'Last Name',value:'Truong'},
                {key:'Street',value:'High Street'},
                {key:'Town/City',value:'Bristol'},
                {key:'Note',value:'An invoice is a time-stamped commercial document that itemizes and records a transaction between a buyer and a seller.'},
            ],
            
            recipientSearchList:[
                {
                    recipientTypeName: 'Saved Recipients',
                    type: 'recipients',
                    optionMenu: [
                        {
                            id:0,
                            recipientImage:require('@/assets/xs/avatar1.jpg'),
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[]
                        },
                        {
                            id:1,
                            recipientImage:require('@/assets/xs/avatar5.jpg'),
                            name:'N-Web infoweb',
                            groupImages:[]
                        }
                    ]
                },
                {
                    recipientTypeName: 'Saved Groups',
                    type: 'groups',
                    optionMenu: [
                        {
                            id:2,
                            recipientImage:'',
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:3,
                            recipientImage:'',
                            name:'Code+ Tech inc.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:4,
                            recipientImage:'',
                            name:'Tab Software Ltd.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        }
                    ]
                }
            ],

            topRecipients: [
                {
                    recipientTypeName: 'Top Recipients',
                    type: 'recipients',
                    optionMenu: [
                        {
                            id:0,
                            recipientImage:require('@/assets/user.png'),
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[]
                        },
                        {
                            id:1,
                            recipientImage:require('@/assets/xs/avatar2.jpg'),
                            name:'N-Web infoweb',
                            groupImages:[]
                        },
                        {
                            id:2,
                            recipientImage:require('@/assets/xs/avatar3.jpg'),
                            name:'PayAngel US',
                            groupImages:[]
                        }
                    ]
                },
                {
                    recipientTypeName: 'Top Groups',
                    type: 'groups',
                    optionMenu: [
                        {
                            id:3,
                            recipientImage:'',
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:4,
                            recipientImage:'',
                            name:'Code+ Tech inc.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:5,
                            recipientImage:'',
                            name:'Tab Software Ltd.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        }
                    ]
                }
            ],

            allRecipients: [
                {
                    recipientTypeName: 'Recipients',
                    type: 'recipients',
                    optionMenu: [
                        {
                            id:0,
                            recipientImage:require('@/assets/xs/avatar1.jpg'),
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[]
                        },
                        {
                            id:1,
                            recipientImage:require('@/assets/xs/avatar2.jpg'),
                            name:'N-Web infoweb',
                            groupImages:[]
                        },
                        {
                            id:2,
                            recipientImage:require('@/assets/xs/avatar3.jpg'),
                            name:'PayAngel US',
                            groupImages:[]
                        },
                        {
                            id:3,
                            recipientImage:require('@/assets/xs/avatar4.jpg'),
                            name:'StandApp',
                            groupImages:[]
                        },
                        {
                            id:4,
                            recipientImage:require('@/assets/xs/avatar5.jpg'),
                            name:'Angel Community',
                            groupImages:[]
                        }
                    ]
                },
                {
                    recipientTypeName: 'Groups',
                    type: 'groups',
                    optionMenu: [
                        {
                            id:5,
                            recipientImage:'',
                            name:'Adwoa Mensah Tetteh',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:6,
                            recipientImage:'',
                            name:'Code+ Tech inc.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        },
                        {
                            id:7,
                            recipientImage:'',
                            name:'Tab Software Ltd.',
                            groupImages:[
                                require('@/assets/xs/avatar1.jpg'),
                                require('@/assets/xs/avatar5.jpg'),
                                require('@/assets/xs/avatar9.jpg'),
                                require('@/assets/xs/avatar10.jpg')
                            ]
                        }
                    ]
                }
            ],
            
            selectedRecipientIs:'',
            searchQuery: null,
            loadingSearch:false,
            showInvoiceInfoSection:false,
            showTopRecipients:true,
        }
    },methods:{
        customerSubmitForm(){
            this.$router.push({path:'/invoice/add-invoice-details', query:{ type: this.$route.query.type, recipient: this.selectedRecipientIs }});
        },

        showLoadingScreen(e){
            if(e.target.value){
                setTimeout(() => {
                    this.loadingSearch = true 
                }, 2000);
            }else{
                this.loadingSearch = false
            }
              
        },

        showInvoiceInformationssection(menutype){
            this.showInvoiceInfoSection = true
            this.searchQuery = null
            this.selectedRecipientIs = menutype.type
        },

        showAllRecipients(){
            this.showTopRecipients = !this.showTopRecipients 
            document.getElementById('allRecipients').innerHTML = this.showTopRecipients ? 'See All Recipients':'See Top Recipients';
            if(this.showTopRecipients){
                this.topRecipients = [
                    {
                        recipientTypeName: 'Top Recipients',
                        type: 'recipients',
                        optionMenu: [
                            {
                                id:0,
                                recipientImage:require('@/assets/user.png'),
                                name:'Adwoa Mensah Tetteh',
                                groupImages:[]
                            },
                            {
                                id:1,
                                recipientImage:require('@/assets/xs/avatar2.jpg'),
                                name:'N-Web infoweb',
                                groupImages:[]
                            },
                            {
                                id:2,
                                recipientImage:require('@/assets/xs/avatar3.jpg'),
                                name:'PayAngel US',
                                groupImages:[]
                            }
                        ]
                    },
                    {
                        recipientTypeName: 'Top Groups',
                        type: 'groups',
                        optionMenu: [
                            {
                                id:3,
                                recipientImage:'',
                                name:'Adwoa Mensah Tetteh',
                                groupImages:[
                                    require('@/assets/xs/avatar1.jpg'),
                                    require('@/assets/xs/avatar5.jpg'),
                                    require('@/assets/xs/avatar9.jpg'),
                                    require('@/assets/xs/avatar10.jpg')
                                ]
                            },
                            {
                                id:4,
                                recipientImage:'',
                                name:'Code+ Tech inc.',
                                groupImages:[
                                    require('@/assets/xs/avatar1.jpg'),
                                    require('@/assets/xs/avatar5.jpg'),
                                    require('@/assets/xs/avatar9.jpg'),
                                    require('@/assets/xs/avatar10.jpg')
                                ]
                            },
                            {
                                id:5,
                                recipientImage:'',
                                name:'Tab Software Ltd.',
                                groupImages:[
                                    require('@/assets/xs/avatar1.jpg'),
                                    require('@/assets/xs/avatar5.jpg'),
                                    require('@/assets/xs/avatar9.jpg'),
                                    require('@/assets/xs/avatar10.jpg')
                                ]
                            }
                        ]
                    }
                ]
            }else{
                this.topRecipients = this.allRecipients
            }
        }
    },computed: {
        recipienList() {
            let recipientItem = this.recipientSearchList;
            if (this.searchQuery) {
                recipientItem = recipientItem.filter(item => {
                    return item.optionMenu.some(subItem => {
                        return (
                        subItem.name
                        .toLowerCase()
                        .indexOf(this.searchQuery.toLowerCase()) !== -1
                        );
                    });
                });
                
                recipientItem = recipientItem.map(item => {
                    const items = item.optionMenu.filter(x => x.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
                    return {
                        ...item,
                        items
                    }
                })
            } 
            return recipientItem;
        }
    }
}
</script>