<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Finally, in case we need to reach you who should we contact?</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body" v-if="!contacts.readonlyMode">
            <div class="account-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(contactDataSubmit)" id="contact">
                        <b-tabs content-class="px-0" class="contact-tab" nav-class="nav-tabs-new2" :no-nav-style="true" v-model="currentContactsTabIndex">
                            <!-- Individual Tab -->
                           <b-tab title="Account Administration">
                                <ValidationProvider :name="'administration_country'" :rules="currentContactsTabIndex == 0 ? 'required' : ''" v-slot="{ errors}">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contacts.administration.country && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                            <label for="c">Country*</label>
                                                <multiselect
                                                    :disabled="contacts.readonlyMode"
                                                    placeholder=""
                                                    v-model="contacts.administration.country" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !contacts.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="contacts.administration.country && !errors[0] && !contacts.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="administration_name" :rules="currentContactsTabIndex == 0 ? 'required' : ''" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.administration.name,'success':contacts.administration.name}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.administration.name" class="form-control" id="administration_name" placeholder="Name*"  :disabled="contacts.readonlyMode"/>
                                            <label for="administration_name" class="control-label">Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.administration.name && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.administration.name && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="administration_email" :rules="currentContactsTabIndex == 0 ? 'required|email' : ''" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.administration.Email || errors[0],'success':contacts.administration.Email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="contacts.administration.Email" class="form-control" name="email1" id="administration_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                            <label for="administration_email" class="control-label">Email*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.administration.Email || errors[0] && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.administration.Email && !errors[0] && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="administrationPhoneNumber" :rules="currentContactsTabIndex == 0 ? 'required' : ''" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.administration.phoneNumber,'success':contacts.administration.phoneNumber}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.administration.phoneNumber" class="form-control" id="administrationPhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                            <label for="administrationPhoneNumber" class="control-label">Phone Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.administration.phoneNumber && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.administration.phoneNumber && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </b-tab>

                            <!-- Corporate Tab -->
                            <b-tab title="Finance related issues">
                                <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                    <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                    <div class="custom-switch success-handle">
                                        <input type="checkbox" class="custom-switch-input" id="is_finance_same_as_admin" v-model="contacts.finance.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                        <label class="custom-switch-label" for="is_finance_same_as_admin">
                                            <span class="custom-switch-text1">Yes</span>
                                            <span class="custom-switch-text2">No</span>
                                        </label>
                                    </div>
                                </div>
                                <ValidationProvider :name="'finance_country'" :rules="currentContactsTabIndex == 1 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.finance.isSameAsAdmin">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contacts.finance.country && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                            <label for="c">Country*</label>
                                                <multiselect
                                                    :disabled="contacts.readonlyMode"
                                                    placeholder=""
                                                    v-model="contacts.finance.country" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !contacts.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="contacts.finance.country && !errors[0] && !contacts.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="finance_name" :rules="currentContactsTabIndex == 1 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.finance.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.finance.name,'success':contacts.finance.name}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.finance.name" class="form-control" id="finance_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                            <label for="finance_name" class="control-label">Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.finance.name && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.finance.name && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="finance_email" :rules="currentContactsTabIndex == 1 ? 'required|email' : ''" v-slot="{ errors}" v-if="!contacts.finance.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.finance.Email || errors[0],'success':contacts.finance.Email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="contacts.finance.Email" class="form-control" name="email1" id="finance_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                            <label for="finance_email" class="control-label">Email*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.finance.Email || errors[0] && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.finance.Email && !errors[0] && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="financePhoneNumber" :rules="currentContactsTabIndex == 1 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.finance.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.finance.phoneNumber,'success':contacts.finance.phoneNumber}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.finance.phoneNumber" class="form-control" id="financePhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                            <label for="financePhoneNumber" class="control-label">Phone Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.finance.phoneNumber && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.finance.phoneNumber && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </b-tab>

                            <!-- Customer Service Issues  -->
                            <b-tab title="Customer Service Issues">
                                <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                    <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                    <div class="custom-switch success-handle">
                                        <input type="checkbox" class="custom-switch-input" id="is_customer_same_as_admin" v-model="contacts.customerService.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                        <label class="custom-switch-label" for="is_customer_same_as_admin">
                                            <span class="custom-switch-text1">Yes</span>
                                            <span class="custom-switch-text2">No</span>
                                        </label>
                                    </div>
                                </div>
                                <ValidationProvider :name="'customerService_country'" :rules="currentContactsTabIndex == 2 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.customerService.isSameAsAdmin">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contacts.customerService.country && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                            <label for="c">Country*</label>
                                                <multiselect
                                                    :disabled="contacts.readonlyMode"
                                                    placeholder=""
                                                    v-model="contacts.customerService.country" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !contacts.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="contacts.customerService.country && !errors[0] && !contacts.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="customerService_name" :rules="currentContactsTabIndex == 2 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.customerService.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.customerService.name,'success':contacts.customerService.name}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.customerService.name" class="form-control" id="customerService_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                            <label for="customerService_name" class="control-label">Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.customerService.name && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.customerService.name && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="customerService_email" :rules="currentContactsTabIndex == 2 ? 'required|email' : ''" v-slot="{ errors}" v-if="!contacts.customerService.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.customerService.Email || errors[0],'success':contacts.customerService.Email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="contacts.customerService.Email" class="form-control" name="email1" id="customerService_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                            <label for="customerService_email" class="control-label">Email*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.customerService.Email || errors[0] && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.customerService.Email && !errors[0] && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="customerServicePhoneNumber" :rules="currentContactsTabIndex == 2 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.customerService.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.customerService.phoneNumber,'success':contacts.customerService.phoneNumber}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.customerService.phoneNumber" class="form-control" id="customerServicePhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                            <label for="customerServicePhoneNumber" class="control-label">Phone Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.customerService.phoneNumber && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.customerService.phoneNumber && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>  
                            </b-tab>

                            <!-- Technical Issues  -->
                            <b-tab title="Technical Issues">
                                <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                    <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                    <div class="custom-switch success-handle">
                                        <input type="checkbox" class="custom-switch-input" id="is_technical_same_as_admin" v-model="contacts.technical.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                        <label class="custom-switch-label" for="is_technical_same_as_admin">
                                            <span class="custom-switch-text1">Yes</span>
                                            <span class="custom-switch-text2">No</span>
                                        </label>
                                    </div>
                                </div>
                                <ValidationProvider :name="'technical_country'" :rules="currentContactsTabIndex == 3 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.technical.isSameAsAdmin">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contacts.technical.country && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                            <label for="c">Country*</label>
                                                <multiselect
                                                    :disabled="contacts.readonlyMode"
                                                    placeholder=""
                                                    v-model="contacts.technical.country" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !contacts.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="contacts.technical.country && !errors[0] && !contacts.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="technical_name" :rules="currentContactsTabIndex == 3 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.technical.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.technical.name,'success':contacts.technical.name}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.technical.name" class="form-control" id="technical_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                            <label for="technical_name" class="control-label">Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.technical.name && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.technical.name && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="technical_email" :rules="currentContactsTabIndex == 3 ? 'required|email' : ''" v-slot="{ errors}" v-if="!contacts.technical.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.technical.Email || errors[0],'success':contacts.technical.Email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="contacts.technical.Email" class="form-control" name="email1" id="technical_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                            <label for="technical_email" class="control-label">Email*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.technical.Email || errors[0] && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.technical.Email && !errors[0] && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            
                                <ValidationProvider name="technicalphoneNumber" :rules="currentContactsTabIndex == 3 ? 'required' : ''" v-slot="{ errors}" v-if="!contacts.technical.isSameAsAdmin">
                                    <div class="form-group" :class="{'errors': errors.length && !contacts.technical.phoneNumber,'success':contacts.technical.phoneNumber}">
                                        <span class="float-label">
                                            <input type="text" v-model="contacts.technical.phoneNumber" class="form-control" id="technicalphoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                            <label for="technicalphoneNumber" class="control-label">Phone Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !contacts.technical.phoneNumber && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="contacts.technical.phoneNumber && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider> 
                            </b-tab>
                        </b-tabs>
                        <div v-if="!contacts.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="contactDataSubmit()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
import "@/plugins/vee-validate";

export default {
    name: 'Contact-Profile',
    components:{
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },
    data(){
        return{
            currentContactsTabIndex: 0, 
            contacts:{
                readonlyMode:true,
                administration:{
                    country:[{ name: 'France', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    name:'administration',
                    Email:'administration@gmail.com',
                    phoneNumber:'02359874563'
                },

                finance:{
                    country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    name:'finance',
                    Email:'finance@gmail.com',
                    phoneNumber:'85624569852',
                    isSameAsAdmin: false,
                },

                customerService:{
                    country:[{ name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"}],
                    name:'customerService',
                    Email:'customerService@gmail.com',
                    phoneNumber:'120365894520',
                    isSameAsAdmin: true,
                },

                technical:{
                    country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    name:'technical',
                    Email:'technical@gmail.com',
                    phoneNumber:'1023658974102',
                    isSameAsAdmin: true,
                },
            },
            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

        }
    },methods:{
        editDetails(){
           this.contacts.readonlyMode = false
        },

        contactDataSubmit(){
            this.contacts.readonlyMode = true
        },
    }
}
</script>
